import { createSlice } from "@reduxjs/toolkit"; 

const initialState = {
    questions: [],
}

const mocktestSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setQuestions: (state,action) => {
             state.questions = action.payload;
        },
        updateQuestion: (state,action) => {
            let updatedIndex:number = action.payload['index'];
            let updatedKey:string = action.payload['key'];
            let updatedData:string = action.payload['data'];
            let updationOnKey = state.questions[updatedIndex];
        }
    }
});

export default mocktestSlice.reducer;
export const { setQuestions, updateQuestion } = mocktestSlice.actions;