import { DetailsRouteURL, TestSereisRouteURL } from '@Constants/app-route-url.constant';
import { Link } from 'react-router-dom';
import './MockTests.scss';
import { Skeleton } from 'primereact/skeleton';
import { useEffect, useState } from 'react';

const MockTests = ({ testList, classId, subject, isTestSeries }: any) => {
    const [loadMore, setLoadMore] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (testList && testList.length >= 20) {
            let data = testList.slice(0, 20);
            setList(data);
            setLoadMore(true);
        } else {
            setList(testList);
            setLoadMore(false);
        }
    }, [testList])
    function loadMoreClicked() {
        setList(testList);
        setLoadMore(false);
    }

    return (
        <section className="most-popular-series-container">
            <div className="mock-test-listing-header">
            { isTestSeries ? 'Test Series' :  'Mock Tests'}
            </div>
            <div className="most-popular-cards-container">
                {
                  isTestSeries ?  
                  list.map((element:any)=>{
                    return (<div key={element.Id} className="othr-test-card">
                        <div className="o-t-c-header">
                           <div className='test-card-imgs'> <img src={element.testSeriesImage} alt="ssc-cgl" onError={(e:any) => {e.target.src = "../../../noimage.png"}} /> </div>
                            <div className="o-t-c-heading">{element.testSeriesName}</div>
                            <div className="subject-title-card">Level {element.difficultyLevel}</div>
                        </div>
                        <div className="question-paper">
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                                <span className="qust-papr-head">Questions</span>
                                <span className="duration-marks-card">{element.Questions}</span>
                            </div>
                            {/* <div className="tw-flex tw-items-center tw-justify-between">
                                <span className="qust-papr-head">Max Marks</span>
                                <span className="duration-marks-card">{element.mask}</span>
                            </div> */}
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                                <span className="qust-papr-head">Time</span>
                                <span className="duration-marks-card">{element.Time/60} mins</span>
                            </div>
                        </div>
                        <div className="stnd-cls-card-btn tw-my-4">
                        <Link to={`${TestSereisRouteURL.TEST_SERIES_DETAIL}/${element.testSeriesId}`} >
                            <button className="take-test-btn">View Details</button>
                            </Link>
                        </div>
                    </div>)
                    })
                  :  list.map((test: any) => {
                        let subjectName = test.Name; 
                        return (
                            <div key={test.Id} className="m-p-card">
                                <div className="subject-card">
                                    <img src={test.testImage} className='subject-img'  onError={(e:any) => {e.target.src = "../../../noimage.png"}}
 />
                                    <div className="subject-test-levl-card">Level {test.testDifficultyLevel}</div>
                                </div>
                                <div className="subject-test-descrip">{test.testName}</div>
                                <div className="stnd-cls-card-btn">
                                    <Link to={`${DetailsRouteURL.TEST_DETAILS}/${test.testId}`} >
                                        <button className="take-test-btn">View Details</button>
                                    </Link>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {
                loadMore ? <div className="lode-more-card">
                    <button className="load-more-btn" onClick={loadMoreClicked} >Load More</button>
                </div> : ''
            }
        </section>
    )
}

export const SubjectTestsSkeleton = () => {
    return (
        <div>
            <ul className="m-0 tw-px-5 tw-pt-2 list-none tw-flex tw-flex-wrap ">
                {[...Array(12)].map((e, i) => (
                    <li className="m-2 border-round border-1 tw-p-3 skeleton-card tw-text-center">
                        <div>
                            <Skeleton  width="100%" height='14rem' className='tw-mb-3'></Skeleton>
                            <div >
                                <Skeleton  className="tw-mx-auto" width='70%' height='1.5rem'></Skeleton>                             
                                <Skeleton width="10rem" className='tw-mx-auto tw-mt-2'  height='3rem'></Skeleton>
                          </div>
                        </div>
                    </li>
                ))
                }
            </ul>
        </div>

    )
}

export default MockTests