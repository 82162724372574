import ApiService from "@Src/services/ApiService";
import {HOME_API_ROUTES} from "./Home.api.constant";


export async function getBannnerimages() { // need to define proper interface
    return await ApiService.fetchData({
        url: HOME_API_ROUTES.HOME +  '/' +  HOME_API_ROUTES.BANNER_HOME,
        method: "get",
        params:''
    });
}