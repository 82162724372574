import { TestListingIndex } from "@Src/features/TestListing"


export const TestListing = () => {

    return (
        <>
         <TestListingIndex />
        </>
    )
}