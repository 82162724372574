import { useEffect, useState } from "react";
import { TestQuestionIndex } from "./Components/Test-question-card";
import "./MockTest.scss";
import { TestSteps, TestTypes } from "@Constants/app.constant";
import { InstructionIndex } from "./Instruction";
import { useParams } from "react-router-dom";
import { getTestDetails, getTestSeriesDetails } from "../TestDetails/services/TestDetails.service";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { TestRouteURL } from "@Constants/app-route-url.constant";

export const TestIndex = () => {
    const params = useParams();
    const testId = params.testId;
    const examId = params.examId;
    const [step, setStep] = useState(0);
    const [testDeatils, setTestDeatils] = useState<any>([]);
    const testType = TestTypes.regular;
    const [isTestSeries, setIsTestSeries] = useState<boolean>(false);

    useEffect(() => {
        if (location.pathname.includes(`${TestRouteURL.TEST_SERIES}`)) {
            setIsTestSeries(true);
            getAllTestSeriesDetails();
        } else {
            setIsTestSeries(false);
            getAllTestDetails();
        }
    }, [testId, examId]);

    function getAllTestDetails() {
        let param = {
            "testId": testId,
        }
        getTestDetails(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setTestDeatils(data);
                setStep(TestSteps.INSTRUCTION)
            }
        })
    }
    function getAllTestSeriesDetails() {
        let param = {
            "examId": examId,
        }
        getTestSeriesDetails(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setTestDeatils(data);
                setStep(TestSteps.INSTRUCTION)
            }
        })
    }


    const onContinueClickHandler = () => {
        setStep(TestSteps.START_TEST);
    }

    return (
        <>
            {step == TestSteps.INSTRUCTION ?
                isTestSeries ?
                    <InstructionIndex maxMarks="0" noOfQuestion={testDeatils.NoOfQues} timeDuration={testDeatils.Duration} onContinueClickHandler={onContinueClickHandler} testType={testType} isTestSeries={isTestSeries} testName={testDeatils.Name} />
                    : <InstructionIndex maxMarks="0" noOfQuestion={testDeatils[0].NoOfQues} timeDuration={testDeatils[0].Duration} onContinueClickHandler={onContinueClickHandler} testType={testType} isTestSeries={isTestSeries} testName={testDeatils[0].TestName} />
                : ''}
            {step == TestSteps.START_TEST ?
                isTestSeries ?
                    <TestQuestionIndex testId={examId} noOfQuestion={testDeatils.NoOfQues} timeDuration={testDeatils.Duration} isTestSeries={isTestSeries} testIds={testDeatils.TestIdList} testName={testDeatils.Name} />
                    : <TestQuestionIndex testId={testId} noOfQuestion={testDeatils[0].NoOfQues} timeDuration={testDeatils[0].Duration} isTestSeries={isTestSeries} testIds={[]} testName={testDeatils[0].TestName} />
                : ''
            }
        </>
    );
};
