export const TestDescription = ({ testDeatils,isTestSeries }: any) => {
    return (
        <>
            {
            isTestSeries ? 
            testDeatils && testDeatils.Id  ? 
                <div  className="text-description-card" dangerouslySetInnerHTML={{ __html: testDeatils.Description }} >
                </div> :''
            :    testDeatils && testDeatils.length && testDeatils[0].Id ? 
                <div  className="text-description-card" dangerouslySetInnerHTML={{ __html: testDeatils[0].Description }} >
                </div> :''
            }

{/* <div>
             <div className="abt-b-c-about-test">
            <div className="about-test-header">
                About the Test
            </div>
            <ul className="about-test-ul">
                <li>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</li>
                <li>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</li>
            </ul>
        </div>
        <div className="abt-b-c-about-test">
            <div className="about-test-header">
                What Areas Does it Comprise
            </div>
            <ul className="about-test-ul">
                <li>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</li>
                <li>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</li>
            </ul>
        </div>
        <div className="abt-b-c-about-test">
            <div className="about-test-header">
                How Will this Benefit You
            </div>
            <ul className="benefits-test-ul">
                <li>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</li>
                <li>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</li>
            </ul>
        </div>
        <div className="abt-b-c-about-test">
            <div className="about-test-header">
                Who Should Take the Test
            </div>
            <ul className="about-test-ul">
                <li>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</li>
                <li>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</li>
            </ul>
        </div> 
  </div> */}
        </> 
    )

}