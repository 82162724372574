import { DetailsRouteURL, TestSereisRouteURL } from '@Constants/app-route-url.constant';
import { useNavigate } from 'react-router-dom';
import './SimilarTests.scss';
export const SimilarTests = ({Heading, list,isTestSeries}:any) => {
    const navigation = useNavigate();

    const goToTest =(element:any) =>{
        // if(isTestSeries){
        //     navigation(`${TestSereisRouteURL.TEST_SERIES_DETAIL}/${element.Id}`)
        // }else{
        navigation(`${DetailsRouteURL.TEST_DETAILS}/${element.Id}`)
        //}
    }

    return (
        <>
                 {list && list.length ? <div className="abt-b-c-about-test">
                            <div className="about-test-header">
                                {Heading}
                            </div>
                            <div className="similar-test-cards-container">
                                {
                                    list.map((element:any)=>{
                                       return (
                                        <div className="similar-test-cards" key={element.Id} >
                                        <div className='s-t-c-img'>
                                        <img src={element.Image} />
                                        </div>                                          
                                        <div className="tw-ml-5">
                                            <div className="test-level-head">{element.Name}</div>
                                            <div className="stnd-cls-card-btn">
                                                <button onClick={()=>goToTest(element)} className="take-test-btn">View Details</button>
                                            </div>
                                        </div>
                                    </div>    
                                       )
                                    })
                                }
                            </div>
                        </div>: ''}        
        </>
    )
}