import { createSlice } from "@reduxjs/toolkit"; 

const initialState = {
   registerEmail : '',
   userID : null,
   password : ''
}

const registerSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
        setEmailID: (state,action) => {
             state.registerEmail = action.payload;
        },
        setUserID: (state,action) => {
            state.userID = action.payload;
       },
       setPassword: (state,action) => {
        state.password = action.payload;
   }
    }
});

export default registerSlice.reducer;
export const { setEmailID , setUserID, setPassword} = registerSlice.actions;