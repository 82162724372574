import { Link } from 'react-router-dom';
import './../DetailsPageBanner.scss'
const Breadcrumb = ({ Headings }: any) => {
    return (
                <div className="m-t-l-tabs">
                <Link to='/'>
                    <span className="m-t-l-span">Home</span>
                    </Link>
                    {Headings.map((element: any, index: number) => {
                        return  <>{element && element.name ?<span key={index} className="m-t-l-span active-span">| {element.name}</span> : ''}</>
                    })}

                </div>
        );
};

export default Breadcrumb;
