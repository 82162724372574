
import "./MyBadges.scss";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import ShareIconSvg from "@Assets/svg/Dashboard-svgs/shareicon.svg";


export const MyBadgesIndex = () => {
    const [displayBadge, setDisplayBadge] = useState(false);

    const [position, setPosition] = useState('center');





    return (
        <>

            <div className="dashboard-mybadge-container">
            <div className="badge-header">
            My Badges
            </div>
            <div className="badge-cards-container">
                <div className="badge-card"  onClick={() => setDisplayBadge(true)}>
                    <span className="badge-img">
                        <img src="https://i.postimg.cc/zGNh0WL4/achievement-1.png" alt="badge-status" /> 
                    </span>
                    <div className="badge-status-head">The Torchbearer</div>
                </div>

                <div className="badge-card">
                    <span className="badge-img">
                        <img src="https://i.postimg.cc/zGNh0WL4/achievement-1.png" alt="badge-status" /> 
                    </span>
                    <div className="badge-status-head">The Leader</div>
                </div>

                <div className="badge-card">
                    <span className="badge-img">
                        <img src="https://i.postimg.cc/zGNh0WL4/achievement-1.png" alt="badge-status" /> 
                    </span>
                    <div className="badge-status-head">First Milestone</div>
                </div>

                <div className="badge-card">
                    <span className="badge-img">
                        <img src="https://i.postimg.cc/zGNh0WL4/achievement-1.png" alt="badge-status" /> 
                    </span>
                    <div className="badge-status-head">The Champion</div>
                </div>


                <div className="badge-card">
                    <span className="badge-img">
                        <img src="https://i.postimg.cc/zGNh0WL4/achievement-1.png" alt="badge-status" /> 
                    </span>
                    <div className="badge-status-head">Top Charts</div>
                </div>


                <div className="badge-card">
                    <span className="badge-img">
                        <img src="https://i.postimg.cc/zGNh0WL4/achievement-1.png" alt="badge-status" /> 
                    </span>
                    <div className="badge-status-head">Shining Star</div>
                </div>

               


                <div className="badge-card">
                    <span className="badge-img">
                        <img src="https://i.postimg.cc/zGNh0WL4/achievement-1.png" alt="badge-status" /> 
                    </span>
                    <div className="badge-status-head">Perfect Score</div>
                </div>


                <div className="badge-card">
                    <span className="badge-img">
                        <img src="https://i.postimg.cc/zGNh0WL4/achievement-1.png" alt="badge-status" /> 
                    </span>
                    <div className="badge-status-head">Knowledge King</div>
                </div>

                <div className="badge-card">
                    <span className="badge-img">
                        <img src="https://i.postimg.cc/zGNh0WL4/achievement-1.png" alt="badge-status" /> 
                    </span>
                    <div className="badge-status-head">Fastest Force</div>
                </div>

                <div className="badge-card">
                    <span className="badge-img">
                        <img src="https://i.postimg.cc/zGNh0WL4/achievement-1.png" alt="badge-status" /> 
                    </span>
                    <div className="badge-status-head">The Finisher</div>
                </div>


                
            </div>

        </div>


            <div>
               
                <Dialog visible={displayBadge} style={{ width: '40vw' }} onHide={() => setDisplayBadge(false)}>
                    <div className="badge-dialog-popup">
                        <span>
                            <svg width="197" height="151" viewBox="0 0 197 151" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M98 136.562C126.477 136.562 149.562 113.477 149.562 85C149.562 56.5228 126.477 33.4375 98 33.4375C69.5228 33.4375 46.4375 56.5228 46.4375 85C46.4375 113.477 69.5228 136.562 98 136.562Z" fill="#7F56D9" />
                                <path d="M98 127.969C121.731 127.969 140.969 108.731 140.969 85C140.969 61.269 121.731 42.0312 98 42.0312C74.269 42.0312 55.0312 61.269 55.0312 85C55.0312 108.731 74.269 127.969 98 127.969Z" fill="url(#paint0_linear_190_6957)" />
                                <path d="M121.203 77.3516C108.312 72.625 95.5938 67.125 83.1328 60.7656C75.6563 80.5312 71.5312 101.93 71.3594 124.102C78.3203 120.75 85.3672 117.656 92.5 114.906C99.1172 119.633 105.992 123.844 112.867 127.367C112.695 109.75 115.617 92.8203 121.203 77.3516Z" fill="#D44A90" />
                                <path d="M75.3125 87.6641C75.9141 88.1797 76.6016 88.6094 77.375 88.7812C80.125 89.4687 82.1016 92.4766 82.1875 96.0859C82.4453 101.586 87.1719 104.68 90.8672 102.102C93.3594 100.469 96.3672 101.242 98.2578 103.648C101.18 107.43 106.336 106.312 108.055 102.102C109.258 99.3516 112.094 97.9766 114.758 98.6641C114.93 98.75 115.102 98.75 115.273 98.75C116.648 91.3594 118.625 84.1406 121.117 77.2656C108.227 72.5391 95.5078 67.0391 83.0469 60.6797C79.8672 69.4453 77.2031 78.3828 75.3125 87.6641Z" fill="#A72973" />
                                <path d="M118.023 76.1484C107.281 72.1094 96.711 67.4688 86.3125 62.3125C79.2656 81.0469 75.2266 101.414 74.8828 122.469C80.7266 119.805 86.5703 117.227 92.5 114.906C98 118.859 103.672 122.383 109.43 125.563C109.43 108.117 112.438 91.4453 118.023 76.1484Z" fill="#F15A9E" />
                                <path d="M82.1875 96.0859C82.4453 101.586 87.0859 103.648 90.8672 101.07C93.3594 99.4375 96.3672 100.211 98.2578 102.703C101.094 106.484 106.422 106.398 108.141 102.102C108.914 100.383 110.289 99.1797 111.922 98.664C113.383 90.8437 115.445 83.2812 118.023 76.0625C107.281 72.1094 96.7109 67.4687 86.3125 62.3125C83.0469 70.9922 80.4688 79.9297 78.4922 89.2109C80.6406 90.414 82.1016 92.9922 82.1875 96.0859Z" fill="#D44A90" />
                                <path d="M110.375 45.2966C114.156 43.3201 118.797 45.9841 118.969 50.281C119.141 53.1169 121.203 55.3513 123.953 55.781C128.164 56.4685 130.312 61.281 127.992 64.8904C126.445 67.2107 126.789 70.3044 128.766 72.281C131.773 75.2888 130.742 80.445 126.703 81.9919C124.125 83.0232 122.578 85.6872 123.008 88.4372C123.695 92.6482 119.742 96.1716 115.617 95.1404C112.867 94.4529 110.031 95.656 108.828 98.2341C106.852 102.101 101.609 102.617 98.9453 99.2653C97.1406 97.1169 94.1328 96.4294 91.6406 97.7185C87.8594 99.695 83.2188 97.031 83.0469 92.7341C82.875 89.8982 80.8125 87.6638 78.0625 87.2341C73.8516 86.5466 71.7031 81.7341 74.0235 78.1247C75.5703 75.8044 75.2266 72.7107 73.25 70.7341C70.2422 67.7263 71.2735 62.57 75.3125 61.0232C77.8906 59.9919 79.4375 57.3279 79.0078 54.5779C78.3203 50.3669 82.2735 46.8435 86.3985 47.8747C89.1485 48.5622 91.9844 47.3591 93.1875 44.781C95.1641 40.9138 100.406 40.3982 103.07 43.7497C104.789 45.8982 107.883 46.4997 110.375 45.2966Z" fill="#00C2A9" />
                                <path d="M121.228 75.7286C123.571 64.5813 116.434 53.6452 105.286 51.3022C94.1391 48.9592 83.2031 56.0965 80.8601 67.2438C78.5171 78.3911 85.6543 89.3271 96.8016 91.6701C107.949 94.0131 118.885 86.8759 121.228 75.7286Z" fill="#00A58C" />
                                <path d="M81.3281 69.1021C83.6484 57.9302 94.5625 50.7974 105.734 53.1177C113.297 54.7505 119.055 60.2505 121.203 67.1255C119.57 59.3911 113.469 52.9458 105.305 51.2271C94.1328 48.9068 83.1328 56.1255 80.8125 67.2114C80.0391 70.8208 80.2969 74.3442 81.3281 77.6099C80.7266 74.8599 80.7266 72.0239 81.3281 69.1021Z" fill="#037C68" />
                                <path d="M99.2031 72.1099L94.9922 65.5786C94.7344 65.1489 94.2187 65.063 93.7891 65.3208L89.9219 67.813C89.4922 68.0708 89.4063 68.5864 89.6641 69.0161L97.3984 80.7895C97.6562 81.2192 98.1719 81.3052 98.6016 81.0474L113.898 71.0786C114.328 70.8208 114.414 70.3052 114.156 69.8755L111.664 66.0083C111.406 65.5786 110.891 65.4927 110.461 65.7505L100.406 72.2817C99.9766 72.6255 99.4609 72.4536 99.2031 72.1099Z" fill="white" />
                                <path d="M62.25 72.5391L62.7656 71.25C62.9375 70.9063 63.4531 70.9063 63.5391 71.25L64.0547 72.5391C64.4844 73.5703 65.2578 74.3437 66.2891 74.7734L67.5781 75.2891C67.9219 75.4609 67.9219 75.9766 67.5781 76.0625L66.2031 76.4922C65.1719 76.9219 64.3984 77.6953 63.9687 78.7266L63.4531 80.0156C63.2812 80.3594 62.7656 80.3594 62.6797 80.0156L62.1641 78.7266C61.7344 77.6953 60.9609 76.9219 59.9297 76.4922L58.6406 75.9766C58.2969 75.8047 58.2969 75.2891 58.6406 75.2031L59.9297 74.6875C61.0469 74.3437 61.8203 73.4844 62.25 72.5391ZM123.266 106.914L123.781 105.625C123.953 105.281 124.469 105.281 124.555 105.625L125.07 106.914C125.5 107.945 126.273 108.719 127.305 109.148L128.594 109.664C128.938 109.836 128.938 110.352 128.594 110.437L127.219 110.867C126.188 111.297 125.414 112.07 124.984 113.102L124.469 114.391C124.297 114.734 123.781 114.734 123.695 114.391L123.18 113.102C122.75 112.07 121.977 111.297 120.945 110.867L119.656 110.352C119.312 110.18 119.312 109.664 119.656 109.578L120.945 109.062C122.062 108.719 122.836 107.859 123.266 106.914Z" fill="#FFDE39" />
                                <path d="M62.7656 97.8906C63.7149 97.8906 64.4844 97.1211 64.4844 96.1719C64.4844 95.2226 63.7149 94.4531 62.7656 94.4531C61.8164 94.4531 61.0469 95.2226 61.0469 96.1719C61.0469 97.1211 61.8164 97.8906 62.7656 97.8906Z" fill="white" />
                                <path d="M134.094 91.875C135.043 91.875 135.812 91.1055 135.812 90.1562C135.812 89.207 135.043 88.4375 134.094 88.4375C133.145 88.4375 132.375 89.207 132.375 90.1562C132.375 91.1055 133.145 91.875 134.094 91.875Z" fill="white" />
                                <path d="M177.702 92.16L173.311 88.2636L175.019 85.9258L178.922 90.3417L177.702 92.16Z" fill="#FF867B" />
                                <path d="M156.509 136.424L153.984 134.841L156.509 129.566L159.595 131.149L156.509 136.424Z" fill="#FF867B" />
                                <path d="M193.914 135.176L191.389 133.593L193.914 128.318L197 129.901L193.914 135.176Z" fill="#FF867B" />
                                <path d="M151.345 24.443L148.276 22.6019L152.018 16.3474L155.295 18.5497L151.345 24.443Z" fill="#FF6EB3" />
                                <path d="M18.9154 21.7148L16.2085 23.9358L20.5396 29.8583L23.0661 27.6373L18.9154 21.7148Z" fill="#00EF79" />
                                <path d="M58.6016 10.7078V7.37695L65.4592 8.74847V12.2752L58.6016 10.7078Z" fill="#B4A17A" />
                                <path d="M24.9365 66.5995V63.4824H31.1707V66.5995H24.9365Z" fill="#FF867B" />
                                <path d="M187.983 37.2974L185.779 34.2646L192.191 29.8164L194.195 32.6471L187.983 37.2974Z" fill="#FF6D79" />
                                <path d="M19.0211 109.106L16.8996 107.356L20.8703 102.472L23.3289 104.867L19.0211 109.106Z" fill="#96D6FD" />
                                <path d="M187.025 110.377V106.498L195.753 107.883L194.726 111.485L187.025 110.377Z" fill="#96D6FD" />
                                <path d="M170.194 120.422V118.344L173.311 119.383V121.461L170.194 120.422Z" fill="#00FFFD" />
                                <path d="M50.8528 27.9473L48.0029 29.3603L49.9622 35.0127L52.9903 33.5996L50.8528 27.9473Z" fill="#00FFFD" />
                                <path d="M179.277 0.517578L176.427 1.93066L178.387 7.58299L181.415 6.16991L179.277 0.517578Z" fill="#00FFFD" />
                                <path d="M169.619 64.3982L171.534 61.3917L176.764 65.061L175.215 68.0921L169.619 64.3982Z" fill="#58B37B" />
                                <path d="M4.63689 134.244L3.05194 131.051L8.89503 128.469L10.6907 131.36L4.63689 134.244Z" fill="#58B37B" />
                                <path d="M125.411 7.55695L123.671 1.93809L126.174 0.799255L128.191 6.57797L125.411 7.55695Z" fill="#96D6FD" />
                                <path d="M158.972 42.7921L166.597 38.3396L168.985 41.2884L161.641 46.0879L158.972 42.7921Z" fill="#FF867B" />
                                <path d="M0.622011 87.6533L0 83.9648L6.84995 82.8097L7.47196 86.4981L0.622011 87.6533Z" fill="#00FFFD" />
                                <path d="M30.8837 124.384L32.097 122.131L36.6776 125L34.8005 127.365L30.8837 124.384Z" fill="#FF6EB3" />
                                <path d="M47.1549 149.249L45.5093 146.398L51.2955 143.952L53.1347 147.138L47.1549 149.249Z" fill="#58B37B" />
                                <path d="M24.7481 143.497L26.2263 136.926L29.2441 136.417L28.0174 142.946L24.7481 143.497Z" fill="#00FFFD" />
                                <defs>
                                    <linearGradient id="paint0_linear_190_6957" x1="98" y1="42.0312" x2="98" y2="127.969" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#986EFA" />
                                        <stop offset="1" stop-color="#D27BB8" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>

                        </span>

                        <div className="badge-dilog-head">Shining Star</div>
                        <div className="badge-dilg-para">You Unlocked this badge by completing 15 tests on : 16/08/2022 </div>
                        <div className="tell-us-btn">
                            <a href="" className="tell-us-btn-a"><span className="share-icon-dialog"><img src={ShareIconSvg} alt="shareicon" /></span>Tell the world now!</a>
                        </div>
                    </div>
                </Dialog>
            </div>




        </>
    )
}