import Footer from "@Components/Footer/Footer";
import Header from "@Components/Header/Header";
import { AppRoutes } from "@Src/routes/Routes";
import { ReactNode } from "react";
import { MobHeader } from "@Components/Mob-header/Mob-header";
import './HomeLayout.scss'
interface Props {
    children?: ReactNode
    // any props that come into the component
}

const HomeLayout = ({children}: Props) => {
    return (
<>
<Header />
<MobHeader/>
{children}
<Footer />
</>
    );
};

export default HomeLayout;
