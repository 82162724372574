import { store } from "@Src/app/store";
import {DropdownValues} from "@Utils/service/interfaces";

export const convertDataIntoDropdownValues = (inputArray: any[], labelKey: string,valueKey: string): Array<DropdownValues> => {
    const returnArr: Array<DropdownValues> = [];
    inputArray.forEach((item: any) => {
        returnArr.push({
            label: item[labelKey],
            value: item[valueKey]
        })
    });
    return returnArr
}

export const isLoggedIn = () => {
    return store.getState().login.isLoggedIn;
}