import ApiService from "@Src/services/ApiService";
import { SUBJECT_DETAILS_API_ROUTES } from "./SubjectDetails.api.constant";

export async function getStandardClasses(params:any){
    return await ApiService.fetchData({
        url: SUBJECT_DETAILS_API_ROUTES.DETAILS + '/' + SUBJECT_DETAILS_API_ROUTES.STANDARD_CLASSES,
        method: 'get',
        params: params
    })
}

export async function getStandardClassesList(params:any) {
      return await ApiService.fetchData({
        url: SUBJECT_DETAILS_API_ROUTES.DETAILS + '/' +SUBJECT_DETAILS_API_ROUTES.STANDARD_CLASSES_LIST,
        method: 'get',
        params: params
      })
}

export async function getPopulartestList() {
  return await ApiService.fetchData({
    url: SUBJECT_DETAILS_API_ROUTES.DETAILS + '/' +SUBJECT_DETAILS_API_ROUTES.POPULAR_TEST,
    method: 'get',
    params: ''
  })
}

export async function getRecommendedTest() {
  return await ApiService.fetchData({
    url: SUBJECT_DETAILS_API_ROUTES.DETAILS + '/' +SUBJECT_DETAILS_API_ROUTES.RECOMMENDED_TEST,
    method: 'get',
    params: ''
  })
}