import righttickSvg from "@Assets/svg/Dashboard-svgs/righttick.svg";
import CloseSvg from "@Assets/svg/MockTest-svgs/Close.svg";

export const InstructionSetIndex = ({setinstructionValue}:any) => {

    return (
        <div className="instru-card">
        <a href="javascript:void(0)" className="inst-close-btn" >
        <img src={CloseSvg} alt="close-svg-img" onClick={() => setinstructionValue(false)} />
        </a>
        <div className="inst-heading">General Instructions:</div>

        <ul className="instru-ul">
          <li className="inst-li">
            Mindler brings you M-Quiz, a daily 20-minute exercise,
            that can help you stand out in your cohort
            <ul className="sub-inst-ul">
              <li className="inst-li">
                Each quiz consists of 20-questions from topics like
                logical reasoning, high school mathematics, basic
                science and general awareness.
              </li>
              <li className="inst-li">
                Every correct answer fetches you +1 and for every
                incorrect answer gets you -0.25.
              </li>
              <li className="inst-li">
                The clock will be set at the server. The countdown
                timer in the top right corner of screen will display
                the remaining time available for you to complete the
                examination. When the timer reaches zero, the
                examination will end by itself. You will not be
                required to end or submit your examination.(However,
                for the convenience of candidates, exception is made
                in BReT Solutions' Practice Test screen which provides
                for button to go to the 'Next Test' before the
                allotted time and also provides for button to 'Submit'
                before the end of examination.)
              </li>
            </ul>
          </li>
        </ul>

        <div className="response-qust-card">
          <div className="rspn-opt-card">A. Rice</div>
          <div className="rspn-opt-card active-opt-resp">B. Wheat <img className="righttick-Svg" src={righttickSvg} alt="righttickSvg" /></div>
          <div className="rspn-opt-card">C. Sugarcane</div>
          <div className="rspn-opt-card">D. Maize</div>
        </div>
      </div>    )
}
