
import "./Banner.scss";
import { Carousel } from 'primereact/carousel';
export const BannerIndex = (props: any) => {
    const onClickHandler = (path:string) => {
        window.open(path, '_blank')
    }

    const Banner = (bannerdata: any) => {
        return (
            <>
                <div >
                    <div className="platform-main-content">
                         {/* <div className="online-platform-left-content">
                                 <div className="plt-h1-head">A <span className="plt-head-span">Comprehensive  </span> <br /> <span className="plt-head-span">Online</span> Platform  <br /> for <span className="plt-head-span">Mock Tests</span> </div>
                            <div className="plt-h1-head-mob">A <span className="plt-head-span">Comprehensive Online </span>Platform for <span className="plt-head-span">Mock Tests</span> </div>
                            <p className="plt-para">Join the best and most affordable online platform for all your <br /> exam preparation needs</p>
                            <div className="mock-btn-card">
                            <button onClick={()=>onClickHandler(bannerdata.Path)} className="take-mock-test-btn">Take Mock Test</button>
                            </div>
                        
                        </div>  */}
                        <div className="online-platform-right-img">
                        <img onClick={()=>onClickHandler(bannerdata.Path)} src={bannerdata.Name} className="banner-img pointer-cursor" alt="banner-img" />
                        {/* <button onClick={()=>onClickHandler(bannerdata.Path)} className="take-mock-test-btn">Take Mock Test</button> */}


                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (

        <section className="online-platform-container">
            <Carousel value={props.bannerdata} numVisible={1} numScroll={1} itemTemplate={Banner} circular={false} autoplayInterval={10000} />
        </section>

    )
}