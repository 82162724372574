import Layout from "@Src/features/layout/components/layout/Layout";

const LayoutIndex = () => {

    return (
        <>
            <Layout/>
        </>
    );
}

export default LayoutIndex;
