
import { FILTER_KEYS } from '@Src/features/Filter/Constants/filters.constants';
import * as Yup from 'yup';

export const FiltersSchema = Yup.object({
    [FILTER_KEYS.TEST_SERIES] :Yup.object(),
    [FILTER_KEYS.BOARD] :Yup.object(),
    [FILTER_KEYS.CLASS]: Yup.object(),
    [FILTER_KEYS.SUBJECT]: Yup.object(),
    [FILTER_KEYS.CHAPTER]: Yup.array(),
    [FILTER_KEYS.TOPIC] : Yup.array(),
    [FILTER_KEYS.DIFFICULTY] : Yup.array(),
    [FILTER_KEYS.LANGUAGE] : Yup.object(),
    [FILTER_KEYS.SOURCE] : Yup.array(),
    [FILTER_KEYS.PREVIOUS_YEAR] : Yup.array(),
})


export const FiltersInitialvalue = {
    [FILTER_KEYS.TEST_SERIES]:{},
    [FILTER_KEYS.BOARD]:{},
    [FILTER_KEYS.CLASS]: {},
    [FILTER_KEYS.SUBJECT]: {},
    [FILTER_KEYS.CHAPTER]: [],
    [FILTER_KEYS.TOPIC] : [],
    [FILTER_KEYS.DIFFICULTY] : [],
    [FILTER_KEYS.LANGUAGE] : {},
    [FILTER_KEYS.SOURCE] : [],
    [FILTER_KEYS.PREVIOUS_YEAR] : []
}  

export const TestSeriesFiltersInitialvalue = {
    [FILTER_KEYS.TEST_SERIES]:{},
    [FILTER_KEYS.SUBJECT]: [],
    [FILTER_KEYS.CHAPTER]: [],
    [FILTER_KEYS.TOPIC] : [],
    [FILTER_KEYS.DIFFICULTY] : [],
    [FILTER_KEYS.LANGUAGE] : {},
    [FILTER_KEYS.SOURCE] : [],
    [FILTER_KEYS.PREVIOUS_YEAR] : []
}  