import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Email: '',
    userID: null,
    loginToken: '',
    isLoggedIn : false,
    Firstname: '',
    Lastname: '',
    previous_url:null
}

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setloginEmailID: (state, action) => {
            state.Email = action.payload;
        },
        setUserID: (state, action) => {
            state.userID = action.payload;
        },
        setLoginToken: (state, action) => {
            state.loginToken = action.payload;
        },
        setIsloggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setUserFirtsName: (state, action) => {
            state.Firstname = action.payload;
        },
        setUserLastName: (state, action) => {
            state.Lastname = action.payload;
        },
        setPreviousUrl: (state, action) => {
            state.previous_url = action.payload;
        },
    }
});

export default loginSlice.reducer;
export const { setloginEmailID, setUserID, setLoginToken ,setIsloggedIn , setUserFirtsName , setUserLastName, setPreviousUrl } = loginSlice.actions;