import { TestDetailsIndex } from "@Src/features/TestDetails"


export const TestDetails = () => {
   
    return (
        <>
        <TestDetailsIndex />
        </>
    )

}