import "../UserProfile.scss";
import { getUserDetails, UpdateuserProfile } from "../Services/UserProfile.sevice";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { EditProfileInitialValue, perosnalProfileSchema } from "../Schema/UserProfile.schema";
import { toast } from "react-hot-toast";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { GENDER_OPTONS  , COUNTRY_CODE} from "@Constants/app.constant";
import { getAllStatesList } from "@Src/features/Auth/components/exam_prefrences/services/examPrefrences.service";
import { useAppDispatch } from "@Src/app/hooks";
import { showSpinner } from "@Src/app/reducers/globalSlice";

export const EditProfileIndex = ({ setIsEdit, Userdetails }: any) => {

  // const [Userdetails, setuserdetails] = useState<any>([])
  const [editdata, seteditdata] = useState<Array<any>>([])
  const [stateList, setStateList] = useState<Array<any>>([]);
  const dispatch = useAppDispatch();

  let minDate = new Date('1899-01-01');
  let year = new Date().getFullYear() - 2
  let maxDate = new Date(`${year}-12-31`);
  let DOBYearRange = `1971:${year}`
  useEffect(() => {
    // getdetails()
    getStateList();
    setUserDetailsData(Userdetails);
  }, [])


  const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setValues } = useFormik({
    initialValues: EditProfileInitialValue,
    validationSchema: perosnalProfileSchema,


    onSubmit: (e) => {
      updateprofile(e)

    }
  })


  // const getdetails = () => {
  //   getUserDetails().then((response: APIResponse) => {
  //     if (response.ResponseType === APIStatus.SUCCESS) {
  //       setUserDetailsData(response?.data[0])

  //     }
  //   }).catch((error: any) => {
  //     console.log(error)
  //   })
  // }



  function setUserDetailsData(userdetailsAPI: any) {
    setValues({
      PhoneNo: userdetailsAPI?.PhoneNo,
      DOB: userdetailsAPI.DOB ? new Date(userdetailsAPI.DOB) : userdetailsAPI.DOB,
      Gender: userdetailsAPI.Gender ? userdetailsAPI.Gender : '',
      city: userdetailsAPI.City ? userdetailsAPI.City : '',
      stateId: userdetailsAPI.stateId ? userdetailsAPI.stateId : '',
      school: userdetailsAPI.School ? userdetailsAPI.School : '',
      countryCode: userdetailsAPI.CountryCode ? userdetailsAPI.CountryCode : '+91'
    })
  }
  // setuserdetails((previous: any) => {
  //   return {
  //     ...previous,
  //     FirstName: userdetailsAPI?.FirstName,
  //     LastName: userdetailsAPI?.LastName,
  //     EmailId: userdetailsAPI?.EmailId,
  //     PhoneNo: userdetailsAPI?.PhoneNo,
  //     DOB: userdetailsAPI?.DOB,
  //     Gender: userdetailsAPI.Gender,
  //     city: userdetailsAPI.city,
  //     stateId: userdetailsAPI.stateId,
  //     school: userdetailsAPI.school,
  //     countryCode: userdetailsAPI.countryCode    
  //   };

  // });

  const formattedDate = (dob: any) => {
    const date = new Date(dob);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let modifiedDate = [day, month, year].join('/');
    return date
    // setFieldValue('DOB', formattedDate)
  }
  // setValues({ PhoneNo: userdetailsAPI?.PhoneNo, DOB: formatDate() })


  const updateprofile = (e: any) => {
    const updateobj = {
      "DOB": e.DOB,
      "phoneNo": e.PhoneNo,
      Gender: e.Gender,
      city: e.city,
      stateId: e.stateId,
      school: e.school,
      countryCode: e.countryCode
    }
    if (isValid && touched) {
      dispatch(showSpinner(true));
      UpdateuserProfile(updateobj).then((response: APIResponse) => {
        dispatch(showSpinner(false));
        if (response.ResponseType === APIStatus.SUCCESS) {
          toast.success("User Details updated successfully")
          setIsEdit(false)
        } else {
          toast.error(response.StatusMsg)
        }
      }).catch((error) => {
        dispatch(showSpinner(false));

      })
    }
  }

  const getStateList = async () => {
    getAllStatesList().then((response: APIResponse) => {
      if (response.ResponseType == APIStatus.SUCCESS) {
        setStateList(response.data);
      }
    })
      .catch((error) => {
        console.log(error);

      })
  }


  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="profile-input-card">
          <div className="input-form-card">
            <div className="form-label-log">First Name</div>
            <input
              type="text"
              className="input-form-control"
              placeholder="Enter your first name"
              value={Userdetails.FirstName}
              readOnly
              disabled
            />
          </div>
          <div className="input-form-card">
            <div className="form-label-log">Last Name</div>
            <input
              type="text"
              className="input-form-control"
              placeholder="Enter your last name"
              value={Userdetails.LastName}
              readOnly
              disabled
            />
          </div>
          <div className="input-form-card">
            <div className="form-label-log">Email Address</div>
            <input
              type="text"
              className="input-form-control"
              placeholder="Enter your Email Address"
              value={Userdetails.EmailId}
              readOnly
              disabled

            />
          </div>
          <div className="input-form-card">
            <div className="form-label-log"> Mobile number <span className="mandiatory-star">*</span></div>


            <div className="tw-flex tw-items-center ">
              <div className="tw-mr-3">
                {/* <select className="select-form-control tw-p-0">
                  <option >+91</option>
                </select> */}
                <Dropdown
                  className="select-form-control tw-p-0"
                  value={values.countryCode}
                  name="countrycode"
                  onChange={handleChange}
                  options={COUNTRY_CODE}
                  optionLabel="Name" 
                  optionValue="Name"
                />
              </div>

              <input
                type="text"
                className="input-form-control"
                placeholder="Enter your Mobile number"
                value={values.PhoneNo}
                name='PhoneNo'
                maxLength={10}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {touched.PhoneNo && errors.PhoneNo ? (
              <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.PhoneNo}</p>
            ) : null}


          </div>
          <div className="input-form-card">
            <div className="form-label-log">Date of Birth <span className="mandiatory-star">*</span></div>
            {/* <input
                type="date"
                className="input-form-control"
                placeholder="Enter your Name"
                value={values.DOB}
                pattern="\d{2}-\d{2}-\d{4}"
                name="DOB"
                onChange={handleChange}
                onBlur={handleBlur}
                min='1899-01-01' max='2000-13-13'
              /> */}
            <Calendar className="Dob-calendar" value={values.DOB} onChange={(e) => { handleChange(e), setFieldValue('DOB', e.value) }} showIcon={true}
              minDate={minDate} maxDate={maxDate} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"
              yearRange={DOBYearRange} yearNavigator
            />

          </div>
          {touched.DOB && errors.DOB ? (
            <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.DOB}</p>
          ) : null}
          <div className="input-form-card">
            <div className="form-label-log">Gender <span className="mandiatory-star">*</span></div>
            <Dropdown
              className="prime-deopdown"
              placeholder="Please select your gender"
              value={values.Gender}
              name="Gender"
              onChange={(e) => { handleChange(e), setFieldValue('Gender', e.value) }}
              options={GENDER_OPTONS}
              optionLabel="Name" optionValue="Id"
            />
          </div>
          {touched.Gender && errors.Gender ? (
            <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.Gender}</p>
          ) : null}
          <div className="input-form-card">
            <div className="form-label-log">School Name <span className="mandiatory-star">*</span></div>
            <input
              type="text"
              className="input-form-control"
              placeholder="Enter your school name"
              value={values.school}
              onChange={(e) => { handleChange(e), setFieldValue('school', e.target.value) }}
            />
          </div>
          {touched.school && errors.school ? (
            <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.school}</p>
          ) : null}
          <div className="input-form-card">
            <div className="form-label-log">State <span className="mandiatory-star">*</span></div>
            <Dropdown
              className="prime-deopdown"
              placeholder="Please select your state"
              value={values.stateId}
              name="boardListId"
              onChange={(e) => { handleChange(e), setFieldValue('stateId', e.value) }}
              options={stateList}
              optionLabel="Name" optionValue="Id"
            />
          </div>
          {touched.stateId && errors.stateId ? (
            <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.stateId}</p>
          ) : null}
          <div className="input-form-card">
            <div className="form-label-log">City <span className="mandiatory-star">*</span></div>
            <input
              type="text"
              className="input-form-control"
              placeholder="Enter your city name"
              value={values.city}
              onChange={(e) => { handleChange(e), setFieldValue('city', e.target.value) }}
            />
          </div>
          {touched.city && errors.city ? (
            <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.city}</p>
          ) : null}
          <div className="tw-text-center tw-mt-9 ">
            <button className="user-profile-btn tw-w-36" type="submit" >Save</button>
          </div>
        </div>
      </form>
    </>
  );
};