
import "./Question-card.scss";
import { useEffect, useRef, useState } from "react";
import { StopWatchActions, TestTypes } from '@Constants/app.constant';
import { toast } from "react-hot-toast";
import { MathJax } from "better-react-mathjax";
export const QuestionCardIndex = ({ question, index, total, testType, testId, onClickSubmitionHandler, updateTestQuestionHandler, actionStopWatch, isTestSeries, onClickMarkForReview }: any) => {
    const options = Object.entries(question.Options);
    const [selectedOption, setSelectedOption] = useState('');
    const [flag, setFlag] = useState(false);
    const questionRef = useRef<any>(null)
    const answerRef = useRef<any>(null)
    const questionCardRef = useRef<any>(null)
    const executeQuestionScroll = () => questionRef.current.scrollIntoView()
    const executeAnswerScroll = () => answerRef.current.scrollIntoView()
    const [previousSelectedOption, setPreviousSelectedOption] = useState<string>(question.SelectedOption);

    useEffect(() => {
        setPreviousSelectedOption(question.SelectedOption);
        setSelectedOption(question.SelectedOption)
        setFlag(question.IsFlag);
    }, [index]);

    const onClickMultipleOption = (option: string) => {
        let previouslySelectedOption = selectedOption;
        setPreviousSelectedOption(previouslySelectedOption);
        let selectionOption: string;
        if (selectedOption && selectedOption.length && selectedOption.includes(option)) {
            let oldSelection = selectedOption;
            if (oldSelection.length > 2) {
                selectionOption = oldSelection.replace(`,${option}`, "");
                selectionOption = oldSelection.replace(`${option}`, "");
            } else {
                selectionOption = oldSelection.replace(`${option}`, "");
            }
        } else {
            if (selectedOption && selectedOption.length > 0) {
                selectionOption = `${selectedOption},${option}`;
            } else {
                selectionOption = option;
            }
        }
        setSelectedOption(selectionOption);
        let params: any = {
            "UserTestId": question.UserTestId,
            "QuestionId": question.QuestionId,
            "SelectedOption": selectionOption,
            "IsFlag": question.IsFlag,
            'previousSelectedOption':previouslySelectedOption
        }
        if (testType == TestTypes.speed) {
            if (isTestSeries) {
                params["examId"] = testId;
            } else {
                params["testId"] = testId;
            }
            params["difficultyLevel"] = question.Difficult;
            updateTestQuestionHandler(params);  
            onClickSubmitionHandler(params);
        } else {
            updateTestQuestionHandler(params);
            onClickSubmitionHandler(params);
        }
    }

    const onClickOption = (option: string) => {
        let previouslySelectedOption = selectedOption;
        setPreviousSelectedOption(previouslySelectedOption);
        setSelectedOption(option);
        let params: any = {
            "UserTestId": question.UserTestId,
            "QuestionId": question.QuestionId,
            "SelectedOption": option,
            "IsFlag": question.IsFlag,
            'previousSelectedOption':previouslySelectedOption
        }
        if (testType == TestTypes.speed) {
            if (isTestSeries) {
                params["examId"] = testId;
            } else {
                params["testId"] = testId;
            }
            params["difficultyLevel"] = question.Difficult;
            onClickSubmitionHandler(params)
            updateTestQuestionHandler(params);
        } else {
            updateTestQuestionHandler(params);
            onClickSubmitionHandler(params);
        }
    }

    const onClickFlag = (flag: boolean) => {
        let params: any = {
            "UserTestId": question.UserTestId,
            "QuestionId": question.QuestionId,
            "SelectedOption": selectedOption,
            "IsFlag": flag,
            'previousSelectedOption':previousSelectedOption
        }
        setFlag(flag);
        if (testType == TestTypes.speed) {
            if (isTestSeries) {
                params["examId"] = testId;
            } else {
                params["testId"] = testId;
            }
            params["difficultyLevel"] = question.Difficult;
            onClickSubmitionHandler(params)
        } else {
            updateTestQuestionHandler(params);
            onClickSubmitionHandler(params);
            onClickMarkForReview(params);
        }
    }
        
    return (
        <>

            <div id={`questioncard_${question.QuestionId}`} ref={questionCardRef} className="qust-page-card">
                <div className="qust-anst-card">
                    <div>
                    <span onClick={executeQuestionScroll} className="qust-span">Q</span>
                    <span onClick={executeAnswerScroll} className="ans-span">A</span>
                    </div>               
                    <span className="qust-subjct">{question.SubjectName}</span>
                </div>

                <div className="qust-marks-card">
                    <div className="tw-flex">
                        <span className="qust-num">Question {index + 1}
                            {testType == TestTypes.regular ? `/${total}` : ''}
                        </span>
                        <span>
                        </span>
                    </div>
                    <div>
                        <span className="marks-span">Marks : <span className="ngvt-mark-span"> +{question.PositiveMark} | {question.NegativeMark > 0 ? -question.NegativeMark : question.NegativeMark}</span></span>
                    </div>

                </div>

                <div className="qust-main-card">
                    <div className="main-qust-card">
                        <div id={`question_${question.QuestionId}`} ref={questionRef} className="main-qust" ></div>
                        <MathJax dangerouslySetInnerHTML={{ __html: question.Question }} dynamic={true} >
                        </MathJax>
                        <div id={`answer${question.QuestionId}`} ref={answerRef} className="qust-options-card">
                            {
                                question.IsMultiple == 1 ?
                                 options.map((option: any) => {
                                    return (

                                        option[1] ? <div onClick={() => onClickMultipleOption(option[0])} key={option[0]}
                                            className={`opt-div ${(selectedOption && (selectedOption.includes(option[0]))) ? "active-selected" : ""}`}>
                                            <span className="alpb-opt" dangerouslySetInnerHTML={{ __html: option[0] }} ></span>
                                            <span className="opt-label">
                                                <MathJax dangerouslySetInnerHTML={{ __html: option[1] }} dynamic={true} >
                                                </MathJax>
                                            </span>
                                        </div> : ''
                                    )
                                })
                                    : options.map((option: any) => {
                                        return (
                                            option[1] ? <div onClick={() => onClickOption(option[0])} key={option[0]}
                                                className={`opt-div ${(option[0] == selectedOption) ? "active-selected" : ""}`}>
                                                <span className="alpb-opt" dangerouslySetInnerHTML={{ __html: option[0] }} ></span>
                                                <span className="opt-label">
                                                    <MathJax dangerouslySetInnerHTML={{ __html: option[1] }} dynamic={true} >
                                                    </MathJax>
                                                </span>
                                            </div> : ''
                                        )
                                    })
                            }
                        </div>
                    </div>
                    <div className="response-marked-card">
                        <div>
                            <button className="clear-resp-btn" onClick={() => onClickOption('')}  >Clear Response</button>
                        </div>
                        <div>
                            {testType == TestTypes.speed ? '' :
                                flag ? <button className="clear-resp-btn active-btn" onClick={() => onClickFlag(false)}  >Marked For Review</button> : <button className="clear-resp-btn active-btn" onClick={() => onClickFlag(true)}  >Mark For Review</button>
                            }
                        </div>
                    </div>

                </div>

            </div>

        </>
    )
}