
import { Link } from "react-router-dom";
import "./Das-header.scss";
import { useAppSelector } from "@Src/app/hooks";

export const DashHeaderIndex = () => {

    const FirstName = useAppSelector( state => state.login.Firstname)
    const LastName = useAppSelector( state => state.login.Lastname)




    return (
        <>

            <div className="dash-header-sect">
                <div className="header-logo">
                    <Link to='/'>
                        <img src="https://i.postimg.cc/hjp3fD3K/Sample-Logo.png" />
                    </Link>
                </div>


                <div className="tw-flex tw-items-center ">
                    {/* <span className="notif-bell-span">
                        <img src={NotificationBellSvg} alt="notification-svg-img" />
                    </span> */}

                    <div className="user-name">
                       {FirstName[0]}{LastName[0]}
                    </div>
                </div>

            </div>



        </>
    )
}