import { useNavigate } from "react-router-dom";
import { AppRouteURL, AppRouteURLwithChilds, DetailsRouteURL, TestSereisRouteURL } from "@Constants/app-route-url.constant";
import './Header.scss'
import { DropdownHover } from "./Components/DropdownHover/DropdownHover";
import { useAppDispatch, useAppSelector } from "@Src/app/hooks";
import { useEffect, useState } from "react";
import { getAllClassList, getSuggestions, postAllSubjectList, postAllTestSeriesList } from "./services/Header.service";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { setClassList, setSubjectList, setTestSeriesList } from "@Src/app/reducers/header/headerSlice";
import DownArrow from "@Assets/svg/Home_svgs/DownArrow.svg";
import SearchIcon from "@Assets/svg/Home_svgs/SearchIcon.svg";
import ActiveSearchIcon from "@Assets/svg/HeaderSvg/ActiveSearchicon.svg";
import { Link } from 'react-router-dom';
import { isLoggedIn } from "@Src/utils/service/app.utility.service";
import { setIsloggedIn, setLoginToken, setUserFirtsName, setUserID, setUserLastName, setloginEmailID } from "@Src/app/reducers/auth/loginSlice";
import { toast } from "react-hot-toast";
import { getUserDeviceDetails } from "@Src/utils/service/device-details.service";
import { AutoComplete } from 'primereact/autocomplete';
import SearchCloseSvg from "@Assets/svg/HeaderSvg/Searchclose.svg"
import LogoutSvg from "@Assets/svg/HeaderSvg/Logout.svg";
import ActiveLogoutSvg from "@Assets/svg/HeaderSvg/ActiveLogout.svg";
import { useFormik } from "formik";

const Header = () => {

    const navigation = useNavigate();
    const [allClassList, setAllClassList] = useState<Array<any>>([]);
    const dispatch = useAppDispatch();
    const [allSubjectList, setAllSubjectList] = useState<Array<any>>([]);
    const [allTestSeriesList, setAllTestSeriesList] = useState<Array<any>>([]);
    const headerStore = useAppSelector(state => state.header);
    const FirstName = useAppSelector(state => state.login.Firstname)
    const lastName = useAppSelector(state => state.login.Lastname)
    const isLogin = isLoggedIn();
    const currentpath = window.location.pathname;
    const [suggestions, setSuggestions] = useState<Array<any>>([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState<Array<any>>([]);
    const search = (event: any) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            if (!event.query.trim().length) {
            }
            else if (event.query.trim().length < 3) {
            } else {
                let searchText = event.query.trim();
                getSearchSuggestions(searchText)
            }
        }, 250);
    }

    /**
     * get initial List of Class
     */
    useEffect(() => {
        getClassList();
        getSubjectList();
        getTestSeriesList();
    }, []);

    useEffect(() => {
        if (allClassList && allClassList.length > 0) {
            dispatch(setClassList(allClassList));
        }
    }, [allClassList]);

    useEffect(() => {
        if (allSubjectList && allSubjectList.length > 0) {
            dispatch(setSubjectList(allSubjectList));
        }
    }, [allSubjectList]);

    useEffect(() => {
        if (allTestSeriesList && allTestSeriesList.length > 0) {
            dispatch(setTestSeriesList(allTestSeriesList));
        }
    }, [allTestSeriesList]);

    /**
     * get initial List of Class
     */
    function getClassList() {
        getAllClassList().then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setAllClassList(response?.data);
            }
        })
    }

    function getSubjectList() {
        postAllSubjectList().then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                setAllSubjectList(response?.data.sort((a: any, b: any) => a.Name.localeCompare(b.Name)));
            }
        })
    }

    function getTestSeriesList() {
        postAllTestSeriesList().then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                setAllTestSeriesList(response?.data);
            }
        })
    }
    const logout = () => {
        dispatch(setUserID(null));
        dispatch(setloginEmailID(''));
        dispatch(setLoginToken(''));
        dispatch(setUserFirtsName(''));
        dispatch(setUserLastName(""));
        dispatch(setIsloggedIn(false));

        toast.success('Student loggged out successfully');

    }


    const itemTemplate = (item: any) => {

        return (
            <div className="search-suggetion-box"  >
                <div>
                    <div className="sug-head"> {item.Name}</div>
                    <div className="sub-sug-card">
                        {item.PName1 ? <> <span>{item.PName1}</span></> : ""}
                        {item.PName2 ? <>| <span>{item.PName2}</span></> : ""}
                        {item.PName3 ? <>| <span>{item.PName3}</span></> : ""}
                    </div>

                </div>

            </div>
        );
    };

    const onEnterAutocomplete = (e: any) => {
        setSelectedSuggestion(e.value);
        if (e.originalEvent && (e.originalEvent.type == "click" || e.originalEvent.key == "Enter"))
            clickEventHandler(e.value)
    }

    function getSearchSuggestions(searchText: string) {
        let data = {
            'searchKey': searchText
        }
        getSuggestions(data).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                setSuggestions(response?.data);
            } else {
                setSuggestions([])
            }
        })
    }

    const clickEventHandler = (item: any) => {
        navigation(`${DetailsRouteURL.SEARCH}?${item.Flag}Id=${item.Id}&${item.Flag}Name=${item.Name}${item.PFlag1 ? `&${item.PFlag1}Id=${item.PId1}&${item.PFlag1}Name=${item.PName1}` : ''}${item.PFlag2 ? `&${item.PFlag2}Id=${item.PId2}&${item.PFlag2}Name=${item.PName2}` : ''}${item.PFlag3 ? `&${item.PFlag3}Id=${item.PId3}&${item.PFlag3}Name=${item.PName3}` : ''}`)
        onClickCrossSearch()
    }

    const onClickCrossSearch = () => {
        setSelectedSuggestion([]);
        setSuggestions([])
    }
    return (
        /* <!-------------------------------------header-section-------------------------------> */
        <>
            {!getUserDeviceDetails().isMobileDevice && <section className={`header-container  ${currentpath === "/home" ? "bg-home-page" : ''}`}>
                <div className="tw-flex tw-items-center">
                    <div className="header-logo">
                        <Link to='/'>
                            <img src="https://i.postimg.cc/hjp3fD3K/Sample-Logo.png" />
                        </Link>
                    </div>
                    <div className="header-searchbox">
                        {/* <span>
                            <img src={SearchIcon} className="search-svg-img" />
                        </span> */}

                        {/* <input type="text" className="search-input" placeholder="Want to take a test?" /> */}
                        <AutoComplete placeholder="Want to take a test?" field="Name" className="auto-search-input" value={selectedSuggestion}
                            suggestions={suggestions} completeMethod={search} minLength={3} delay={250}
                            onChange={(e: any) => { onEnterAutocomplete(e) }} itemTemplate={itemTemplate}
                            autoHighlight={true} showEmptyMessage={true} emptyMessage={'Suggestions Not Found'}
                        />
                        {selectedSuggestion && selectedSuggestion.length > 0 ? <div onClick={() => setSelectedSuggestion([])} className="search-clear-card"> <img src={SearchCloseSvg} className="search-svg-img " /></div> : <div className="search-drop-down-container">
                            <img src={ActiveSearchIcon} className="search-svg-img" />
                        </div>}





                    </div>
                    <div className="dropdown-container">
                        {headerStore.class && headerStore.class.length > 0 ? <div className="header-dropdowns-card">
                            <DropdownHover Heading='Class' list={headerStore.class} pageName={DetailsRouteURL.CLASSES_DETAILS} />
                        </div> : ''}
                        {headerStore.subject && headerStore.subject.length > 0 ? <div className="header-dropdowns-card">
                            <DropdownHover Heading='Subject' list={headerStore.subject} pageName={DetailsRouteURL.SUBJECT_DETAILS} />
                        </div> : ''}
                        {headerStore.testSeries && headerStore.testSeries.length > 0 ? <div className="header-dropdowns-card">
                            <DropdownHover Heading='Test Series' list={headerStore.testSeries} pageName={TestSereisRouteURL.TEST_SERIES} />
                        </div> : ''}

                    </div>
                </div>
                {!isLogin && <div className="tw-flex tw-items-center ">
                    <div className="sign-card">
                        <a href="javascript:void(0)" onClick={() => navigation(AppRouteURL.AUTH + AppRouteURLwithChilds.AUTH.childrens.LOGIN.path)}>Sign in</a>
                    </div>
                    <div className="create-accnt-btn">
                        <a href="javascript:void(0)" onClick={() => navigation(AppRouteURL.AUTH + AppRouteURLwithChilds.AUTH.childrens.REGISTER.path)}> Create free account</a>
                    </div>
                </div>}
                {isLogin && <div className="user-loged-in ">
                    <div className="user-name">
                        <span>{FirstName ? FirstName[0] : ''}{lastName ? lastName[0] : ''}</span>
                    </div>
                    <ul className="login-drop-down-prof-dash">
                        <li className="dropdown-ui-1"><a href="javascript:void(0)">{FirstName} {lastName}<span className="down-arrow-svg">
                            <img src={DownArrow} />
                        </span></a>
                            <ul className="dropdown-ui-container">
                                <li className="user-dropdown-li" onClick={() => navigation(AppRouteURL.USERPROFILE + AppRouteURL.MYPROFILE)}><a href="javascript:void(0)">My Profile</a></li>
                                <li className="user-dropdown-li" onClick={() => navigation(AppRouteURL.DASHBOARD + AppRouteURL.DASHBOARD_HOME)}><a href="javascript:void(0)">Dashboard</a></li>
                                <li className="user-dropdown-li"><a href="javascript:void(0)" onClick={() => navigation(AppRouteURL.CONTACT)}>Contact Us</a></li>
                                <li className="user-dropdown-li"><a onClick={logout} href="javascript:void(0)">
                                    <span>
                                        <img src={LogoutSvg} className="head-logout-img" alt="logout-svg" />
                                    </span>
                                    <span>
                                        <img src={ActiveLogoutSvg} className="head-active-logout-img" alt="logout-svg" />
                                    </span> Logout</a></li>
                            </ul >
                        </li>
                    </ul>
                </div >}
            </section >}
        </>


    );
};

export default Header;
