import { HOME_API_ROUTES } from "@Src/features/Home/services/Home.api.constant";
import ApiService from "@Src/services/ApiService";

export async function getAllClassList() {
    return await ApiService.fetchData({
        url: HOME_API_ROUTES.HOME +  '/' +  HOME_API_ROUTES.CLASS_LIST,
        method: "get",
    });
}

export async function postAllSubjectList() {
    return await ApiService.fetchData({
        url: HOME_API_ROUTES.HOME +  '/' + HOME_API_ROUTES.SUBJECT_LIST,
        method: 'get'
    })
}

export async function postAllTestSeriesList() {
    return await ApiService.fetchData({
        url: HOME_API_ROUTES.HOME +  '/' + HOME_API_ROUTES.TEST_SERIES_LIST,
        method: 'get'
    })
}

export async function getSuggestions(params:any) {
    return await ApiService.fetchData({
        url: HOME_API_ROUTES.SEARCH_SUGGESTIONS,
        method: 'get',
        params: params
    })
}