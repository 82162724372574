import React, { lazy } from "react";
import Loader from "@Components/loader/Loader";

const AUTH = lazy(() =>
  import('@Pages/Auth/Auth')
    .then(({ Auth }) => ({ default: Auth })),
);

const Register = lazy(() =>
  import('@Pages/Auth/Register/Register')
    .then(({ Register }) => ({ default: Register })),
);

const ExamPrefrences = lazy(() =>
  import('@Pages/Auth/exam_prefrences/exam_prefrences')
    .then(({ ExamPrefrences }) => ({ default: ExamPrefrences })),
);

const LOGIN = lazy(() =>
  import('@Pages/Auth/Login/Login')
    .then(({ Login }) => ({ default: Login })),
);

const LOGOUT = lazy(() =>
  import('@Pages/Auth/Logout/Logout')
    .then(({ Logout }) => ({ default: Logout })),
);

const VerifyEmail = lazy(() =>
  import('@Pages/Auth/verify_email/verify_email')
    .then(({ VerifyEmail }) => ({ default: VerifyEmail })),
);

const VERIFY_OTP = lazy(() =>
  import('@Pages/Auth/Verify_otp/Verify_otp')
    .then(({ VerifOtp }) => ({ default: VerifOtp })),
);

const RESET_PASSWORD = lazy(() =>
  import('@Pages/Auth/reset_password/reset_password')
    .then(({ RESET_PASSWORD }) => ({ default: RESET_PASSWORD })),
);

const FORGOT_PASSWORD = lazy(() =>
  import('@Pages/Auth/forgot_password/forgot_password')
    .then(({ FORGOT_PAASWORD }) => ({ default: FORGOT_PAASWORD })),
);

const UpdateMobile = lazy(() =>
  import('@Pages/Auth/updateMobile/updateMobile')
    .then(({ UpdateMobile }) => ({ default: UpdateMobile })),
);

const HOME = lazy(() =>
  import('@Pages/Home/Home')
    .then(({ Home }) => ({ default: Home })),
);

const CLASSES_DETAILS = lazy(() =>
  import('@Pages/ClassesDetails/ClassesDetails')
    .then(({ ClassesDetails }) => ({ default: ClassesDetails })),
);

const SUBJECT_DETAILS = lazy(() =>
  import('@Pages/SubjectDetails/SubjectDetails')
    .then(({ SubjectDetails }) => ({ default: SubjectDetails })),
);

const TEST_SERIES_DETAILS = lazy(() =>
  import('@Pages/TestSeriesDetails/TestSeriesDetails')
    .then(({ TestSeriesDetails }) => ({ default: TestSeriesDetails })),
);

const TEST_LISTING = lazy(() =>
  import('@Pages/TestListing/index')
    .then(({ TestListing }) => ({ default: TestListing })),
);

const TEST_DETAILS = lazy(() =>
  import('@Pages/TestDetails/index')
    .then(({ TestDetails }) => ({ default: TestDetails })),
);

const DASHBOARD = lazy(() =>
  import('@Pages/Dashboard/index')
    .then(({ Dashboard }) => ({ default: Dashboard })),
);
const PROFILE = lazy(() =>
  import('@Pages/UserProfile/UserProfile')
    .then(({ UserProfile }) => ({ default: UserProfile })),
);

const MOCKTEST = lazy(() =>
  import('@Pages/MockTest/MockTest')
    .then(({ Test }) => ({ default: Test })),
);

const SPEED_TEST = lazy(() =>
  import('@Pages/SpeedTest/SpeedTest')
    .then(({ SpeedTest }) => ({ default: SpeedTest })),
);


export const AppRouteURL = {
	HOME: '/home',
	USERPROFILE: '/userprofile/',
	MYPROFILE: 'myprofile',
	EDITPROFILE: 'editprofile',
	CHANGEPASS: 'changepassword',
	COURSEPREFERD: 'coursepreferences',
	EDITCOURSE: 'editcourse',
	DASHBOARD: '/dashboard/',
	//DASHBOARDPAGE: 'dashboardpage',
	MOCKTEST: 'mocktest',
	MOCKTEST_SERIES: 'mocktestseries',
	INSTRUCTIONS: 'instructions',
	LEADEBOARD: 'leaderboard',
	TESTRESULT:'testresult',
	MYBADGES: 'mybadges',
	AUTH: '/auth/',
	CONTACT:'/contactus',
	DASHBOARD_HOME: 'home',
}
export const AppRouteURLwithChilds = {
	AUTH: {
		path: "/auth/",
		component: AUTH,
		chilren: true,
		childrens: {
			REGISTER: {
			   path: "register",
			   component: Register   
			},
			EXAM_PREFRENCES: {
			   path: 'exam_prefrences',
			   component: ExamPrefrences
			},
			LOGIN: {
				path: 'login',
				component: LOGIN
			 },
			 LOGOUT: {
				path: 'logout',
				component: LOGOUT
			 },
			 VERIFY_EMAIL: {
				path: 'verifyEmail',
				component: VerifyEmail
			 },
			 VERIFY_OTP: {
				path: 'verifyOtp',
				component: VERIFY_OTP
			 },
			 RESET_PASSWORD: {
				path: 'resetPassword',
				component: RESET_PASSWORD
			 },
			 FORGOT_PASSWORD: {
				path: 'forgotPassword',
				component: FORGOT_PASSWORD
			 },
			 UPDATE_MOBILE: {
				path: 'updateMobile',
				component: UpdateMobile
			 }
		 }
	}
}

export const DetailsRouteURL = {
	CLASSES_DETAILS: '/class',
	TEST_DETAILS: '/testdetail',
	SUBJECT_DETAILS: '/subject',
	SEARCH:'/search'
 }

 export const TestRouteURL = {
	TEST: '/test',
	SPEED_TEST: '/speedtest',
	TEST_SERIES: '/testseries',
	SPEED_TEST_SERIES: '/speedtestseries',
 }

 export const TestSereisRouteURL = {
	TEST_SERIES: '/testseries',
	TEST_SERIES_DETAIL: '/testseriesdetail',
 }
