export const TEST_DETAILS_API_ROUTES = {
   DETAILS: 'testPage',
   SIMILAR_TEST :'similarTest',
   TEST_DETAILS: 'testDetails',
   TEST_LIST: 'testSearchDetailsUsingFilter',
   SEARCH_FILTERS: 'search/filters',
   TEST_SERIES_LIST: 'testSeries/testSeriesSearchUsingFilter',
   TEST_SERIES_FILTERS: 'testSeries/filters',
   EXAM_DETAILS:'examDetails',
   TEST:'test',
   PREVIOUS_OPEN_TEST:'previousOpenTest',
   END_PREVIOUS_OPEN_TEST:'endPreviousTestIfOpen'
}