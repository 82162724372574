import { useEffect, useState } from 'react'
import './StandardClasses.scss'
export const StandardClasses = ({ standardClasses,getAllStandardClassesList }: any) => {
    const [selectedStandards, setselectedStandards] = useState(-1);
    useEffect(() => {
        if (standardClasses && standardClasses.length > 0) {
            let id = standardClasses[0]['Id'];
            setselectedStandards(id);
            if(id > -1){
                getAllStandardClassesList(id);
            }
        }
    }, [standardClasses]);
    
   const  getStandardClassListData =(id:number) => {
    setselectedStandards(id);   
    getAllStandardClassesList(id);
   }
    
    return (
        <>
            <section className="all-classes-tabs-container">
                <div className={`a-cls-t-card ${(selectedStandards == 0) ? "active-cls-card" : ""}`}  onClick={() => { getStandardClassListData(0) }} >
                    All Classes
                </div>
                {
                    standardClasses && standardClasses.length > 0 && standardClasses.map((element: any) => {
                        return <div key={element.Id} onClick={() => { getStandardClassListData(element.Id)
                        }}
                            className={`a-cls-t-card ${(selectedStandards == element.Id) ? "active-cls-card" : ""}`} >
                            {element.Name}
                        </div>
                    })
                }
            </section>
        </>
    )
}