import "./Loader.scss"

const mindlerIcon = `${process.env.REACT_APP_MINDLER_PRODUCT_IMAGES_URL}/profile-builder/icons/mindler.png`;

const Loader = () => {
    return (
        <div className="spinner">
            <div className="spinner-icon">
                <img src={mindlerIcon} alt="" />
            </div>
        </div>
    )
}

export default Loader