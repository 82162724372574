import "./Test-question-card.scss";
import PauseSvg from "@Assets/svg/Dashboard-svgs/pause.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { QuestionCardIndex } from "./Question-card";
import { QuestionPaletteIndex } from "./Question-palette";
import { getQuestion, getTestSeriesQuestion, postFinalSubmitTest, postPauseTest, postQuestion, postResumeTest, postSubmitQuestion, postSubmitTestSeriesQuestion, postTestSeriesQuestion, postTimerTest } from "../../services/MockTest.service";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { useNavigate, useParams } from "react-router-dom";
import { MyTimer } from "@Components/CountDown/CountDown";
import Play from "@Assets/svg/MockTest-svgs/Play.svg";
import { FullQuestionPaper } from "../FullQuestionPaper/FullQuestionPaper";
import { AppRouteURL } from "@Constants/app-route-url.constant";
import { toast } from "react-hot-toast";
import { InstructionSetIndex } from "../InstructionSet/InstructionSet";
import { getUserDeviceDetails } from "@Src/utils/service/device-details.service";
import { useAppSelector } from "@Src/app/hooks";
import { CountDownActions, TestTypes, StopWatchActions } from '@Constants/app.constant';
import { QuestionPaperFooter } from "../QuestionPaperFooter/QuestionPaperFooter";
import { PauseTestDialog } from "@Components/PauseTestDialog/PauseTestDialog";
import { EndTestDialog } from "@Components/EndTestDialog/EndTestDialog";
import { ExpireTimeEndTestDialog } from "@Components/ExpireTimeEndTestDialog/ExpireTimeEndTestDialog";
import { QuestionpaperrightFooter } from "../QuestionPaperFooter/Questionpaperrightfooter";
import { StopWatch } from "@Components/StopWatch/StopWatch";
import MarkReview from "@Assets/svg/MockTest-svgs/MarkReview.svg";
import { useTimer } from 'react-use-precision-timer';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';

export const TestQuestionIndex = ({ testId, noOfQuestion, timeDuration, isTestSeries, testIds, testName }: any) => {
  const navigation = useNavigate();
  const [instruction, setinstruction] = useState(false);
  const [fullQuestionPaperMode, setFullQuestionPaperMode] = useState(false);
  const [Pauesedialog, setPauesedialog] = useState(false);
  const [questionsStore, setAllQuestions] = useState<any[]>([]);
  const [endTestDialog, setEndTestDialog] = useState(false);
  const [action, setAction] = useState('');
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [expireTestDialog, setExpireTestDialog] = useState(false);
  const [Qustpalette, setQustpalette] = useState(false);
  const time = new Date();
  const [initialBatch, setInitialBatch] = useState(1);
  const userID = useAppSelector(state => state.login.userID)
  const [questionInSingleBatch, setQuestionInSingleBatch] = useState(5);
  const [actionStopWatch, setActionStopWatch] = useState('');
  const [indexInNeed, setIndexInNeed] = useState(0);
  const questionQuotient = Math.floor(noOfQuestion / questionInSingleBatch);
  const questionRemainder = noOfQuestion % questionInSingleBatch;
  const questionBatch = (questionQuotient + (questionRemainder > 0 ? 1 : 0))
  time.setSeconds(time.getSeconds() + (timeDuration));
  const timer: any = useRef();
  const testType = TestTypes.regular;
  const params = useParams()
  const [spendTime, setSpendTime] = useState(0);
  const [submitAnswer, setSubmitAnswer] = useState<any>({});
  const ref: any = useRef();

  useEffect(() => {
    if (isTestSeries) {
      postAllTestSeriesQuestion()
    } else {
      postAllQuestion()
    }
  }, []);

  useEffect(() => {
    if (initialBatch > 1 && initialBatch <= questionBatch) {
      if (initialBatch == questionBatch) {
        setQuestionInSingleBatch(questionRemainder)
      }
      if (isTestSeries) {
        getAllTestSeriesQuestions();
      } else {
        getAllQuestions();
      }
    }
  }, [initialBatch]);

  useEffect(() => {
    if (questionsStore && questionsStore.length && questionsStore[0] && questionsStore[0]['UserTestId']) {
      let param = {
        "userTestId": questionsStore[0]['UserTestId'],
        "timeTake": 0
      }
      postTimerTest(param);

      timer.current = setInterval(() => {
        let params = {
          "userTestId": questionsStore[0]['UserTestId'],
          "timeTake": 15
        }
        postTimerTest(params);
      }, 15000);
    }
    return () => clearInterval(timer.current);
  }, [questionsStore[0]]);

  useEffect(() => {
    if (action == CountDownActions.PAUSE) {
      clearInterval(timer.current);
    } else if (action == CountDownActions.RESUME) {
      timer.current = setInterval(() => {
        let params = {
          "userTestId": questionsStore[0]['UserTestId'],
          "timeTake": 15
        }
        postTimerTest(params);
      }, 15000);
//       useTimer({ delay: 15000 }, useCallback(() => {
//         let params = {
//         "userTestId": questionsStore[0]['UserTestId'],
//         "timeTake": 15
//     }
//     postTimerTest(params);
// }, []));
    }
  }, [action]);

  useEffect(() => {
    let params: any = submitAnswer;
    if (params && params['UserTestId']) {
      params['timeSpend'] = ref.current.getStopWatchTimeHandler();
      if (isTestSeries) {
        postSubmitTestSeriesQuestionAnswer(params)
      } else {
        postSubmitQuestionAnswer(params)
      }
    }
  }, [submitAnswer])

  const postAllQuestion = () => {
    let params = {
      "testId": testId,
      "duration": timeDuration,
      "noOfQuestion": noOfQuestion

    }
    if (testId) {
      postQuestion(params).then((response: APIResponse) => {
        if (response?.ResponseType == APIStatus.SUCCESS) {
          getAllQuestions()
        } else {
          toast.error("Test Cannot be started")
        }
      })
    }
  }

  const postAllTestSeriesQuestion = () => {
    let params = {
      "examId": testId,
      "duration": timeDuration,
      "noOfQuestion": noOfQuestion,
      "testIds": testIds
    }
    if (testId) {
      postTestSeriesQuestion(params).then((response: APIResponse) => {
        if (response?.ResponseType == APIStatus.SUCCESS) {
          getAllTestSeriesQuestions()
        } else {
          toast.error("Test Cannot be started")
        }
      })
    }
  }

  const getAllQuestions = () => {
    let params = {
      "testId": testId,
      "pageNo": initialBatch,
      "pageSize": questionInSingleBatch
    }
    if (testId) {
      getQuestion(params).then((response: APIResponse) => {
        if (response?.ResponseType == APIStatus.SUCCESS) {
          let appendedQuestions;
          if (questionsStore && questionsStore.length) {
            appendedQuestions = questionsStore.concat(response.data);
          } else {
            appendedQuestions = response.data;
          }
          setAllQuestions(appendedQuestions);
          if (initialBatch < questionBatch) {
            setInitialBatch(initialBatch + 1);
          }
        } else {
        }
      });
    }
  }

  const getAllTestSeriesQuestions = () => {
    let params = {
      "examId": testId,
      "pageNo": initialBatch,
      "pageSize": questionInSingleBatch
    }
    if (testId) {
      getTestSeriesQuestion(params).then((response: APIResponse) => {
        if (response?.ResponseType == APIStatus.SUCCESS) {
          let appendedQuestions;
          if (questionsStore && questionsStore.length) {
            appendedQuestions = questionsStore.concat(response.data);
          } else {
            appendedQuestions = response.data;
          }
          setAllQuestions(appendedQuestions);
          if (initialBatch < questionBatch) {
            setInitialBatch(initialBatch + 1);
          }
        } else {
        }
      });
    }
  }

  const onClickPreiousQuestion = () => {
    if (indexInNeed > 0) {
      setIndexInNeed(indexInNeed - 1)
    }
  }

  const onClickNextQuestion = () => {
    questionsStore[indexInNeed]['IsVisited'] = true;
    if (indexInNeed < questionsStore.length - 1) {
      let params: any = {};
      params = {
        "UserTestId": questionsStore[indexInNeed].UserTestId,
        "QuestionId": questionsStore[indexInNeed].QuestionId,
        "SelectedOption": questionsStore[indexInNeed].SelectedOption,
        "IsFlag": questionsStore[indexInNeed].IsFlag,
        "previousSelectedOption": questionsStore[indexInNeed].previousSelectedOption
      };
      let selectedOption: string = params.SelectedOption;
      let previousSelectedOption: string = params.previousSelectedOption;
      console.log("params", params, selectedOption, previousSelectedOption, selectedOption == previousSelectedOption)
      if (previousSelectedOption != null && selectedOption == previousSelectedOption) {
        postTestSubmitAnswer();
      }else if(selectedOption != null){
        postTestSubmitAnswer();
      } else {
        params['previousSelectedOption'] = params['SelectedOption'];
        questionsStore[indexInNeed].previousSelectedOption = questionsStore[indexInNeed].SelectedOption;
        setSubmitAnswer(params);
      }
      setIndexInNeed(indexInNeed + 1);
    }
  }

  const onClickEndTest = () => {
    setEndTestDialog(true);
  }

  const onClickPauseTest = () => {
    setPauesedialog(true)
  }

  const endTest = () => {
    setEndTestDialog(false);
    setExpireTestDialog(false);
    let params = {
      "userTestId": questionsStore[0]['UserTestId'],
    }
    postFinalSubmitTest(params).then((response: APIResponse) => {
      if (response?.ResponseType == APIStatus.SUCCESS) {
        if (isTestSeries) {
          navigation(`${AppRouteURL.DASHBOARD}${AppRouteURL.TESTRESULT}/${userID}?examId=${testId}&testType=1&userTestId=${questionsStore[0]['UserTestId']}`)
        } else {
          navigation(`${AppRouteURL.DASHBOARD}${AppRouteURL.TESTRESULT}/${userID}?testId=${testId}&testType=1&userTestId=${questionsStore[0]['UserTestId']}`)
        }
      }
    })
    onClickNextQuestion();
  }

  const pauseTest = () => {
    setAction(CountDownActions.PAUSE);
    setActionStopWatch(StopWatchActions.PAUSE)
    let params = {
      "userTestId": questionsStore[indexInNeed]['UserTestId'],
    }
    postPauseTest(params).then((response: APIResponse) => {
      if (response?.ResponseType == APIStatus.SUCCESS) {
      }
    })
  }

  const resumeTest = () => {
    setPauesedialog(false);
    setEndTestDialog(false);
    setAction(CountDownActions.RESUME);
    setActionStopWatch(StopWatchActions.START)
    let params = {
      "userTestId": questionsStore[0]['UserTestId'],
    }
    postResumeTest(params).then((response: APIResponse) => {
      if (response?.ResponseType == APIStatus.SUCCESS) {
      }
    })
  }

  const runningEventHandler = (isRunning: boolean) => {
    setIsTimerRunning(isRunning);
  }

  const expireEventHandler = () => {
            // as using worrker timeres, so mention window for default set time out
    window.setTimeout(()=>{
      setAction(CountDownActions.PAUSE);    
      setExpireTestDialog(true);
  }, 1000);
  }

  const jumpToQuestionHandler = (indexNew: number) => {
    questionsStore[indexInNeed]['IsVisited'] = true;
    let params: any = {};
    params = {
      "UserTestId": questionsStore[indexInNeed].UserTestId,
      "QuestionId": questionsStore[indexInNeed].QuestionId,
      "SelectedOption": questionsStore[indexInNeed].SelectedOption,
      "IsFlag": questionsStore[indexInNeed].IsFlag,
      "previousSelectedOption": questionsStore[indexInNeed].previousSelectedOption
    };
    let selectedOption: string = params.SelectedOption;
    let previousSelectedOption: string = params.previousSelectedOption;
    console.log("params", params, selectedOption, previousSelectedOption, selectedOption == previousSelectedOption)
    if (previousSelectedOption != null && selectedOption == previousSelectedOption) {
      postTestSubmitAnswer();
    }else if(selectedOption != null){
      postTestSubmitAnswer();
    }  else {
      params['previousSelectedOption'] = params['SelectedOption'];
      questionsStore[indexInNeed].previousSelectedOption = questionsStore[indexInNeed].SelectedOption;
      setSubmitAnswer(params);
    }
    setIndexInNeed(indexNew);
    setQustpalette(false);
  }

  const onClickSubmitionHandler = (params: any) => {
    postTestSubmitAnswer();
    setSubmitAnswer(params);
  }

  const updateTestQuestionHandler = (params: any) => {
    questionsStore[indexInNeed]['IsFlag'] = params['IsFlag'];
    questionsStore[indexInNeed]['SelectedOption'] = params['SelectedOption'];
    questionsStore[indexInNeed]['previousSelectedOption'] = params['previousSelectedOption'];
    questionsStore[indexInNeed]['IsVisited'] = true;
  }

  const onClickPreiousQuestionHandler = () => {
    onClickPreiousQuestion()
  }

  const setQuestionpaletteHandler = () => {
    setQustpalette(!Qustpalette)
  }

  const onClickNextQuestionHandler = () => {
    onClickNextQuestion()
  }

  const setFullQuestionPaperModeHandler = (value: boolean) => {
    setFullQuestionPaperMode(value)
    setQustpalette(!Qustpalette)
  }

  const setinstructionHadndler = (value: boolean) => {
    setinstruction(value)
    setQustpalette(false)
  }

  const onClickEndTestHandler = () => {
    onClickEndTest()
  }

  const setPauesedialogHandler = (value: boolean) => {
    setPauesedialog(value);
  }

  const pauseTestHandler = () => {
    pauseTest()
  }

  const resumeTestHandler = () => {
    resumeTest()
  }

  const setEndTestDialogHandler = (value: boolean) => {
    setEndTestDialog(value)
  }

  const endTestHandler = () => {
    endTest()
  }

  const postTestSubmitAnswer = () => {
    getStopWatchSpendTime();
  }

  const getStopWatchSpendTime = () => {
    setAction(StopWatchActions.RESET);
  }

  const postSubmitQuestionAnswer = (params: any) => {
    postSubmitQuestion(params).then((response: APIResponse) => {
      if (response?.ResponseType == APIStatus.SUCCESS) {
        setAction('')
      }
    })
  }

  const postSubmitTestSeriesQuestionAnswer = (params: any) => {
    postSubmitTestSeriesQuestion(params).then((response: APIResponse) => {
      if (response?.ResponseType == APIStatus.SUCCESS) {
        setAction('')
      }
    })
  }

  const onActionChange = (time: number) => {
     setSpendTime(time);
  }

  const askNextTestQuestionHandler = (index: number) => {
  }

  const onClickMarkForReview =(params:any)=>{
    if (params['IsFlag'] == true) {
      toast(
          <span className="qust-toast-card tw-flex ">
              <div>
                  <img src={MarkReview} alt="MarkReview" />
              </div>
              <div className="tw-ml-2">
                  <p className="marked-span">Marked For Review</p>
                  <p className="marked-status">
                      You have marked the question for review.
                  </p>
              </div>
          </span>
      );
  } else if(params['IsFlag'] == false) {
      toast(
          <span className="qust-toast-card tw-flex ">
              <div>
                  <img src={MarkReview} alt="MarkReview" />
              </div>
              <div className="tw-ml-2">
                  <p className="marked-span">Unmarked For Review</p>
                  <p className="marked-status">
                      You have unmarked the question for review.
                  </p>
              </div>
          </span>
      );

  }

  }

  const onClickBackButtonHandler = (value:boolean)=>{
    setQustpalette(value);
    setFullQuestionPaperMode(false);
    setinstruction(false);
  }
  return (
    <>

      <div className="test-question-container">
        <div className="test-qust-cards-container">
          <div className="test-qust-header">
            <div className="left-qust-header">
              <div className="t-qust-heading">{testName}</div>
              <div className="t-qust-timer">
                <span className="timer-span">
                  <MyTimer expiryTimestamp={time} action={action} runningEventHandler={runningEventHandler} expireEventHandler={expireEventHandler} />
                </span>
                <StopWatch action={action} onActionChange={onActionChange} askNextSpeedTestQuestionHandler={askNextTestQuestionHandler} indexInNeed={indexInNeed} ref={ref} />
                <span className="pause-svg" onClick={() => onClickPauseTest()} >
                  {isTimerRunning ?
                    <img src={PauseSvg} alt="Pause-Svg" />
                    : <img src={Play} alt="Play-Svg" />

                  }
                </span>
              </div>
            </div>

            <div className="quest-palette-header">Question Palette</div>
          </div>

          <div className="qust-palette-container">
            <div className="test-question-card">
              { questionsStore.length ?
              <div className="qust-container">
                <QuestionCardIndex question={questionsStore[indexInNeed]} index={indexInNeed} total={questionsStore.length} testType={testType} testId={testId} onClickSubmitionHandler={onClickSubmitionHandler} updateTestQuestionHandler={updateTestQuestionHandler} actionStopWatch={actionStopWatch} isTestSeries={isTestSeries} onClickMarkForReview={onClickMarkForReview} />
              </div>
              :''
              }
              {/* {fullQuestionPaperMode && questionsStore.length && 
              <div className="qust-container">
                <FullQuestionPaper questions={questionsStore} />
              </div>
              } */}

              <QuestionPaperFooter testType={testType} fullQuestionPaperMode={fullQuestionPaperMode} index={indexInNeed} questionsStoreLength={questionsStore.length} instruction={instruction} Qustpalette={Qustpalette} onClickPreiousQuestionHandler={onClickPreiousQuestionHandler} setQuestionpaletteHandler={setQuestionpaletteHandler} onClickNextQuestionHandler={onClickNextQuestionHandler} setFullQuestionPaperModeHandler={setFullQuestionPaperModeHandler} setinstructionHadndler={setinstructionHadndler} onClickEndTestHandler={onClickEndTestHandler} isTestSeries={isTestSeries} />
            </div>

            <div>
              <div className={`qust-palette-card  ${(instruction || Qustpalette ) ? 'qust-mob-card isactive-card' : ''}`}>
                {instruction && (
                  <InstructionSetIndex setinstructionValue={setinstruction} />
                )}

                {!getUserDeviceDetails().isMobileDevice && !instruction && <QuestionPaletteIndex questions={questionsStore} jumpToQuestionHandler={jumpToQuestionHandler} testType={testType} fullQuestionPaperMode={fullQuestionPaperMode}  onClickBackButtonHandler={onClickBackButtonHandler}/>}

                {getUserDeviceDetails().isMobileDevice && !instruction && Qustpalette && <QuestionPaletteIndex questions={questionsStore} jumpToQuestionHandler={jumpToQuestionHandler} testType={testType} onClickBackButtonHandler={onClickBackButtonHandler} fullQuestionPaperMode={fullQuestionPaperMode} />}

              </div>
              <QuestionpaperrightFooter testType={testType} fullQuestionPaperMode={fullQuestionPaperMode} instruction={instruction} setFullQuestionPaperModeHandler={setFullQuestionPaperModeHandler} setinstructionHadndler={setinstructionHadndler} onClickEndTestHandler={onClickEndTestHandler} />
            </div>

          </div>



        </div>
      </div>

      <PauseTestDialog Pauesedialog={Pauesedialog} setPauesedialogHandler={setPauesedialogHandler} pauseTestHandler={pauseTestHandler} resumeTestHandler={resumeTestHandler} />

      <EndTestDialog endTestDialog={endTestDialog} setEndTestDialogHandler={setEndTestDialogHandler} endTestHandler={endTestHandler} resumeTestHandler={resumeTestHandler} />

      <ExpireTimeEndTestDialog expireTestDialog={expireTestDialog} endTestHandler={endTestHandler} />
    </>
  );
};
