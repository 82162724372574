import { APIResponse, APIStatus } from '@Src/services/baseInterfaces';
import { useEffect, useState } from 'react';
import HomeLayout from '../layout/components/HomeLayout/HomeLayout';
import { getStandardClasses, getStandardClassesList } from './services/SubjectDetails.service';
import DetailsPageBanner from '@Components/DetailsPageBanner/DetailsPageBanner';
import { StandardClasses } from './Components/StandardClasses/StandardClasses';
import { useParams } from 'react-router-dom';
import { SubscriptionUpdates } from '@Components/SubscriptionUpdates/SubscriptionUpdates';
import { PopularTestSeries } from './Components/PopularTestSeries/PopularTestSeries';
import { OtherTestRecommendation } from './Components/OtherTestRecommendation/OtherTestRecommendation';
import { StandardClassesList } from './Components/StandardClasses/StandardClassesList';
import { HeaderNameKeys } from '@Constants/app.constant';
const SubjectDetailsIndex = () => {
    const params = useParams();
    const subjectId = params.subjectId;
    let subjectName = params.subjectName;
    // subjectName = subjectName?.replace(/_/g,' ');
    let Headings = [        
        {name:subjectName, type:HeaderNameKeys.SUBJECT, id:subjectId},
    ]
    const [standardClasses, setStandardClasses] = useState<Array<any>>([]);
    const [standardClassesList, setstandardClassesList] = useState<Array<any>>([]);
    const [headingsBreadcrumb, setHeadingsBreadcrumb] = useState<Array<any>>(Headings);
    useEffect(() => {
        window.scrollTo(0, 0)
        getAllStandardClasses();
    }, [subjectId]);

    function getAllStandardClasses() {
        setStandardClasses([])
        let param = {
            subjectId: subjectId
        }
        getStandardClasses(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setStandardClasses(data);
            }
        })
    }

    function getAllStandardClassesList(id: number) {
        let params: any;
        if (id > -1) {
            params = {
                "subjectId": subjectId
            };
            if (id > 0) {
                params["standerClassId"] = id
            }
            getStandardClassesList(params).then((response: APIResponse) => {
                if (response?.ResponseType == APIStatus.SUCCESS) {
                    let data = response?.data;
                    setstandardClassesList(data);
                }
            })
        }
    }

    return (
        <>
            <HomeLayout >
                {/* <!-----------------------------------test-listining-section-------------------------------> */}
                <div className="test-listining">
                    <DetailsPageBanner Headings={headingsBreadcrumb} />
                    <StandardClasses standardClasses={standardClasses} getAllStandardClassesList={getAllStandardClassesList} />
                    <StandardClassesList standardClassesList={standardClassesList} subjectId={subjectId} name={params.subjectName} subjectName={subjectName} />
                    <PopularTestSeries />
                    <OtherTestRecommendation />
                    <SubscriptionUpdates />
                </div>
                {/* <!----------------------------------end-test-listining-section------------------------------->  */}
            </HomeLayout>

        </>
    );
}

export default SubjectDetailsIndex;

