import ApiService from "@Src/services/ApiService";
import { EXAM_PREFRENCES_API_ROUTES } from "./examPrefrences.api.constant";


export async function getAllClassList(queryParams : any) {
    return ApiService.fetchData({
        url: EXAM_PREFRENCES_API_ROUTES.GET_USER_CLASS_LIST,
        method: "get",
        params : queryParams

    });
}

export async function getAllBoardList() {
    return ApiService.fetchData({
        url: EXAM_PREFRENCES_API_ROUTES.GET_BOARD_LIST,
        method: "get",
    });
}

export async function getsubjectListByClass(queryParams : any) {
    return ApiService.fetchData({
        url: EXAM_PREFRENCES_API_ROUTES.GET_SUBJECT_LIST,
        method: "get",
        params : queryParams
    });
}

export async function insertCoursePref(data : any) {
    return ApiService.fetchData({
        url: EXAM_PREFRENCES_API_ROUTES.SUBMIT_COURSE_PREF,
        method: "post",
        data
    });
}

export async function getEducationInterestData() {
    return ApiService.fetchData({
        url: EXAM_PREFRENCES_API_ROUTES.EDUCATION_INTEREST,
        method: "get",
    });
}

export async function getAllStatesList() {
    return ApiService.fetchData({
        url: EXAM_PREFRENCES_API_ROUTES.STATE_LIST,
        method: "get",
    });
}


