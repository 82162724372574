import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    resendOTP : false
}

const verifyEmailSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setResendOTP: (state, action) => {
            state.resendOTP = action.payload;
        }
    }
});

export default verifyEmailSlice.reducer;
export const { setResendOTP } = verifyEmailSlice.actions;