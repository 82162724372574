import BetterLuckNextSVg from "@Assets/svg/Dashboard-svgs/Better-luck-next.svg";
import RankedCrownSvg from "@Assets/svg/Dashboard-svgs/Rankedcrown.svg";
import OverallscoreSvg from "@Assets/svg/Dashboard-svgs/Overallscore.svg";
import AttemptsSvg from "@Assets/svg/Dashboard-svgs/Attempts.svg";
import TimeSvg from "@Assets/svg/Dashboard-svgs/Time.svg";
import PassSvg from "@Assets/svg/Dashboard-svgs/Pass.svg";
import { AppRouteURL } from "@Constants/app-route-url.constant";
import { useNavigate } from "react-router-dom";

export const LeaderboardResult = ({ testType, resultdata, LeaderDetails, userValueId, examId, testId, TimeValue }: any) => {
    const navigation = useNavigate()

    const rankvalue = LeaderDetails.findIndex((detail: any) => {
        return LeaderDetails ? detail.userId === userValueId : ''
    })

    const TestResultPercent = ((resultdata[0]?.['positiveMarks'] - resultdata[0]?.['negativeMarks']) / (resultdata[0]?.['totalMarks'])) * 100

    const navigateToLeaderBoard = () => {
        if (examId) {
            navigation(`${AppRouteURL.DASHBOARD}${AppRouteURL.LEADEBOARD}/${userValueId}?examId=${examId}&testType=${testType}`)
        } else {
            navigation(`${AppRouteURL.DASHBOARD}${AppRouteURL.LEADEBOARD}/${userValueId}?testId=${testId}&testType=${testType}`)
        }
    }


    return (
        <>
            <div className="test-paper-name">{resultdata[0] && resultdata[0].Name}</div>
            <div className="paper-result" >
                <div className="paper-total-number-details">
                    {testType === 1 && <ul className="paper-lists">
                        <li>
                            <span className="paper-d-box-1">
                                <span className="paper-name-1"> Total Questions </span>
                                <span
                                    className="paper-status-1"> {resultdata[0]?.['NoOfQuestion']} </span>
                            </span>
                        </li>
                        <li>
                            <span className="paper-d-box-1">
                                <span className="paper-name-1"> Maximum Marks </span>
                                <span
                                    className="paper-status-1"> {resultdata[0]?.['totalMarks']} </span>
                            </span>
                        </li>
                        <li>
                            <span className="paper-d-box-1">
                                <span className="paper-name-1"> Question Answered </span>
                                <span className="paper-status-1"> {resultdata[0]?.["attendQuestion"]} </span>
                            </span>
                        </li>
                        <li>
                            <span className="paper-d-box-1">
                                <span className="paper-name-1"> Questions Unanswered </span>
                                <span className="paper-status-1"> {resultdata[0] ? (resultdata[0]?.['NoOfQuestion']) - (resultdata[0]?.['attendQuestion']) : ''} </span>
                            </span>
                        </li>
                        <li>
                            <span className="paper-d-box-1">
                                <span className="paper-name-1"> Correct Answers </span>
                                <span className="paper-status-1">{resultdata[0]?.['correctAns']} </span>
                            </span>
                        </li>
                        <li>
                            <span className="paper-d-box-1">
                                <span className="paper-name-1"> Incorrect Answers </span>
                                <span className="paper-status-1"> {resultdata[0]?.['incorrectAns']} </span>
                            </span>
                        </li>
                    </ul>}

                    {testType === 2 && <ul className="speed-test-paper-lists">


                        <li>
                            <span className="paper-d-box-1">
                                <span className="paper-name-1">Question Attempted</span>
                                <span className="paper-status-1"> {resultdata[0]?.["attendQuestion"]} </span>
                            </span>
                        </li>

                        <li>
                            <span className="paper-d-box-1">
                                <span className="paper-name-1"> Correct Answers </span>
                                <span className="paper-status-1">{resultdata[0]?.['correctAns']} </span>
                            </span>
                        </li>
                        <li>
                            <span className="paper-d-box-1">
                                <span className="paper-name-1"> Incorrect Answers </span>
                                <span className="paper-status-1"> {resultdata[0]?.['incorrectAns']} </span>
                            </span>
                        </li>
                    </ul>}
                </div>

                <div className="paper-total-number">
                    <ul className="ui-list-sec">
                        <li className="leader-board-li">
                            <div className="your-ranked-card">
                                <div className="view-leader-board-card">
                                    <span className="v-l-b-a" onClick={() => navigateToLeaderBoard()}>View Leaderboard</span>
                                </div>
                                <div className="your-ranked-content-card">
                                    <span className="rank-score-card">
                                        <span className="better-name-1"> You Ranked </span>
                                        <span className="rancked-scored">{LeaderDetails ? (rankvalue + 1) : ''}</span>
                                    </span>
                                    <div>
                                        <img src={RankedCrownSvg} alt="ranked-svg" />
                                    </div>
                                </div>
                            </div>
                        </li>

                        <div className="tw-flex">
                            <li className="highlight-result">
                                <span className="overallscore-bg  paper-d-box-1">
                                    <span>
                                        <span className="paper-name-1"> Overall Score </span>
                                        <span className="paper-status-1"> {resultdata[0] ? (resultdata[0]?.['positiveMarks']) - (resultdata[0]?.['negativeMarks']) : ''} </span>
                                    </span>
                                    <img src={OverallscoreSvg} alt="overall-svg" />
                                </span>
                            </li>

                            {TestResultPercent >= 40 ? <li className="highlight-result">
                                <span className="result-card-bg  paper-d-box-1">
                                    <span>
                                        <span className="paper-name-1"> Overall Result </span>
                                        <span className="paper-status-pass"> Pass </span>
                                    </span>
                                    <img src={PassSvg} alt="pass-svg" />
                                </span>
                            </li> :
                                <li
                                    className="highlight-result ">
                                    <span className="bg-betterluck  paper-d-box-1">
                                        <span>
                                            <span className="better-name-1"> Better Luck Next Time! </span>
                                        </span>
                                        <img src={BetterLuckNextSVg} alt="brtter-luck-svg" />
                                    </span>
                                </li>}



                        </div>

                        <div className="tw-flex">

                            <li className="total-attempts-sec">
                                <div className="attempts-sec-card">
                                    <span className="total-attempts-head">Total Attempts</span>
                                    <span
                                        className="attempts-status">{resultdata[0]?.['attempt']}</span>
                                </div>
                                <div>
                                    <img src={AttemptsSvg} alt="Attempts-svg" />
                                </div>
                            </li>

                            <li className="time-sec">
                                <div className="time-status-card">
                                    <span className="time-head">Time</span>
                                    <span className="timer-status">{resultdata[0] ? TimeValue : ''}</span>
                                </div>
                                <div>
                                    <img src={TimeSvg} alt="time-svg" />
                                </div>
                            </li>

                        </div>


                    </ul>

                </div>
            </div></>)
}