import axios from 'axios';
import BaseService from './BaseService'
import {APIResponse} from "@Services/baseInterfaces";

const ApiService = {
    fetchData(param:any) {
        return new Promise<APIResponse>((resolve, reject) => {
            BaseService(param).then(response => {
                resolve(response?.data)
            }).catch(errors => {
                reject(errors)
            })
        })
    },

    fetchMultipleData(param1:any, param2:any) {
        return new Promise<APIResponse>((resolve, reject) => {
           BaseService( param1,param2 ).then((response:any) => {
                resolve(response?.data)
            })
            .catch(function(errors) {
                reject(errors);
            });
        })
    }

}

export default ApiService