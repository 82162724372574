import { Link } from 'react-router-dom';
import './SubjectTests.scss';
import { Skeleton } from 'primereact/skeleton';

const SubjectTests = ({ subjectList, classId, name, board }: any) => {
    return (
        <>
            <section className="most-popular-series-container ">
                <div className="mock-test-listing-header">
                    Select Your Subject
                </div>
                <div className="most-popular-cards-container">
                    {
                        subjectList.map((subject: any) => {
                            let subjectName = subject.Name;
                            return (
                                <div key={subject.Id} className='subject-card'>
                                    <img src={subject.Image} alt="subject-img" className='subjct-img' />
                                    <div className="s-c-right">
                                        <div className="s-c-r-head">{subject.Name}</div>
                                        <div className="s-c-r-para">{subject.Description}</div>
                                        <div className="stnd-cls-card-btn">
                                            <Link to={`/${name}/${classId}/${subjectName}/${subject.Id}${board && board.Id ?  `?boardId=${board.Id}&boardName=${board.Name}` : ''}`} >
                                                <button className="take-test-btn">View Test</button>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </section>

            <section className="most-popular-series-container tw-hidden">
                <div className="mock-test-listing-header">
                    Select Your Subject
                </div>

                <div className="most-popular-cards-container">
                    <div className='subject-card'>
                        <img src="https://i.postimg.cc/jqNhgh2S/Saly-1-3.png" className='subjct-img' alt="subject-img" />
                        <div className="s-c-right">
                            <div className="s-c-r-head">English Language</div>
                            <div className="s-c-r-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                            <div className="stnd-cls-card-btn">
                                <button className="take-test-btn">Take Test</button>
                            </div>
                        </div>

                    </div>

                </div>

            </section>

        </>

    )
}

export const SubjectTestsSkeleton = () => {
    return (
        <div>
            <ul className="m-0 tw-px-5 tw-pt-2 list-none tw-flex tw-flex-wrap ">
                {[...Array(12)].map((e, i) => (
                    <li className="m-2 border-round border-1 tw-p-3 skeleton-card tw-text-center">
                        <div>
                            <Skeleton width="100%" height='14rem' className='tw-mb-3'></Skeleton>
                            <div >
                                <Skeleton className="tw-mx-auto" width='70%' height='1.5rem'></Skeleton>
                                <Skeleton width="10rem" className='tw-mx-auto tw-mt-2' height='3rem'></Skeleton>
                            </div>
                        </div>
                    </li>
                ))
                }
            </ul>
        </div>

    )
}

export default SubjectTests