import { TestIndex } from "@Src/features/MockTest"


export const Test = () => {

    return (
        <>
         <TestIndex />
        </>
    )
}