import './SubscriptionUpdates.scss'
export const SubscriptionUpdates = () => {
    return ( <></>
        // <section className="subscrp-update-mock-test">
        //     <div className="subp-left-user-cards">
        //         <span className="subp-left-user-1">
        //             <img src="https://i.postimg.cc/qBnBJPfg/Ellipse-168.png" />
        //         </span>
        //         <span className="subp-left-user-2">
        //             <img src="https://i.postimg.cc/59L7v4bM/Ellipse-167.png" />
        //         </span>
        //         <span className="subp-left-user-3">
        //             <img src="https://i.postimg.cc/NfLtB2Rx/Ellipse-166.png" />
        //         </span>
        //     </div>
        //     <div className="subp-update-card">
        //         <div className="sub-upd-heading">
        //             Subscribe To Get Latest Updates <br /> On Our Mock Tests
        //         </div>
        //         <div className="sub-upd-para">
        //             Join the best and most affordable online platform for all your exam preparation needs.
        //         </div>
        //         <div className="subscribe-email-box">
        //             <input type="text" placeholder="Enter your email" />
        //             <button className="subsc-btn" >Subscribe</button>
        //         </div>
        //     </div>
        //     <div className="subp-right-user-cards">
        //         <span className="subp-right-user-1">
        //             <img src="https://i.postimg.cc/GpnN64cz/Ellipse-166-1.png" />
        //         </span>
        //         <span className="subp-right-user-2">
        //             <img src="https://i.postimg.cc/yNrRkZky/Ellipse-167-1.png" />
        //         </span>
        //         <span className="subp-right-user-3">
        //             <img src="https://i.postimg.cc/43HMSzkY/Ellipse-168-1.png" />
        //         </span>
        //     </div>
        // </section>
    )
}