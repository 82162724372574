import { useTimer } from 'react-timer-hook';
import { useEffect } from "react";
import {CountDownActions} from '@Constants/app.constant';

export function MyTimer({ expiryTimestamp, action, runningEventHandler,expireEventHandler }: any) {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => expireEventHandler() });

    useEffect(() => {
        if (action == CountDownActions.PAUSE) {
            pause()
            runningEventHandler(false)
        } else if (action == CountDownActions.RESUME) {
            resume()
            runningEventHandler(true)
        }
    }, [action])
    return (
        <>
           {hours > 9 ? hours : `0${hours}`}:{minutes > 9 ? minutes : `0${minutes}`}:{seconds > 9 ? seconds : `0${seconds}`}
        </>
    );
}
