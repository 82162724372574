import "../UserProfile.scss";
import { getCouresePreferences, getUserDetails } from "../Services/UserProfile.sevice";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { useEffect, useState } from "react";
import PersonalCheckSvg from "@Assets/svg/Myprofile/Profilechecked.svg"
import { EditProfileIndex } from "../EditProfile";
import ProfileBarFilled from "@Assets/svg/UserProfilesvg/ProfileBarFilled.svg";
import ProfileBarEmpty from "@Assets/svg/UserProfilesvg/ProfileBarEmpty.svg";
import TickCircle from "@Assets/svg/UserProfilesvg/TickCircle.svg";
import { Skeleton } from "primereact/skeleton";
import { GENDER } from "@Constants/app.constant";

export const MyProfileIndex = () => {
  const [profiledetails, setprofiledetails] = useState<Array<any>>([])
  const [isEdit, setIsEdit] = useState(false);
  const [coursePrefernce, setCoursePrefernce] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [proflieStrength, setProfileStrength] = useState<number>(0);
  const [prefrenceStrength, setPrefrenceStrength] = useState<number>(0);
  const [totalStrength, setTotalStrength] = useState<number>(0);
  const [barStrength, setBarStrength] = useState<number>(0);
  const [unFilledProfileDetails , setUnFilledProfileDetails ] = useState<any>([]);
  const [unFilledCourseDetails , setUnFilledCourseDetails ] = useState<any>([]);

  let strength: any = {
    FirstName: 5,
    LastName: 5,
    EmailId: 10,
    PhoneNo: 10,
    DOB: 10,
    Gender: 5,
    School: 10,
    stateName: 10,
    City: 10,
    boardDetails: 5,
    classDetails: 15,
    subjectDetails: 5
  }

  let detailsProfile: any = {
    FirstName: 'First Name',
    LastName: 'Last Name',
    EmailId: 'Email',
    PhoneNo: 'Mobile Number',
    DOB: 'Date of Birth',
    Gender: 'Gender',
    School: 'School Name',
    stateName: 'State',
    City: 'City',
  }

  let detailsCourses: any = {
    boardDetails: 'Board',
    classDetails: 'Class',
    subjectDetails: 'Subjects'
  }

  useEffect(() => {
    if (!isEdit) {
      getprofiledata()
    }
  }, [isEdit])

  useEffect(() => {
    getcourseprefer();
  }
    , []);

 useEffect(()=>{
        setTotalStrength(proflieStrength+prefrenceStrength)
 },[proflieStrength, prefrenceStrength])

 useEffect(()=>{
  if(totalStrength > 0){
  let barCount = totalStrength/20;
  setBarStrength(barCount)
  }
},[totalStrength])


  useEffect(() => {
    if (profiledetails && profiledetails.length) {
      let calculatedStrnegth =(profiledetails[0]?.FirstName ? strength['FirstName'] : 0) + (profiledetails[0]?.LastName ? strength['LastName'] : 0) + (profiledetails[0]?.EmailId ? strength['EmailId'] : 0) + (profiledetails[0]?.PhoneNo ? strength['PhoneNo'] : 0) + (profiledetails[0]?.DOB ? strength['DOB'] : 0) + (profiledetails[0]?.Gender ? strength['Gender'] : 0) + (profiledetails[0]?.School ? strength['School'] : 0) + (profiledetails[0]?.stateName ? strength['stateName'] : 0) + (profiledetails[0]?.City ? strength['City'] : 0);
      setProfileStrength(calculatedStrnegth);
      let unFilledDetails:Array<string> = [];
        let keysProfile = Object.keys(detailsProfile);
        keysProfile.forEach((key:string)=>{
          if(profiledetails[0][key]){

          }else{
            unFilledDetails.push(detailsProfile[key]);
          }
        })
        setUnFilledProfileDetails(unFilledDetails);
    }
  }
    , [profiledetails]);

  const getprofiledata = () => {
    setIsLoading(true)
    getUserDetails().then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        setprofiledetails(response?.data);
        setIsLoading(false);

      }
    }).catch((error: any) => {
      console.log(error)
    })
  }

  const getcourseprefer = () => {
    setIsLoading(true)
    getCouresePreferences().then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        setCoursePrefernce(response?.data);
        setIsLoading(false);
          let calculatedStrnegth =  ((response?.data?.boardDetails && response?.data?.boardDetails.length) ? strength['boardDetails'] : 0) + ((response?.data?.classDetails && response?.data?.classDetails.length) ? strength['classDetails'] : 0) + ((response?.data?.subjectDetails && response?.data?.subjectDetails.length) ? strength['subjectDetails'] : 0);
          setPrefrenceStrength( calculatedStrnegth);   
          let unFilledDetails:Array<string> = [];
          let keysCourses = Object.keys(detailsCourses);
          keysCourses.forEach((key:string)=>{
            if(response?.data[key] && response?.data[key].length){
  
            }else{
              unFilledDetails.push(detailsCourses[key]);
            }
          })
          setUnFilledCourseDetails(unFilledDetails);
    
        }
    }).catch((error: any) => {
      console.log(error)
    })
  }

  function formatDate() {
    const date = new Date(profiledetails[0]?.DOB);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return profiledetails[0]?.DOB ? [day, month, year].join('-') : '';
  }

  const onClickEditDetails = () => {
    setIsEdit(true);
  }

  return (
    <>
      <div className="user-profile-right-container ">
        {!isLoading ? (
          <div className="user-act-header  tw-flex">
            <span className="user-name-card tw-mr-5">
              {profiledetails[0]?.FirstName[0]}
              {profiledetails[0]?.LastName[0]}
            </span>

            <div>
              <div className="usr-prf-heading">
                {profiledetails[0]?.FirstName} {profiledetails[0]?.LastName}
              </div>
              {coursePrefernce ? (
                <div className="user-prf-para">
                  Course : { coursePrefernce["classDetails"] && coursePrefernce["classDetails"].length ? coursePrefernce["classDetails"][0]["Name"] : ''}{" "}
                  {coursePrefernce["boardDetails"] &&  coursePrefernce["boardDetails"].length ? `,${coursePrefernce["boardDetails"][0]["Name"]}` : ''}
                  {coursePrefernce["subjectDetails"] && coursePrefernce["subjectDetails"].length ? coursePrefernce["subjectDetails"]
                    .map((each: any, index:number) => `${index  >0 ? ',' : ''} ${each.Name}`)
                     : ''}
                </div> 
              ) : (
                ""
              )}
              <div className="profile-strenght-bars-cards">
                {
                                  [...Array(5)].map((e, i) => (
                                    <span className="profile-strenght-bars">
                                 {
                                 ( barStrength > i) ?
                                  <img src={ProfileBarFilled} alt="ProfileBarFilleday-Svg" />  :
                                  <img src={ProfileBarEmpty} alt="ProfileBarEmpty-Svg" />
                                 }   
                                  </span>                  
                                  ))

                }
                {/* <span className="profile-strenght-bars">
                  <img src={ProfileBarFilled} alt="ProfileBarFilleday-Svg" />
                </span>
                <span className="profile-strenght-bars">
                  <img src={ProfileBarFilled} alt="ProfileBarFilleday-Svg" />
                </span>
                <span className="profile-strenght-bars">
                  <img src={ProfileBarFilled} alt="ProfileBarFilleday-Svg" />
                </span>
                <span className="profile-strenght-bars">
                  <img src={ProfileBarFilled} alt="ProfileBarFilleday-Svg" />
                </span>
                <span className="profile-strenght-bars">
                  <img src={ProfileBarFilled} alt="ProfileBarFilleday-Svg" />
                </span>
                <span className="profile-strenght-bars">
                  <img src={ProfileBarEmpty} alt="ProfileBarEmpty-Svg" />
                </span> */}
              </div>

              <div className="user-prf-para">
                Profile Strength: {totalStrength}% Profile Complete
                {/* Profile Strength: 90% Profile Complete */}
              </div>
            </div>
          </div>
        ) : (
          <div className="user-act-header  tw-flex">
            <span className=" tw-mr-5">
              <Skeleton
                borderRadius="50%"
                width="5rem"
                height="5rem"
              ></Skeleton>
            </span>
            <div>
              <Skeleton
                width="8rem"
                height="1.25rem"
                className="mb-2"
              ></Skeleton>
              <Skeleton width="15rem" height="1rem" className="mb-2"></Skeleton>
              <Skeleton width="10rem" height="1rem" className="mb-2"></Skeleton>
              <Skeleton width="12rem" height="1rem" className="mb-2"></Skeleton>
            </div>
          </div>
        )}

        {isEdit ? (
          <EditProfileIndex setIsEdit={setIsEdit} Userdetails={profiledetails[0]} />
        ) : (
          <div>
            {!isLoading ? (
              <div>
                {totalStrength < 100 ?  (
                  <div className="user-profile-card   add-detail-card">
                    <div className="add-detail-head">
                      Complete 100% of your profile by adding your {
                       unFilledProfileDetails && unFilledProfileDetails.length ?  unFilledProfileDetails.map((details:any, index:number)=> `${details} ${index < unFilledProfileDetails.length-1 ? ',': ''}` )
                     :'' }
                     {unFilledProfileDetails && unFilledProfileDetails.length && unFilledCourseDetails && unFilledCourseDetails.length ? ',': ''}
                      {
                       unFilledCourseDetails && unFilledCourseDetails.length ?   unFilledCourseDetails.map((details:any, index:number)=> `${details} ${index < unFilledCourseDetails.length-1 ? ',': ''}` )
                      : ''}
                    </div>
                   {proflieStrength < 75 ?
                   <button
                   className="user-profile-btn"
                   onClick={() => onClickEditDetails()}
                 >
                   Add Details
                 </button> : '' }     
                  </div>
                ) : (
                  ""
                ) }

                <div className="user-profile-card personal-detail-card">
                  <div className="user-profile-card-main-heading tw-flex tw-items-center">
                    Personal Details
                    <span className="tw-ml-8">
                      <img src={TickCircle} alt="TickCircle-Svg" />
                    </span>
                  </div>

                  <div className="user-p-c-para-1">
                    {profiledetails[0]?.FirstName} {profiledetails[0]?.LastName}
                  </div>

                  <div className="tw-flex tw-flex-wrap tw-justify-between tw-mt-5">
                    <div>
                      <div className="user-profile-card-sub-heading">Email</div>
                      <div className="u-p-c-para-2">
                        {profiledetails[0]?.EmailId}
                      </div>
                    </div>
                    <div>
                      <div className="user-profile-card-sub-heading mob-num">
                        Mobile Number
                      </div>
                      <div className="u-p-c-para-2">
                        {profiledetails[0]?.CountryCode}{profiledetails[0]?.PhoneNo}
                      </div>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-justify-between tw-mt-5">
                    <div className="">
                      <div className="user-profile-card-sub-heading">
                        Date of Birth
                      </div>
                      <div className="u-p-c-para-2">{profiledetails[0]?.DOB ? formatDate() : '.........'}</div>
                    </div>
                    <div>
                      <div className="user-profile-card-sub-heading">
                        Gender
                      </div>
                      <div className="u-p-c-para-2">
                        {profiledetails[0]?.Gender ?
                          GENDER[profiledetails[0]?.Gender]
                          : '...........'}
                      </div>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-justify-between tw-mt-5">
                    <div>
                      <div className="user-profile-card-sub-heading mob-num">
                        School Name
                      </div>
                      <div className="u-p-c-para-2">
                        {profiledetails[0]?.School ? profiledetails[0]?.School : '..........'}
                      </div>
                    </div>

                    <div>
                      <div className="user-profile-card-sub-heading mob-num">
                        City
                      </div>
                      <div className="u-p-c-para-2">
                        {profiledetails[0]?.City ? profiledetails[0]?.City : '..........'}
                      </div>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-justify-between tw-mt-5">
                    <div>
                      <div className="user-profile-card-sub-heading mob-num">
                        State
                      </div>
                      <div className="u-p-c-para-2">
                        {profiledetails[0]?.stateName ? profiledetails[0]?.stateName : '..........'}
                      </div>
                    </div>

                    <div>

                    </div>
                  </div>
                  <div className="tw-text-center tw-mt-9">
                    <button
                      className="user-profile-btn"
                      onClick={() => onClickEditDetails()}
                    >
                      Edit Details
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="user-profile-card personal-detail-card">
                <div>
                  <Skeleton
                    width="15rem"
                    height="1.25rem"
                    className="tw-my-3"
                  ></Skeleton>
                  <Skeleton
                    width="15rem"
                    height="1.25rem"
                    className="tw-my-3"
                  ></Skeleton>
                </div>
                <div className="tw-flex tw-flex-wrap tw-justify-between tw-mt-5">
                  <div>
                    <Skeleton
                      width="15rem"
                      height="1.25rem"
                      className="tw-my-3"
                    ></Skeleton>
                    <Skeleton
                      width="15rem"
                      height="1.25rem"
                      className="tw-my-3"
                    ></Skeleton>
                  </div>
                  <div>
                    <Skeleton
                      width="15rem"
                      height="1.25rem"
                      className="tw-my-3"
                    ></Skeleton>
                    <Skeleton
                      width="15rem"
                      height="1.25rem"
                      className="tw-my-3"
                    ></Skeleton>
                  </div>

                  <div className="tw-mt-5">
                    <Skeleton
                      width="15rem"
                      height="1.25rem"
                      className="tw-my-3"
                    ></Skeleton>
                    <Skeleton
                      width="15rem"
                      height="1.25rem"
                      className="tw-my-3"
                    ></Skeleton>
                  </div>
                </div>

                <div className="tw-text-center tw-mt-9 tw-mb-2">
                  <Skeleton
                    width="10rem"
                    height="3rem"
                    className="tw-mx-auto"
                  ></Skeleton>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};