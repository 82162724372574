import { Navigate } from "react-router-dom";
import { useAppSelector } from "@Src/app/hooks";

interface Props {
    component: React.ComponentType
    path?: string
  }


  export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
    const isLoggedIn = useAppSelector((state) => state?.login?.isLoggedIn);
  if (isLoggedIn) {
    return <RouteComponent />
  }
  return <Navigate to="/" />
}

export const UnAuthorisedRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const isLoggedIn = useAppSelector((state) => state?.login?.isLoggedIn);
if (isLoggedIn) {
}else{
  return <RouteComponent />
}
return <Navigate to="/" />
}
