import React from 'react'
import { Routes, Route } from 'react-router-dom';
import './App.scss';
import { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import Loader from '@Components/loader/Loader';
import Layout from './features/layout';
import { useAppSelector } from './app/hooks';


const App: React.FC = () => {
	const isSpinnerVisible = useAppSelector(state => state.global);
	return (
		<div>
			<Routes>
				<Route path='/*' element={<Layout/>}/>
			</Routes>
			{isSpinnerVisible.loading ? <Loader /> : null}
			<div>
				<div>
					<Toaster
						position="top-center"
						reverseOrder={false}
					/>
				</div>
			</div>
		</div >
	);
}

export default App;
