import { Dialog } from "primereact/dialog"

export const EndTestDialog = ({endTestDialog,setEndTestDialogHandler, endTestHandler, resumeTestHandler}:any) => {
    return (
        <>
              <Dialog className="mock-test-dialog-box" position="top" visible={endTestDialog} onHide={() => setEndTestDialogHandler(false)} closeOnEscape={false} >
        <div className="pause-dialog-box">
          <div className="p-dlg-heading">End Test?</div>
          <span className="p-dilg-para">Do you really want to end the test?</span>

          <div className="tw-mt-7 tw-flex">
            <button className="dialog-end-test-btn"
              onClick={() => endTestHandler()} > End Test</button>
            <button className="resume-test-btn" onClick={() => resumeTestHandler()}>Resume Test</button>
          </div>
        </div>
      </Dialog>
      </>
    )
}