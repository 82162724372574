import React, { useEffect, useState } from "react";
import "./Dashboardpage.scss";
import ClockSvg from "@Assets/svg/Dashboard-svgs/clock.svg";
import NoMocktestSvg from "@Assets/svg/Dashboard-svgs/NoMocktest.svg";
import { CardDashboardIndex, SkeletonCardDashboardIndex } from "./Components/Cards";
import { getDashboardTestSeriesDetails, getDashboarddetails } from "../Service/Dashboard.service";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { error } from "console";
import { useAppSelector } from "@Src/app/hooks";



export const DashboardpageIndex = () => {
  const [toggletabs, settoggletabs] = useState(1);
  const [dashdetails, setdashdetails] = useState<any>([]);
  const [dashTestdetails, setdashTestdetails] = useState<any>([]);
  const [dashTestSeriesdetails, setdashTestSeriesdetails] = useState<any>([]);
  const FirstName = useAppSelector(state => state.login.Firstname);
  const LastName = useAppSelector(state => state.login.Lastname);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const dashTestdetails$ = new BehaviorSubject(dashTestdetails),
  //  dashTestSeriesdetails$ = new BehaviorSubject(dashTestSeriesdetails),
  // dashdetails$ = combineLatest([dashTestdetails$, dashTestSeriesdetails$]).pipe(
  //   map(() => {
  //     let appendedData = [...dashTestdetails, ...dashTestSeriesdetails]
  //     return appendedData
  //   }
  //   )
  // );

  const togglebutton = (tabs: any) => {
    settoggletabs(tabs);
  };

  useEffect(() => {
    dashboarddetails()
  }, [])

  const dashboarddetails = () => {
    setIsLoading(true)
    getDashboarddetails().then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        // setdashTestdetails(response.data)
        dashboardTestSeriesDetails(response.data)
        setIsLoading(false)

      }
    }).catch((error: any) => {
      console.log(error)
    })

  }

  const dashboardTestSeriesDetails = (testDetails: any) => {
    setIsLoading(true)
    getDashboardTestSeriesDetails().then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        // setdashTestSeriesdetails(response.data);
        let appendedData = [...testDetails, ...response.data]
        setdashdetails(appendedData)
        setIsLoading(false)
      }
    }).catch((error: any) => {
      setIsLoading(false)
      console.log(error)
    })

  }

  return (
    <>
      <div className="dashboard-page-container ">
        <div className="dash-header">
          <div className="dash-heading">Welcome, {FirstName} {LastName}</div>
          <div className="dash-para">Have a good day!</div>
        </div>

       {dashdetails? <div className="dash-content-card">
          <div className="dashboard-tabs">
            <ul className="dash-tabs-ul">
              <li
                className={`dash-tabs-li ${toggletabs === 1 ? 'active-tab' : ''}`}
                onClick={() => togglebutton(1)}
              >
                Tests Completed
              </li>
              <li
                className={`dash-tabs-li ${toggletabs === 0 ? 'active-tab' : ''}`}
                onClick={() => togglebutton(0)}
              >
                Tests Pending
              </li>
            </ul>
          </div>
          {!isLoading ?
            dashdetails && dashdetails.length ?
              <CardDashboardIndex toggletabs={toggletabs} dashdetails={dashdetails} />
              :         <div className="no-data-section">
              <img src={NoMocktestSvg} alt="NoMocktestSvg" />
              <div className="n-d-head">
                No <span className="n-d-active-span">Mock Tests</span>  added yet! <br/>
                Any new tests will appear here
              </div>
            </div>
    
            :
            <SkeletonCardDashboardIndex />}

        </div> :''

        }
      </div>







    </>
  );
};
