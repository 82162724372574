import "../UserProfile.scss";
import { getCouresePreferences } from "../Services/UserProfile.sevice";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { useEffect, useState } from "react";
import PersonalCheckSvg from "@Assets/svg/Myprofile/Profilechecked.svg"
import { EditCoursesIndex } from "../EditCourses";
import TickCircle from "@Assets/svg/UserProfilesvg/TickCircle.svg";
import { Skeleton } from "primereact/skeleton";

export const CoursePreferenceIndex = () => {
  const [courseprefernces, setcourseprefernces] = useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);



  useEffect(() => {
    if(!isEdit){
    getcourseprefer()
    }
  }, [isEdit])

  const getcourseprefer = () => {
    setIsLoading(true)
    getCouresePreferences().then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        setcourseprefernces(response?.data);
        setIsLoading(false)
      }
    }).catch((error: any) => {
      console.log(error)
    })
  }

  const subjectdatamap: any = courseprefernces?.subjectDetails
  return (
    <>
      <div className="user-profile-right-container ">
        <div className="user-act-header">

          <div className="usr-prf-heading">Course Preferences</div>

        </div>
        {isEdit ? 
       <EditCoursesIndex setIsEdit={setIsEdit} courseprefernces={courseprefernces} /> : <div>


         {!isLoading? <div className="user-profile-card personal-detail-card">
            <div className="user-profile-card-main-heading tw-flex tw-items-center">Academic Details
              <span className="tw-ml-8">
              <img src={PersonalCheckSvg} alt="profiel-svg" />
              </span>
            </div>

            <div className="tw-flex tw-mt-5">
              <div className="tw-w-1/2">
                <div className="user-profile-card-sub-heading">Class</div>
                {courseprefernces?.classDetails &&  <div className="u-p-c-para-2">{courseprefernces?.classDetails[0]?.Name}</div>
}
              </div>
              <div className="tw-w-1/2">
                <div className="user-profile-card-sub-heading">Board</div>
              {courseprefernces?.boardDetails &&  <div className="u-p-c-para-2">{courseprefernces?.boardDetails[0]?.Name}</div>}
              </div>
            </div>

            <div className="tw-flex  tw-mt-5">
            {courseprefernces?.educationInterestDetails && courseprefernces?.educationInterestDetails[0]?.Name?  <div className="tw-w-1/2">
                <div className="user-profile-card-sub-heading">Education Interest</div>
                <div className="u-p-c-para-2">{courseprefernces?.educationInterestDetails[0]?.Name}</div>
              </div> : null }
           
              <div className="tw-w-1/2">
                <div className="user-profile-card-sub-heading">Subject</div>

                <div className="u-p-c-para-2">
                  {subjectdatamap?.map((each: any) => each.Name).toString()}
                </div>
              </div>
            </div>
            <div className="tw-text-center tw-mt-9">
              <button className="user-profile-btn" onClick={() => setIsEdit(true) }>Edit Details</button>
            </div>
          </div> : <div className="user-profile-card personal-detail-card">
              <div >
                <Skeleton width="15rem" height="1.25rem" className="tw-my-3" ></Skeleton>
              </div>
              <div className="tw-flex tw-flex-wrap tw-justify-between tw-mt-5">
                <div>
                  <Skeleton width="15rem" height="1.25rem" className="tw-my-3" ></Skeleton>
                  <Skeleton width="15rem" height="1.25rem" className="tw-my-3"></Skeleton>
                </div>
                <div>
                  <Skeleton width="15rem" height="1.25rem" className="tw-my-3" ></Skeleton>
                  <Skeleton width="15rem" height="1.25rem" className="tw-my-3"></Skeleton>
                </div>

                <div className="tw-mt-5">
                  <Skeleton width="15rem" height="1.25rem" className="tw-my-3" ></Skeleton>
                  <Skeleton width="15rem" height="1.25rem" className="tw-my-3"></Skeleton>
                </div>
                <div className="tw-mt-5">
                  <Skeleton width="15rem" height="1.25rem" className="tw-my-3" ></Skeleton>
                  <Skeleton width="15rem" height="1.25rem" className="tw-my-3"></Skeleton>
                </div>
              </div>

              <div className="tw-text-center tw-mt-9 tw-mb-2">
                <Skeleton width="10rem" height="3rem" className="tw-mx-auto"></Skeleton>
              </div>
            </div>}
        </div>}


      </div>
    </>
  );
};