import { MathJax } from "better-react-mathjax";
import CorrectSvg from "@Assets/svg/Dashboard-svgs/correct.svg";
import WroungSvg from "@Assets/svg/Dashboard-svgs/Wroung.svg";
import { ReviewResultType } from "@Constants/app.constant";
import TimerSvg from "@Assets/svg/MockTest-svgs/Timer.svg"
import { ResultCard } from "../ResultCard/ResultCard";
import React from "react";

 const ReviewResult = ({ Reviewdata, onClickShowExplanation, showDataReviewType }: any) => {
    return (
        <>
            {Reviewdata.map((review: any, index: any) => {
                let checkNullExplaination = review.Explaination && review.Explaination.trim().length && review.Explaination.toLowerCase() != 'null'
                return (
                    <div className="question-review-card" key={index} style={{ display: (showDataReviewType == ReviewResultType.CORRECT_ANSWERS && review.AnsType == 1) ||  (showDataReviewType == ReviewResultType.INCORRECT_ANSWERS && review.AnsType == 0) || (showDataReviewType == ReviewResultType.ALL_ANSWERS) ? 'block': 'none'}} >
                        <div className="rev-qust-no-head">Question {index > 8 ? index + 1 : `0${index + 1}`}/{Reviewdata.length > 9 ? Reviewdata.length : `0${Reviewdata.length}`} <span className="pipe-span"><svg width="2" height="18" viewBox="0 0 2 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.78409 0.536932V17.2812H0.789773V0.536932H1.78409Z" fill="#667085" />
                        </svg>
                        </span>
                            <span className="quest-time-span">  <img src={TimerSvg} className="timer-img" alt="timer-svg" />
                                {review.Time > 0 ?
                                    review.TimeToShow
                                    : '--:--'}
                            </span>
                        </div>
                        <div className="qust-subject-card">
                            <div className="review-qust"  >
                                {/* <MathJax dangerouslySetInnerHTML={{ __html: review.Question }} >
                                </MathJax> */}
                                                                <div dangerouslySetInnerHTML={{ __html: review.Question }} >
                                </div>

                            </div>
                            <div className="level-subj-sec">
                                <span className="subject-level-span">Level {review.DifficultyLevel}</span>
                                <span className="subject-span">{review.SubjectName}</span>
                            </div>

                        </div>
                        <div className="reviw-opt-card">
                            {Object.entries(review.Options).map((opt: any) => {
                            //    let rightOption = (opt[0].toLowerCase() == review.Ans.toLowerCase());
                                let rightOptions = review.Ans ? review.Ans.trim().toLowerCase().split(',') : [];
                                let options = review.SelectedOption ? review.SelectedOption.trim().toLowerCase().split(',') : [];
                                let missedOption = [];
                                if(review.IsMultiple){
                                                                        // find missed option 
                                // if( ( rightOptions && rightOptions.length ) == ( options && options.length )  ){
                                    // if any selected option is correct from multiple answer right options, we can find misssed option
                                    let matchedOption = rightOptions.filter((item:any) => { 
                                        return options.indexOf(item) > -1 
                                      })   
                                      if(matchedOption && matchedOption.length){ 
                                  missedOption =  rightOptions.filter((item:any) => { 
                                        return options.indexOf(item) === -1 
                                      })    
                                   }   
                              //  }
                            }
                                return (
                                    <>
                                        {opt[1] && showDataReviewType != ReviewResultType.ALL_ANSWERS && ( (review.Ans.trim().toLowerCase().includes(opt[0].toLowerCase()) ) || (review.SelectedOption && (review.SelectedOption.includes(opt[0]))) ) ?
                                            <ResultCard review={review} rightOptions={rightOptions} opt={opt} options={options} missedOption={missedOption} />
                                            :
                                            opt[1] && showDataReviewType == ReviewResultType.ALL_ANSWERS ?
                                            <ResultCard review={review} rightOptions={rightOptions} opt={opt} options={options} missedOption={missedOption} />
                                                : ""
                                        }
                                    </>
                                )
                            })}

                        </div>
                        {review.AnsType === 1 && <div className="tw-mt-3">
                            <span className="answer-status status-correct ">
                                Your answer is correct.
                            </span>
                        </div>}

                        {review.AnsType === 0 && <div className="tw-mt-3">
                            <span className="answer-status status-incorrect">
                                Your answer is incorrect.
                            </span>
                        </div>}

                        {review.AnsType === 2 && <div className="tw-mt-3 status-skipped">
                            <span className="answer-status ">
                                You skipped  this question.
                            </span>
                        </div>}
                        {checkNullExplaination ?
                            <>
                                <div className="q-review-card">
                                    {review.selected ?
                                        <span className="button-links" onClick={() => { onClickShowExplanation(index, review.selected) }} >Hide Explanation</span>
                                        :
                                        <span className="button-links" onClick={() => { onClickShowExplanation(index, review.selected) }} >Show Explanation</span>
                                    }
                                </div>
                                    <div className={`q-review-card-text ${review.selected ? 'visible' : 'hidden' }` } >
                                                                         {/* <div className={`q-review-card-text`} > */}
                                        {/* <MathJax dangerouslySetInnerHTML={{ __html: review.Explaination }} dynamic={true} >
                                        </MathJax> */}
                                        <div dangerouslySetInnerHTML={{ __html: review.Explaination }}  >
                                        </div>
                                    </div>
                                    : ''
                                
                            </>
                            : ''
                        }
                    </div>
                )

            })}
        </>
    )
}

export default React.memo(ReviewResult)