import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
// import storageSession from 'redux-persist/es/storage/session'
import globalReducer from '@App/reducers/globalSlice'
import thunk from 'redux-thunk'
import headerSlice from '../reducers/header/headerSlice';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import mocktestSlice from '../reducers/header/mocktestSlice';
import registerSlice from '../reducers/auth/registerSlice';
import loginSlice from '../reducers/auth/loginSlice';
import verifyEmailSlice from '../reducers/auth/verifyEmailSlice';
const persistConfig: any = {
    key: 'root',
    version: 1,
    storage
}
/**
 * Need to use combine reducers when we can create multiple reducers
 */
const reducers = combineReducers({
    global: globalReducer,
    header:headerSlice,
    questions: mocktestSlice, 
    register : registerSlice,
    login : loginSlice,
    verifyEmail : verifyEmailSlice
});

/**
 * persisted reducer helps persisting state by storing our state in sessionStorage
 */
const persistedReducer = persistReducer(persistConfig, reducers)


export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})


export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;