
import { TestTypes } from "@Constants/app.constant";
import InstructionSvg from "@Assets/svg/Dashboard-svgs/instruction.svg";
import  "../Test-question-card/Test-question-card.scss";

export const QuestionpaperrightFooter = ({testType,fullQuestionPaperMode, instruction, setFullQuestionPaperModeHandler, setinstructionHadndler,onClickEndTestHandler,}:any) => {
const onClickQuestionPalette=()=>{
  setinstructionHadndler(false);
  setFullQuestionPaperModeHandler(false);
  if(fullQuestionPaperMode == true){
    setFullQuestionPaperModeHandler(false);
  }else if(fullQuestionPaperMode == false){
    setFullQuestionPaperModeHandler(true);
  }
}
const onClickInstruction = ()=>{
  if(instruction == true){
    setinstructionHadndler(false);
    setFullQuestionPaperModeHandler(false);
  }else if(instruction == false){
    setinstructionHadndler(true);
    setFullQuestionPaperModeHandler(false);
  }
}
    return (
        <>

<div className="footer-right-card">
          <div className="qust-inst-card">
          {testType==TestTypes.regular ?<a
              href="javascript:void(0)"
              className={`qust-a-btn  ${fullQuestionPaperMode ? 'active-btn' : null}`}
              onClick={() => {
                onClickQuestionPalette()
              }
              }
            >
               {fullQuestionPaperMode ? 'Question Palette' : 'Question Paper'}
            </a> : ''}  
            <a
              href="javascript:void(0)"
              className={`qust-inst-btn speed-test-inst  ${instruction ? 'active-btn' : null}`}
              onClick={() => {
                onClickInstruction()
              }}
            >
              <img src={InstructionSvg} alt="rightarrowSvg" /> Instructions
            </a>
          </div>

          <div className="tw-w-full" onClick={() => onClickEndTestHandler()} >
            <a href="javascript:void(0)" className="end-test-btn">
              End Test
            </a>
          </div>
        </div>
        
        </>
    )
}