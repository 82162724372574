import { useState } from "react";
import "./Testtype.scss";
import ObjectiveSvg from "@Assets/svg/Dashboard-svgs/rightarrow.svg"
import ActiveRightArrowSvg from "@Assets/svg/Dashboard-svgs/ActiveRightArrow.svg"
import ActiveProgressbarSvg from "@Assets/svg/Dashboard-svgs/ActiveProgressivebar.svg";
import ProgressbarSvg from "@Assets/svg/Dashboard-svgs/progressiveBar.svg";
import { useNavigate, useParams } from "react-router-dom";
import { AppRouteURL } from "@Constants/app-route-url.constant";
import { toast } from "react-hot-toast";

export const TestType = ({testTypeHandler}:any) => {
  const params = useParams();
  const navigation = useNavigate();
  const enum testType {
    Regular =1,
    Speed
  }
  const testId = params.testId;
  const [testtype, setTesttype] = useState(-1);

//   const onContinueClick = () => {
//     if(testtype == testType.Regular){
//       navigation(`${AppRouteURL.INSTRUCTIONS}/${testId}`)
//     }else if(testtype == testType.Speed){
//       selectionScreenHandler(2)
//     }else{
//       toast.error("Please select test type")
//     }
// }

const setSelection = (option:number) =>{
  setTesttype(option);
  testTypeHandler(option)
}

  return (
    <>
        <div className="test-content-card">
          <div className="test-cnt-heading">Please Select Test Type</div>
          <div className="type-cards-container">
            <div
              className={`type-card  ${
                testtype == testType.Regular ? "active-type-card" : ""
              }`}
              onClick={() => setSelection(testType.Regular)}
            >
              <div className="type-card-head">Regular Test</div>
              <span className="type-svg">
               <img className="right-arrow" src={ObjectiveSvg} alt="" />
               <img className="active-right-arrow" src={ ActiveRightArrowSvg} alt="" />
              </span>
            </div>
            <div
              className={`type-card  ${
                testtype == testType.Speed ? "active-type-card" : ""
              }`}
              onClick={() => setSelection(testType.Speed)}
            >
              <div className="type-card-head">Speed Test</div>
              <span className="type-svg">
               <img className="right-arrow" src={ObjectiveSvg} alt="" />
               <img className="active-right-arrow" src={ ActiveRightArrowSvg} alt="" />   
              </span>
            </div>
          </div>  
          </div>
          {/* <button className="test-cont-btn" onClick={()=>{onContinueClick()}} >
                Continue
            </button>       */}
    </>
  );
};
