import './PopularTestSeries.scss';
import LeftArrow from "@Assets/svg/Home_svgs/LeftArrow.svg";
import Dots from "@Assets/svg/Home_svgs/Dots.svg";
import FlattedDots from "@Assets/svg/Home_svgs/FlattedDots.svg";
import RightArrow from "@Assets/svg/Home_svgs/RightArrow.svg";
import { getPopulartestList } from '../../services/SubjectDetails.service';
import { APIResponse, APIStatus } from '@Src/services/baseInterfaces';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DetailsRouteURL } from '@Constants/app-route-url.constant';
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';

export const PopularTestSeries = () => {
    const [populartest, setpopularlist] = useState([])
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event:any) => {
        setFirst(event.first);
        setRows(event.rows);
    };
    useEffect(() => {
        populatlistdata()
    }, [])

    const populatlistdata = () => {
        getPopulartestList().then((response: APIResponse) => {
            if (response.ResponseType === APIStatus.SUCCESS) {
                setpopularlist(response?.data)

            }
        }).catch((error: any) => {
            console.log(error)
        })
    }

    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink',
        PrevPageLink: (options: any) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3"> <img src={LeftArrow} />
                    </span>
                </button>
            );
        },
        NextPageLink: (options: any) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3"> <img src={RightArrow} />
                    </span>
                </button>
            );
        },
        PageLinks: (options: any) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    <img src={Dots} />
                </button>
            );
        }
    };

    return (<>
        {populartest && populartest.length ? <section className="most-popular-series-container">
            <div className="mock-test-listing-header">
                Most Popular Test
            </div>
            <div className="most-popular-cards-container">
                {populartest.slice(first, first+ rows).map((element: any) => {
                    return <div key={element.Id} className="m-p-card">
                        <div className="subject-card">
                            <img src={element.Image} className='subject-img' />
                        </div>

                        <div className="stnd-cls-card-btn">
                        <div className="populartest-title-card">{element.Name}</div>
                            <Link to={`${DetailsRouteURL.TEST_DETAILS}/${element.Id}`} >
                                <button className="take-test-btn">View Details</button>
                            </Link>
                        </div>
                    </div>
                })}
            </div>
            {/* <div className="testinomal-pagenation">
             {populartest && populartest.length > 4 ? <Paginator template={template1} first={first} rows={rows} totalRecords={populartest.length} onPageChange={(e) => onPageChange(e)} /> : ''}   
                <span>
                    <img src={LeftArrow} />
                </span>
                <span>
                    <img src={FlattedDots} />
                </span>
                <span>
                    <img src={Dots} />
                </span>
                <span>
                    <img src={Dots} />
                </span>
                <span>
                    <img src={Dots} />
                </span>
                <span>
                    <img src={RightArrow} />
                </span>
            </div> */}
        </section> : ''}
    </>
    )
}