import './DetailsPageBanner.scss'
import Breadcrumb from './components/Breadcrumb';

const DetailsPageBanner = ({ Headings }: any) => {
    return (
        <section className="mock-test-listining-container">
            <div>
                <Breadcrumb Headings={Headings} />
                <div className="m-t-l-header">
                    <span className="m-t-l-head-span">MockPrep Tests</span> For <span className="m-t-l-head-span">Students</span><br />  Of <span className="m-t-l-head-span"> All Standards</span>
                </div>
            </div>
            <div className="m-t-l-img-card">
                <img src="https://i.postimg.cc/wBVjy19v/Group-47503.png" />
            </div>
        </section>);
};

export default DetailsPageBanner;
