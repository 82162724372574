
import React, { useEffect, useState } from "react";
import HomeLayout from "../layout/components/HomeLayout/HomeLayout";
import { BannerIndex } from "./components/Banner";
import { getBannnerimages } from "./services/Home.service";
import { APIResponse } from "@Src/services/baseInterfaces";
import { APIStatus } from "@Src/services/baseInterfaces";
import "./Home.scss";
import { useAppSelector } from "@Src/app/hooks";
import { ChooseOurPlatform } from "./components/ChooseOurPlatform/ChooseOurPlatform";
import { SubjectList } from "./components/SubjectList/SubjectList";
import { Testimonials } from "./components/Testimonials/Testimonials";
import { DetailsRouteURL } from "@Constants/app-route-url.constant";
import { MobileFilter } from "../MobFilter/Mob-Filter";
export const HomeIndex = () => {
    const [bannerdata, setbannerdata] = useState<Array<any>>([])
    const subjectStore = useAppSelector(state => state.header.subject);
    useEffect(() => {
        window.scrollTo(0, 0)
        getbannerdata();
    }, [])

    const getbannerdata = () => {
        getBannnerimages().then((response: APIResponse) => {
            if (response.ResponseType === APIStatus.SUCCESS) {
                setbannerdata(response?.data)
            }
        }).catch((error: any) => {
            console.log(error)
        })
    }


    return (
        <>
            <HomeLayout >
            
                <div >
              
                    <BannerIndex bannerdata={bannerdata} />
                    <ChooseOurPlatform />
                    <SubjectList pageName={DetailsRouteURL.SUBJECT_DETAILS} subjectList={subjectStore} />
                    <Testimonials /> 
                    {/* <div className="mob-filter-sect">
                    <MobileFilter />
                   
                    </div>
                                   
                    <div className="mob-filter-section">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 1H1L6.2 7.149V11.4L8.8 12.7V7.149L14 1Z" stroke="#7F56D9" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Filter
                    </div> */}
                  
                </div>
            </HomeLayout>

        </>
    );
};
