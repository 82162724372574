import { createSlice } from "@reduxjs/toolkit"; 

const initialState = {
    class: [],
    subject: [],
    testSeries: []
}

const headerSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setClassList: (state,action) => {
             state.class = action.payload;
        },
        setSubjectList: (state,action) => {
            state.subject = action.payload;
        },
        setTestSeriesList: (state,action) => {
            state.testSeries = action.payload;
        } 
    }
});

export default headerSlice.reducer;
export const { setClassList, setSubjectList, setTestSeriesList } = headerSlice.actions;