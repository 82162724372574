import { Link } from 'react-router-dom';
import './DropdownHover.scss';
import DownArrowDark from "@Assets/svg/Home_svgs/DownArrowDark.svg";
import { DetailsRouteURL, TestSereisRouteURL } from '@Constants/app-route-url.constant';
import { useState } from 'react';

export const DropdownHover = ({ Heading, list, pageName }: any) => {
   const [dropdown , Setdropdown] = useState(false)

   return (
      <ul className="login-drop-down-prof-dash">
         <li className="dropdown-ui-1" ><a href="javascript:void(0)" onClick={() => Setdropdown(true)}>{Heading}   <span className="down-arrow-svg">
            <img src={DownArrowDark} />
         </span></a>
         { dropdown &&  <ul className="dropdown-ui-container">
               {
                  pageName == TestSereisRouteURL.TEST_SERIES ?
                     <Link to={`${pageName}/all`}>
                        <li className="user-dropdown-li" id={'all'} key={'all'} ><a href="javascript:void(0)">
                           See All
                        </a>
                        </li>
                     </Link>
                     : ''

               }
               {
                  list.map((element: any) => {
                     let name = element.Name;
                     if(pageName == TestSereisRouteURL.TEST_SERIES){}else{
                     }
                     return (
                        <Link onClick={() => Setdropdown(false)} to={`${pageName}/${name}/${element.Id}` } >
                           <li  className="user-dropdown-li" id={element.Id} key={element.Id} ><a href="javascript:void(0)" >
                              {element.Name}
                           </a>
                           </li>
                        </Link>
                     )
                  })
               }
            </ul >}
         </li>
      </ul>
   )
}