
import BackArrowSvg from "@Assets/svg/Dashboard-svgs/BackArrow.svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getLeaderBoardTestSeries, getTestResult, getTestSeriesResult, getUserTestReview, getUserTestSeriesReview } from "../Service/Dashboard.service";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { useEffect, useState } from "react";
import { getLeaderBoard } from "../Service/Dashboard.service";
import "./TestResult.scss";
import { AppRouteURL } from "@Constants/app-route-url.constant";
import { MathJax } from "better-react-mathjax";
import { LeaderboardResult } from "./components/LeaderboardResult/LeaderboardResult";
import  ReviewResult  from "./components/ReviewResult/ReviewResult";
import { ReviewResultType } from "@Constants/app.constant";
import { Skeleton } from "primereact/skeleton";



export const TestResultIndex = () => {
    const navigation = useNavigate()
    const [resultdata, setresultdata] = useState<any>([])
    const [Reviewdata, setReviewdata] = useState<any>([])
    const [allAnswersData, setAllAnswersData] = useState([])
    const [correctAnswersData, setCorrectAnswersData] = useState([])
    const [incorrectAnswersData, setIncorrectAnswersData] = useState([])
    const [TimeValue, setTimeValue] = useState('')
    const [LeaderDetails, setLeaderDetails] = useState([]);
    const [showDataReviewType, setShowDataReviewType] = useState(ReviewResultType.ALL_ANSWERS);
    const params = useParams()
    const userValueId = Number(params.TestResultsId)
    const [searchParams] = useSearchParams();
    const id = Number(searchParams.get('userTestId'));
    const testType = Number(searchParams.get('testType'));
    const testId = Number(searchParams.get('testId'));
    const examId = Number(searchParams.get('examId'));
    const [activetab, setActivetab] = useState<any>(ReviewResultType.ALL_ANSWERS)
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (examId) {
            getTestSeriesResultDetails()
            getleaderTestSeriesdata()
            getTestSeriesReview();
        } else {
            getResultDetails()
            getleaderdata()
            gettestreview()
        }
    }, [testType])

    // useEffect(() => {
    //     if (examId) {
    //         getTestSeriesReview();
    //     } else {
    //         gettestreview()
    //     }
    // }, [id])

    /*--------------------------------result-data-from-api-------------------*/
    const getResultDetails = () => {
        if (testId) {
            let params = {
                'testId': testId,
                'testType': testType,
                'id': id,
            }
            getTestResult(params).then((response: APIResponse) => {
                if (response.ResponseType === APIStatus.SUCCESS) {
                    setresultdata(response?.data)
                    let time = getDuration(response?.data[0]?.['UsedDuration']);
                    setTimeValue(time)
                }
            }).catch((error: any) => {
                console.log(error)
            })
        }
    }

    const getTestSeriesResultDetails = () => {
        if (examId) {
            let params = {
                'examId': examId,
                'testType': testType,
                'id': id
            }
            getTestSeriesResult(params).then((response: APIResponse) => {
                if (response.ResponseType === APIStatus.SUCCESS) {
                    setresultdata(response?.data)
                    let time = getDuration(response?.data[0]?.['UsedDuration']);
                    setTimeValue(time)
                }
            }).catch((error: any) => {
                console.log(error)
            })
        }
    }

    /*----------------------review-data-from-api----------------------*/
    const gettestreview = () => {
        if (id) {
            let params = {
                'userTestId': id
            }
            setIsLoading(true);
            getUserTestReview(params).then((response: APIResponse) => {
                setIsLoading(false);
                if (response.ResponseType === APIStatus.SUCCESS) {
                    setModifiedReviewData(response.data)
                }
            }).catch((error: any) => {
                setIsLoading(false);
                console.log(error)
            })
        }

    }

    const getTestSeriesReview = () => {
        if (id) {
            let params = {
                'userTestId': id
            }
            setIsLoading(true);
            getUserTestSeriesReview(params).then((response: APIResponse) => {
                setIsLoading(false);
                if (response.ResponseType === APIStatus.SUCCESS) {
                    setReviewdata(response.data);
                    setAllAnswersData(response.data)            
                    setModifiedReviewData(response.data)
                }
            }).catch((error: any) => {
                setIsLoading(false);
                console.log(error)
            })
        }

    }

    const setModifiedReviewData = (reviews: any) => {
        let modifiedReviews: any = [];
        if (reviews && reviews.length) {
            reviews.forEach((review: any) => {
                let modifiedReview = {
                    ...review,
                    'selected': false,
                    TimeToShow: getDuration(review.Time)
                }
                modifiedReviews.push(modifiedReview);
            });
        }
        setReviewdata(modifiedReviews);
        setAllAnswersData(modifiedReviews)
        divideReviewData(modifiedReviews);
    }

    const divideReviewData = (reviews: any) => {
        let correctAnswers: any = [];
        let inCorrectAnswers: any = [];
        reviews.forEach((review: any) => {
            if (review.AnsType == 0) {
                inCorrectAnswers.push(review);
            } else if (review.AnsType == 1) {
                correctAnswers.push(review);
            }
        });
        setCorrectAnswersData(correctAnswers);
        setIncorrectAnswersData(inCorrectAnswers);
    }
    /*------------------------------Leader-board-data-------------------*/

    const getleaderdata = () => {
        let params = {
            'testId': testId,
            'testType': testType,
            'id': id,
        }
        if (testId) {
            getLeaderBoard(params).then((response: APIResponse) => {
                if (response.ResponseType === APIStatus.SUCCESS) {
                    setLeaderDetails(response.data)
                }
            }).catch((error: any) => {
                console.log(error)
            })
        }

    }

    const getleaderTestSeriesdata = () => {
        let params = {
            'examId': examId,
            'testType': testType,
            'id': id,
        }
        if (examId) {
            getLeaderBoardTestSeries(params).then((response: APIResponse) => {
                if (response.ResponseType === APIStatus.SUCCESS) {
                    setLeaderDetails(response.data)
                }
            }).catch((error: any) => {
                console.log(error)
            })
        }

    }

    const getDuration = (durationdata: any) => {
        let hours: any = Math.floor(durationdata / 3600);
        let minutes: any = Math.floor((durationdata - (hours * 3600)) / 60);
        let seconds: any = durationdata - (hours * 3600) - (minutes * 60);

        if (hours > 0) {
            hours = (hours < 10) ? "0" + hours : hours;
        } else {
            hours = '';
        }
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        let time = hours + (hours ? ":" : '') + minutes + ":" + seconds
        return time;
    }

    const onClickShowExplanation = (index: number, selected: boolean) => {
        // const previousReviews: any = [...Reviewdata];
        // let previousReview = previousReviews[index];
        // previousReview['selected'] = !selected;
          // 2. Mark the todo as complete
  const updatedReviewExplanationStatus = {...Reviewdata[index], selected: !selected};
  // 3. Update the todo list with the updated todo
  const updatedReviews = [...Reviewdata];
  updatedReviews[index] = updatedReviewExplanationStatus;
  setReviewdata(updatedReviews);
       // setReviewdata(previousReviews);

    }

    const onClickReviewDataType = (type: string) => {
        setActivetab(type)
        switch (type) {
            case ReviewResultType.ALL_ANSWERS:
                //   setReviewdata(allAnswersData);
                setShowDataReviewType(type);
                break;
            case ReviewResultType.CORRECT_ANSWERS:
                //  setReviewdata(correctAnswersData);
                setShowDataReviewType(type);
                break;
            case ReviewResultType.INCORRECT_ANSWERS:
                //   setReviewdata(incorrectAnswersData);
                setShowDataReviewType(type);
                break;
        }
    }
    return (
        <>

            <div className="leader-board-container ">
                <div className="leader-header" >
                    <span className="back-btn-span" onClick={() => navigation(AppRouteURL.DASHBOARD + AppRouteURL.DASHBOARD_HOME)}>
                        <img src={BackArrowSvg} alt="back-svg" />
                    </span>
                    Test Result</div>
                <LeaderboardResult testType={testType} resultdata={resultdata} LeaderDetails={LeaderDetails} userValueId={userValueId} examId={examId} testId={testId} TimeValue={TimeValue} />
                <div className="review-mock-test-container">
                    <div className="review-mock-test-tabs">
                        <div className={`r-m-t-heading  ${(activetab === ReviewResultType.ALL_ANSWERS) ? 'active-tab' : ''}`} onClick={() => onClickReviewDataType(ReviewResultType.ALL_ANSWERS)} >Review Mock Test</div>
                        {correctAnswersData && correctAnswersData.length ?
                            <div className={`r-m-t-heading  ${(activetab === ReviewResultType.CORRECT_ANSWERS) ? 'active-tab' : ''}`} onClick={() => onClickReviewDataType(ReviewResultType.CORRECT_ANSWERS)} >Correct Answers</div>
                            : ''}
                        {incorrectAnswersData && incorrectAnswersData.length ?
                            <div className={`r-m-t-heading  ${(activetab === ReviewResultType.INCORRECT_ANSWERS) ? 'active-tab' : ''}`} onClick={() => onClickReviewDataType(ReviewResultType.INCORRECT_ANSWERS)} >Incorrect Answers</div>
                            : ''}
                    </div>

                    {isLoading ?
                        <SkeletonCardResultIndex />
                        :
                        // winding up whole part inside mathjax component instead of small separate components to process mathjax formulas fast
                        <MathJax>
                        <ReviewResult Reviewdata={Reviewdata} onClickShowExplanation={onClickShowExplanation} showDataReviewType={showDataReviewType} />
                        </MathJax>
                    }
                </div>
            </div>


        </>
    )
}

export const SkeletonCardResultIndex = () => {
    return (
        <div>
            {[...Array(10)].map((e, i) => (
                <div className="dashboard-card">
                    <div className="tw-flex tw-flex-wrap tw-mt-3">
                        <span className="tw-flex tw-items-center">
                            <Skeleton width="1.5rem" height="1.5rem"></Skeleton>
                            <Skeleton width="10rem" className="tw-ml-3"></Skeleton>
                        </span>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mt-3">
                        <Skeleton width="60rem" height="5rem" className="mr-2"></Skeleton>
                    </div>
                    <div className="tw-flex tw-mt-3 tw-flex-wrap">
                        <span className="tw-flex tw-items-center">
                            <Skeleton width="1.5rem" height="1.5rem"></Skeleton>
                            <Skeleton width="50rem" className="tw-ml-3"></Skeleton>
                        </span>
                    </div>
                    <div className="tw-flex tw-mt-3 tw-flex-wrap">
                        <span className="tw-flex tw-items-center">
                            <Skeleton width="1.5rem" height="1.5rem"></Skeleton>
                            <Skeleton width="50rem" className="tw-ml-3"></Skeleton>
                        </span>
                    </div>
                    <div className="tw-flex tw-mt-3 tw-flex-wrap">
                        <span className="tw-flex tw-items-center">
                            <Skeleton width="1.5rem" height="1.5rem"></Skeleton>
                            <Skeleton width="50rem" className="tw-ml-3"></Skeleton>
                        </span>
                    </div>
                    <div className="tw-flex tw-mt-3 tw-flex-wrap">
                        <span className="tw-flex tw-items-center">
                            <Skeleton width="1.5rem" height="1.5rem"></Skeleton>
                            <Skeleton width="50rem" className="tw-ml-3"></Skeleton>
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}