
import { Link, useNavigate } from "react-router-dom";
import "./Mob-header.scss";
import SearchIcon from "@Assets/svg/Home_svgs/SearchIcon.svg";
import SelectmenuSvg from "@Assets/svg/HeaderSvg/selectmenu.svg";
import CloseMenuSvg from "@Assets/svg/HeaderSvg/CloseMenu.svg";
import UparrowSvg from "@Assets/svg/HeaderSvg/Uparrow.svg";
import LogoutSvg from "@Assets/svg/HeaderSvg/Logout.svg";
import DownArrow from "@Assets/svg/Home_svgs/DownArrow.svg";
import { useState } from "react";
import { useAppSelector, useAppDispatch } from "@Src/app/hooks";
import { isLoggedIn } from "@Src/utils/service/app.utility.service";
import { AppRouteURL, AppRouteURLwithChilds, DetailsRouteURL, TestSereisRouteURL } from "@Constants/app-route-url.constant";
import { setIsloggedIn, setLoginToken, setUserID, setloginEmailID, setUserFirtsName, setUserLastName } from "@Src/app/reducers/auth/loginSlice";
import toast from "react-hot-toast";
import { AutoComplete } from 'primereact/autocomplete';
import { getSuggestions } from "@Components/Header/services/Header.service";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";

export const MobHeader = () => {
    const [displaymenu, setdisplaymenu] = useState(false)
    const [subjectdisplay, setsubjectdisplay] = useState(false)
    const [Classdisplay, setClassdisplay] = useState(false)
    const [Testdispay, setTestdispay] = useState(false)
    const [Profiledispay, setProfiledispay] = useState(false);
    const [Dashboardispap, setDashboardispap] = useState(false)
    const headerstore = useAppSelector(state => state.header)
    const Firstname = useAppSelector(state => state.login.Firstname)
    const LastName = useAppSelector(state => state.login.Lastname)

    const isLogin = isLoggedIn()
    const Navigation = useNavigate()
    const dispatch = useAppDispatch()




    const onLogout = () => {
        dispatch(setUserID(null));
        dispatch(setloginEmailID(''));
        dispatch(setLoginToken(''));
        dispatch(setIsloggedIn(false));
        dispatch(setUserFirtsName(''));
        dispatch(setUserLastName(''))
        toast.success('Student loggged out successfully');

    }

    const navigation = useNavigate();
    const [suggestions, setSuggestions] = useState<Array<any>>([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState<Array<any>>([]);
    // const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
    const search = (event: any) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            if (!event.query.trim().length) {
            }
            else if (event.query.trim().length < 3) {
            } else {
                let searchText = event.query.trim();
                getSearchSuggestions(searchText)
            }
        }, 250);
    }






    const itemTemplate = (item: any) => {

        return (
            <div className="search-suggetion-box"  >
                <div>
                    <div className="sug-head"> {item.Name}</div>
                    <div className="sub-sug-card">
                        {item.PName1 ? <> <span>{item.PName1}</span></> : ""}
                        {item.PName2 ? <>| <span>{item.PName2}</span></> : ""}
                        {item.PName3 ? <>| <span>{item.PName3}</span></> : ""}
                    </div>

                </div>

            </div>
        );
    };

    const onEnterAutocomplete = (e: any) => {
        setSelectedSuggestion(e.value);
        if (e.originalEvent && (e.originalEvent.type == "click" || e.originalEvent.key == "Enter"))
            clickEventHandler(e.value)
    }

    function getSearchSuggestions(searchText: string) {
        let data = {
            'searchKey': searchText
        }
        getSuggestions(data).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                setSuggestions(response?.data);
            } else {
                setSuggestions([])
            }
        })
    }

    const clickEventHandler = (item: any) => {
        navigation(`${DetailsRouteURL.SEARCH}?${item.Flag}Id=${item.Id}&${item.Flag}Name=${item.Name}${item.PFlag1 ? `&${item.PFlag1}Id=${item.PId1}&${item.PFlag1}Name=${item.PName1}` : ''}${item.PFlag2 ? `&${item.PFlag2}Id=${item.PId2}&${item.PFlag2}Name=${item.PName2}` : ''}${item.PFlag3 ? `&${item.PFlag3}Id=${item.PId3}&${item.PFlag3}Name=${item.PName3}` : ''}`)
        onClickCrossSearch()
    }

    const onClickCrossSearch = () => {
        setSelectedSuggestion([]);
        setSuggestions([])
    }


    return (
        <>
            <section className="mob-header-container">
                <div>
                    <div className="search-container-mob-vers">
                        {/* <input className="search" id="searchleft" type="search" /> */}
                        <label className="button searchbutton" htmlFor="searchleft"><span className="mglass">
                            <img src={SearchIcon} className="search-svg-img" />
                        </span></label>
                        <AutoComplete className="search-mob" placeholder="Want to take a test?" field="Name" value={selectedSuggestion}
                            suggestions={suggestions} completeMethod={search} minLength={3} delay={250}
                            onChange={(e: any) => { onEnterAutocomplete(e) }} itemTemplate={itemTemplate}
                            autoHighlight={true} showEmptyMessage={true} emptyMessage={'Suggestions Not Found'}
                        />

                    </div>
                </div>

                <div className="mob-header-logo">
                    <Link to='/'>
                        <img src="https://i.postimg.cc/hjp3fD3K/Sample-Logo.png" />
                    </Link>
                </div>
                <div>
                    <span className="select-menu">
                        <img src={SelectmenuSvg} onClick={() => setdisplaymenu(true)} className="Selectmenu-svg-img" />
                    </span>
                </div>
            </section>

            {displaymenu && <section className="overlay-header">
                <div className="overlay-card">
                    <div className="user-name-head">
                        {isLogin && <div className="tw-flex tw-items-center">
                            <span className="user-letter">{Firstname[0]}{LastName[0]}</span>
                            <span className="user-name">{Firstname} {LastName}</span>
                        </div>}
                        {!isLogin && <div>
                            <div className="wel-come-tag">Welcome</div>
                            <span className="log-in tw-mr-5" onClick={() => Navigation(AppRouteURL.AUTH + AppRouteURLwithChilds.AUTH.childrens.LOGIN.path)}>
                                Log in
                            </span>
                            <span className="log-in" onClick={() => Navigation(AppRouteURL.AUTH + AppRouteURLwithChilds.AUTH.childrens.REGISTER.path)}>Sign up</span>
                        </div>}
                        <div>
                            <img src={CloseMenuSvg} onClick={() => { setdisplaymenu(false) }} className="Selectmenu-svg-img" />

                        </div>

                    </div>

                    <div>
                        <ul className="overlay-menu-ul">
                            {isLogin && <>
                                <li className="over-ul-li" >
                                    <a className={`over-link-a  ${Profiledispay ? 'active-a-link' : ''}`} onClick={() => setProfiledispay(!Profiledispay)}>My Account

                                        {!Profiledispay && <span>
                                            <img src={DownArrow} />
                                        </span>}
                                        {Profiledispay && <span>
                                            <img src={UparrowSvg} />
                                        </span>}
                                    </a>

                                    {Profiledispay && <ul className="dropdown-ul">
                                        <li><a onClick={() => { Navigation(AppRouteURL.USERPROFILE + AppRouteURL.MYPROFILE), setdisplaymenu(false) }}>My Profile</a></li>
                                        <li><a onClick={() => { Navigation(AppRouteURL.USERPROFILE + AppRouteURL.CHANGEPASS), setdisplaymenu(false) }}>Change Password</a></li>
                                        <li><a onClick={() => { Navigation(AppRouteURL.USERPROFILE + AppRouteURL.COURSEPREFERD), setdisplaymenu(false) }}>Course Preferences</a></li>

                                    </ul>}
                                </li><li className="over-ul-li" >
                                    <a className={`over-link-a  ${Dashboardispap ? 'active-a-link' : ''}`} onClick={() => setDashboardispap(!Dashboardispap)}>Dashboard

                                        {!Dashboardispap && <span>
                                            <img src={DownArrow} />
                                        </span>}
                                        {Dashboardispap && <span>
                                            <img src={UparrowSvg} />
                                        </span>}
                                    </a>

                                    {Dashboardispap && <ul className="dropdown-ul">
                                        <li><a onClick={() => { Navigation(AppRouteURL.DASHBOARD + AppRouteURL.DASHBOARD_HOME), setdisplaymenu(false) }}>Dashboard</a></li>
                                        {/* <li><a >Mock Test</a></li> */}
                                        {/* <li><a >My Badges</a></li> */}
                                    </ul>}
                                </li>
                            </>}
                            <li className="over-ul-li">
                                <a className={`over-link-a  ${Classdisplay ? 'active-a-link' : ''}`} onClick={() => setClassdisplay(!Classdisplay)}>Class
                                    {!Classdisplay && <span>
                                        <img src={DownArrow} />
                                    </span>}
                                    {Classdisplay && <span>
                                        <img src={UparrowSvg} />
                                    </span>}
                                </a>

                                {Classdisplay && <ul className="dropdown-ul">
                                    {headerstore.class.map((classlist: any) => {
                                        return (
                                            <Link to={`/class/${classlist.Name}/${classlist.Id}`} onClick={() => setdisplaymenu(false)}>
                                                <li key={classlist.Id}>
                                                    <a>{classlist.Name}</a>
                                                </li>
                                            </Link>
                                        )
                                    })}

                                </ul>}
                            </li>
                            <li className="over-ul-li">
                                <a className={`over-link-a  ${subjectdisplay ? 'active-a-link' : ''}`} onClick={() => setsubjectdisplay(!subjectdisplay)}>Subject
                                    {!subjectdisplay && <span>
                                        <img src={DownArrow} />
                                    </span>}
                                    {subjectdisplay && <span>
                                        <img src={UparrowSvg} />
                                    </span>}
                                </a>

                                {subjectdisplay && <ul className="dropdown-ul">
                                    {headerstore.subject.map((SubjectList: any) => {
                                        return (
                                            <Link to={`/subject/${SubjectList.Name}/${SubjectList.Id}`} onClick={() => setdisplaymenu(false)}>
                                                <li key={SubjectList.Id}>
                                                    <a>{SubjectList.Name}</a>
                                                </li>
                                            </Link>
                                        )
                                    })}

                                </ul>}
                            </li>
                            <li className="over-ul-li">
                                <a className={`over-link-a  ${Testdispay ? 'active-a-link' : ''}`} onClick={() => setTestdispay(!Testdispay)}>Test Series

                                    {!Testdispay && <span>
                                        <img src={DownArrow} />
                                    </span>}
                                    {Testdispay && <span>
                                        <img src={UparrowSvg} />
                                    </span>}
                                </a>

                                {Testdispay && <ul className="dropdown-ul">
                                    <Link to={`${TestSereisRouteURL.TEST_SERIES}/all`}>
                                        <li className="user-dropdown-li" id={'all'} key={'all'} ><a href="javascript:void(0)">
                                            See All
                                        </a>
                                        </li>
                                    </Link>
                                    {headerstore.testSeries.map((Testseries: any) => {
                                        return (
                                            <Link to={`/testseries/${Testseries.Name}/${Testseries.Id}`} onClick={() => setdisplaymenu(false)}>
                                                <li key={Testseries.Id}>
                                                    <a>{Testseries.Name}</a>
                                                </li>
                                            </Link>
                                        )
                                    })}

                                </ul>}
                            </li>
                            <li className="over-ul-li"><a className="logout-over-link-a" onClick={() => Navigation(AppRouteURL.CONTACT)}>
                                Contact Us</a></li>

                            {isLogin && <li className="over-ul-li" onClick={() => onLogout()}>
                                <a className="logout-over-link-a">
                                    <span>
                                        <img src={LogoutSvg} className="logout-svg-img" alt="logout-svg" />

                                    </span>

                                    Log Out</a>
                            </li>}
                        </ul>
                    </div>
                </div>

            </section>}

        </>
    )
}