export const FIELD_TYPE = {
    DROPDOWN: 'dropdown',
    MULTISELECT: 'multiselect'
}

export const FILTER_KEYS = {
    BOARD: 'boardId',
}

export const FILTER_LIST = {
    BOARD: 'boardList',
}
 export const FILTER_KEY_LIST = {
    [FILTER_KEYS.BOARD] : FILTER_LIST.BOARD,
 }

 export interface FILTERS_INTERFACE {
    'key':String,
    'name': String,
    'fieldType': String,
    'placeholder': String,
    'list': String,
     'childs':Array<string>,
     'parents': Array<string>
  }

export const BOARD_FILTERS = [
    { [FILTER_KEYS.BOARD]: {
        key: FILTER_KEYS.BOARD,
        name: FILTER_KEYS.BOARD,
        fieldType: FIELD_TYPE.DROPDOWN,
        placeholder: 'Board',
        list: FILTER_LIST.BOARD,
        childs: [],
        parents:[]
    }},
];