import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { TestSteps, TestTypes } from "@Constants/app.constant";
import { InstructionIndex } from "../MockTest/Instruction";
import { SpeedTestCard } from "./SpeedTestCard/SpeedTestCard";
import { TestRouteURL } from "@Constants/app-route-url.constant";
import { getTestDetails, getTestSeriesDetails } from "../TestDetails/services/TestDetails.service";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";

export const SppedTestIndex = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const testId = params.testId;
  const examId = params.examId;
  const timeDuration = Number(searchParams.get('duration'));
  const [testDeatils, setTestDeatils] = useState<any>([]);
  const [step, setStep] = useState(0);
  const [isTestSeries, setIsTestSeries] = useState<boolean>(false);
  const testType = TestTypes.speed;

  useEffect(() => {
    if (location.pathname.includes(`${TestRouteURL.SPEED_TEST_SERIES}`)) {
        setIsTestSeries(true);
        getAllTestSeriesDetails();
    } else {
        setIsTestSeries(false);
        getAllTestDetails();
    }
}, [testId, examId]);

function getAllTestDetails() {
  let param = {
      "testId": testId,
  }
  getTestDetails(param).then((response: APIResponse) => {
      if (response?.ResponseType == APIStatus.SUCCESS) {
          let data = response?.data;
          setTestDeatils(data);
          setStep(TestSteps.INSTRUCTION)
      }
  })
}
function getAllTestSeriesDetails() {
  let param = {
      "examId": examId,
  }
  getTestSeriesDetails(param).then((response: APIResponse) => {
      if (response?.ResponseType == APIStatus.SUCCESS) {
          let data = response?.data;
          setTestDeatils(data);
          setStep(TestSteps.INSTRUCTION)
      }
  })
}

  const onContinueClickHandler = () =>{
      setStep(TestSteps.START_TEST);
  }

  return (
    <>
    {  step == TestSteps.INSTRUCTION   ?
    isTestSeries ?
<InstructionIndex maxMarks="0" noOfQuestion="0" timeDuration={timeDuration} onContinueClickHandler={onContinueClickHandler} testType={testType} isTestSeries={isTestSeries} /> 
    : <InstructionIndex maxMarks="0" noOfQuestion="0" timeDuration={timeDuration} onContinueClickHandler={onContinueClickHandler} testType={testType} isTestSeries={isTestSeries} /> 
    : ''}
      { step == TestSteps.START_TEST ?  
      isTestSeries ? 
<SpeedTestCard testId={examId} noOfQuestion="0" timeDuration={timeDuration}isTestSeries={isTestSeries} testIds={testDeatils.TestIdList} />
      :<SpeedTestCard testId={testId} noOfQuestion="0" timeDuration={timeDuration} isTestSeries={isTestSeries} testIds={[]} /> 
      : '' }

    </>
  );
};
