import "./Footer.scss";
import FooterLogoSvg from "@Assets/svg/Footer/Footerlogo.svg";
import TwitterSvg from "@Assets/svg/Footer/Twitter.svg";
import InstagramSvg from "@Assets/svg/Footer/Instagram.svg";
import FacebookSvg from "@Assets/svg/Footer/FaceBook.svg";
import SocilaiconSvg from "@Assets/svg/Footer/Socila-icon.svg";
import GlobeSvg from "@Assets/svg/Footer/globe.svg";
import { useAppSelector } from "@Src/app/hooks";
import { Link } from "react-router-dom";
import { DetailsRouteURL, TestSereisRouteURL } from "@Constants/app-route-url.constant";

const Footer = () => {
  const headerStore = useAppSelector((state) => state.header);
  let classes = headerStore.class;
  let splitndex: number = 9;
  let classList;
  if (classes.length > 9) {
    classList = [classes.slice(0, splitndex), classes.slice(splitndex + 1)];
  } else {
    classList = [classes];
  }
  let subjects = headerStore.subject;
  let subjectList;
  if (subjects.length > 9) {
    subjectList = [subjects.slice(0, splitndex), subjects.slice(splitndex + 1)];
  } else {
    subjectList = [subjects];
  }
  let testSeries = headerStore.testSeries;
  let testSeriesList;
  if (testSeries.length > 9) {
    testSeriesList = [
      testSeries.slice(0, splitndex),
      testSeries.slice(splitndex + 1),
    ];
  } else {
    testSeriesList = [testSeries];
  }
  return (
    /* -------------------------footer--------------------------------- */

    <section className="footer-section-container">
      <div className="footer-top-sec">
        <div className="f-t-logo-card">
          <div className="footer-logo">
            <img src={FooterLogoSvg} alt="logo-svg" />
          </div>'
          <div className="join-para">
          Join the best and most affordable online platform for all your exam preparation needs
          </div>
         
        </div>
      <div className="f-t-right-sec">
      {classList.length > 0 && (
          <div>
            <p className="footer-list-heading">Classes</p>
            {classList.map((list: any, index: number) => {
              return (
                <>
                  <div key={index}>
                    {list.map((cls: any) => {
                      let name = cls.Name;
                      return (
                        <div key={cls.Id} className="footer-ul-card">
                          <ul className="footer-heading">
                            <Link
                              to={`${DetailsRouteURL.CLASSES_DETAILS}/${name}/${cls.Id}`}
                            >
                              <li>{cls.Name}</li>
                            </Link>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        )}
        {subjectList.length >= 1 && (
          <div>
            <p className="footer-list-heading">Subject</p>
            {subjectList.map((list: any, index: number) => {
              return (
                <>
                  <div key={index}>
                    {list.map((sub: any) => {
                      let name = sub.Name;
                      return (
                        <div key={sub.Id} className="footer-ul-card">
                          <ul className="footer-heading">
                            <Link
                              to={`${DetailsRouteURL.SUBJECT_DETAILS}/${name}/${sub.Id}`}
                            >
                              <li>{sub.Name}</li>
                            </Link>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        )}
        {testSeriesList.length >= 1 && (
          <div>
            <p className="footer-list-heading">Test Series</p>
            {testSeriesList.map((list: any, index: number) => {
              return (
                <>
                  <div key={index}>
                    {list.map((test: any) => {
                      let name = test.Name;
                      return (
                        <div key={test.Id} className="footer-ul-card">
                          <ul className="footer-heading">
                            <Link
                              to={`${TestSereisRouteURL.TEST_SERIES}/${name}/${test.Id}`}
                            >
                              <li>{test.Name}</li>
                            </Link>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>
      </div>
      <div className="footer-bottom-sec">
        <div className="all-right-head">
          © 2023 MockPrep. All rights reserved.
        </div>
        {/* <div className="social-media-icons">
            <span className="icons-svg">
              <img src={TwitterSvg} alt="twitter-svg" />
            </span>
            <span className="icons-svg">
              <img src={InstagramSvg} alt="twitter-svg" />
            </span>
            <span className="icons-svg">
              <img src={FacebookSvg} alt="facebook-svg" />
            </span>
            <span className="icons-svg">
              <img src={SocilaiconSvg} alt="social-svg" />
            </span>
            <span className="icons-svg">
              <img src={GlobeSvg} alt="globe-svg" />
            </span>
          </div> */}
      </div>
    </section>
  );
};

export default Footer;
