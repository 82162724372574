import { Outlet } from "react-router-dom";

import "./UserProfile.scss";

export const UserProfileIndex = () => {
  return (
    <>
      <div>
        <Outlet />
      </div>
    </>
  );
};
