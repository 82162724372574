import ClockSvg from "@Assets/svg/Dashboard-svgs/clock.svg";
import RepeateSvg from "@Assets/svg/Dashboard-svgs/Repeat.svg";
import TestTypeSvg from "@Assets/svg/Dashboard-svgs/Testtype.svg";
import ClassSvg from "@Assets/svg/Dashboard-svgs/Class.svg";
import "./Cards.scss";
import { useNavigate } from "react-router-dom";
import { DetailsRouteURL } from "@Constants/app-route-url.constant";
import { Skeleton } from "primereact/skeleton";
import { AppRouteURL, TestRouteURL } from "@Constants/app-route-url.constant";
import { TestTypes } from "@Constants/app.constant";
import { useEffect, useState } from "react";

export const CardDashboardIndex = ({ toggletabs, dashdetails }: any) => {
    const Navigation = useNavigate();
    const [dashboardData , setDashboardData] = useState<any>([]);
    const onCickRetry = (testID:number, testtype:number, testDuration:number, testSeriesId:number) => 
    { 
    if (testtype == TestTypes.regular) {
        if(testSeriesId){
            Navigation(`${TestRouteURL.TEST_SERIES}/${testSeriesId}`)
        }else{
        Navigation(`${TestRouteURL.TEST}/${testID}`)
        }
    } else if (testtype == TestTypes.speed) {
        if(testSeriesId){
            Navigation(`${TestRouteURL.SPEED_TEST_SERIES}/${testSeriesId}?duration=${testDuration}`) 
        }else{
        Navigation(`${TestRouteURL.SPEED_TEST}/${testID}?duration=${testDuration}`) 
        }
    }
    
    }

    useEffect(()=>{
        let dashboardInfo = dashdetails;
        dashboardInfo.map((detail:any)=>{
           detail['updatedDate'] = new Date(detail['UpdatedDate']);
        })
      const sortedDashboardInfo = dashboardInfo.sort((dateA:any, dateB:any) => dateB.updatedDate - dateA.updatedDate)
      setDashboardData(sortedDashboardInfo);
    },[])

    const goToTestResult=(details:any)=>{
        if(details.TestSeriesId){
            Navigation(`${AppRouteURL.DASHBOARD}${AppRouteURL.TESTRESULT}/${details?.UserId}?examId=${details?.TestSeriesId}&testType=${details?.TestType}&userTestId=${details?.Id}`)
        }else{
        Navigation(`${AppRouteURL.DASHBOARD}${AppRouteURL.TESTRESULT}/${details?.UserId}?testId=${details?.TestId}&testType=${details?.TestType}&userTestId=${details?.Id}`)
        }
    }
    const getDuration = (durationdata: any) => {
        let hours: any = Math.floor(durationdata / 3600);
        let minutes: any = Math.floor((durationdata - (hours * 3600)) / 60);
        let seconds: any = durationdata - (hours * 3600) - (minutes * 60);

        if (hours > 0) {
            hours = (hours < 10) ? "0" + hours : hours;
        } else {
            hours = '';
        }
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        let time = hours + (hours ? ":" : '') + minutes + ":" + seconds
        return time;
    }
    const goToLeaderBoard=(details:any)=>{
        if(details.TestSeriesId){
            Navigation(`${AppRouteURL.DASHBOARD}${AppRouteURL.LEADEBOARD}/${details?.UserId}?examId=${details?.TestSeriesId}&testType=${details?.TestType}`)     
        }else{
            Navigation(`${AppRouteURL.DASHBOARD}${AppRouteURL.LEADEBOARD}/${details?.UserId}?testId=${details?.TestId}&testType=${details?.TestType}`)     
        }
    }

    return (
        <>
            {dashboardData && dashboardData.length ? dashboardData.map((details: any) => {

                return (
                    <div key={details.Id}>
                        {details.IsFinalSubmit === toggletabs && <div className="dashboard-card">
                            <div className="dash-card-header">{details?.testName}</div>
                            <div className="duration-attempts-cards">
                                <div className="dash-test-card">
                                    <span className="test-duration ">
                                        <span className="duration-svg">
                                            <img src={TestTypeSvg} alt="repeat-svg" />

                                        </span>
                                        Type
                                    </span>
                                    <span className="time-span">{details?.TestType === 1 ? 'Regular' : 'Speed'}</span>
                                </div>
                                <div className="dash-test-card">
                                    <span className="test-duration attempts-card">
                                        <span className="duration-svg">
                                            <img src={ClockSvg} alt="clock-svg" />
                                        </span>
                                        Duration
                                    </span>
                                    <span className="time-span">{(details?.Duration) / 60} Mins</span>
                                </div>
                            </div>
                            <div className="duration-attempts-cards dur-marg">
                             {
                              details.TestSeriesId ? 
                              ''
                              : 
                              <div className="dash-test-card">
                                    <span className="test-duration">
                                        <span className="duration-svg">
                                            <img src={ClassSvg} alt="clock-svg" />
                                        </span>
                                        Class
                                    </span>
                                    <span className="time-span">{details?.className}</span>
                                </div>
                             }   

                                <div className="dash-test-card">
                                    <span className={`test-duration ${details.TestSeriesId ? '' : 'attempts-card'}` }>
                                        <span className="duration-svg">
                                            <img src={RepeateSvg} alt="repeat-svg" />

                                        </span>
                                        Attempts
                                    </span>
                                    <span className="time-span">{details?.attempt}</span>
                                </div>
                                { details.IsFinalSubmit == 1 ? 
                                <div className="dash-test-card">
                                    <span className="test-duration attempts-card">
                                        <span className="duration-svg">
                                            <img src={ClockSvg} alt="clock-svg" />
                                        </span>
                                        Time Taken
                                    </span>
                                    <span className="time-span">{getDuration(details?.UsedDuration)}</span>
                                </div> 
                                :
                                ''
                                }
                            </div>
                            <div className="dash-btn-card">
                                   {details.IsFinalSubmit === 1? <button className="view-resultes-btn" onClick={() => goToTestResult(details)}>View Result</button> : ''} 

                                {details.IsFinalSubmit === 1 ? <button className="view-resultes-btn active-btn" onClick={() => goToLeaderBoard(details)}>Go To Leaderboard </button> 
                                : <button onClick={() => onCickRetry(details.TestId,details?.TestType,details?.Duration/60, details.TestSeriesId)}
                                    className="view-resultes-btn active-btn">Retry </button>}

                            </div>
                        </div>}

                    </div>
                )

            })
        :''
        }

        </>
    )
}


export const SkeletonCardDashboardIndex = () => {
    return (
        <div>
            {[...Array(10)].map((e, i) => (
                <div className="dashboard-card">
                    <Skeleton width="10rem" height="2rem" className="mr-2"></Skeleton>
                    <div className="tw-flex tw-flex-wrap tw-mt-3">
                        <span className="tw-flex tw-items-center">
                            <Skeleton width="1.5rem" height="1.5rem"></Skeleton>
                            <Skeleton width="10rem" className="tw-ml-3"></Skeleton>
                        </span>
                        <span className="tw-flex tw-items-center lg:tw-ml-6">
                            <Skeleton width="1.5rem" height="1.5rem"></Skeleton>
                            <Skeleton width="10rem" className="tw-ml-3"></Skeleton>
                        </span>
                    </div>
                    <div className="tw-flex tw-mt-3 tw-flex-wrap">
                        <span className="tw-flex tw-items-center">
                            <Skeleton width="1.5rem" height="1.5rem"></Skeleton>
                            <Skeleton width="10rem" className="tw-ml-3"></Skeleton>
                        </span>
                        <span className="tw-flex tw-items-center lg:tw-ml-6">
                            <Skeleton width="1.5rem" height="1.5rem"></Skeleton>
                            <Skeleton width="10rem" className="tw-ml-3"></Skeleton>
                        </span>
                    </div>

                    <div className="tw-flex tw-flex-wrap tw-mt-5">
                        <Skeleton width="10rem" height="3rem" className="lg:mr-4"></Skeleton>
                        <Skeleton width="10rem" height="3rem"></Skeleton>
                    </div>
                </div>
            ))}
        </div>
    )
}