import './OtherTestRecommendation.scss';
import LeftArrow from "@Assets/svg/Home_svgs/LeftArrow.svg";
import Dots from "@Assets/svg/Home_svgs/Dots.svg";
import FlattedDots from "@Assets/svg/Home_svgs/FlattedDots.svg";
import RightArrow from "@Assets/svg/Home_svgs/RightArrow.svg";
import { getRecommendedTest } from '../../services/SubjectDetails.service';
import { APIResponse, APIStatus } from '@Src/services/baseInterfaces';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DetailsRouteURL } from '@Constants/app-route-url.constant';
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';

export const OtherTestRecommendation = () => {
    const [recommendtest, setrecommendtest] = useState([])
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    useEffect(() => {
        recommendedlist()
    }, [])

    const recommendedlist = () => {
        getRecommendedTest().then((response: APIResponse) => {
            if (response.ResponseType === APIStatus.SUCCESS) {
                setrecommendtest(response?.data)
            }
        }).catch((error: any) => {
            console.log(error)
        })
    }

    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink',
        PrevPageLink: (options: any) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3"> <img src={LeftArrow} />
                    </span>
                </button>
            );
        },
        NextPageLink: (options: any) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3"> <img src={RightArrow} />
                    </span>
                </button>
            );
        },
        PageLinks: (options: any) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    <img src={Dots} />
                </button>
            );
        }
    };
    return (<>
        {recommendtest && recommendtest.length ? <section className="other-test-recmnd-container">
            <div className="mock-test-listing-header">
                Other Test Series Recommended for Students
            </div>
            <div className="others-test-cards-container">
                {
                    recommendtest.slice(first, first + rows).map((element: any) => {
                        return <div className="othr-test-card">
                            <div className="o-t-c-header">
                                <img src="https://i.postimg.cc/L5BX8kMX/image-1.png" alt="ssc-cgl" />
                                <div className="o-t-c-heading">{element.Name}</div>
                                {/* <div className="o-t-c-expires-date">Expires on 29 Sep 2023</div> */}
                            </div>
                            <div className="question-paper">
                                <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                                    <span className="qust-papr-head">Questions</span>
                                    <span className="duration-marks-card">{element.NoOfQuestion}</span>
                                </div>
                                {/* <div className="tw-flex tw-items-center tw-justify-between">
                                    <span className="qust-papr-head">Max Marks</span>
                                    <span className="duration-marks-card">{element.mask}</span>
                                </div> */}
                                <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                                    <span className="qust-papr-head">Time</span>
                                    <span className="duration-marks-card">{element.Duration/60} mins</span>
                                </div>
                            </div>
                            <div className="stnd-cls-card-btn tw-my-4">
                            <Link to={`${DetailsRouteURL.TEST_DETAILS}/${element.Id}`} >
                                <button className="take-test-btn">View Details</button>
                                </Link>
                            </div>
                        </div>
                    })}
            </div>
            <div className="testinomal-pagenation">
            {recommendtest && recommendtest.length > 4 ? <Paginator template={template1} first={first} rows={rows} totalRecords={recommendtest.length} onPageChange={(e) => onPageChange(e)} /> : ''}   
            {/* <Paginator template={template1} first={first} rows={rows} totalRecords={recommendtest.length} onPageChange={(e) => onPageChange(e)} /> */}
                {/* <span>
                    <img src={LeftArrow} />
                </span>
                <span>
                    <img src={FlattedDots} />
                </span>
                <span>
                    <img src={Dots} />
                </span>
                <span>
                    <img src={Dots} />
                </span>
                <span>
                    <img src={Dots} />
                </span>
                <span>
                    <img src={RightArrow} />
                </span> */}
            </div>
        </section> : ''}
    </>

    )
}