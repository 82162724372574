
import DetailsPageBanner from "@Components/DetailsPageBanner/DetailsPageBanner";
import HomeLayout from "../layout/components/HomeLayout/HomeLayout";
import "./TestDetails.scss";
import { useEffect, useState } from 'react';
import { SimilarTests } from './Components/SimilarTests/SimilarTests';
import { getSimilarTests, getTestDetails, getTestSeriesDetails } from './services/TestDetails.service';
import { useLocation, useParams } from 'react-router-dom';
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { TestDescription } from "./Components/TestDescription/TestDescription";
import { TestDetailCard } from "./Components/TestDetailCard/TestDetailCard";
import { TestSereisRouteURL } from "@Constants/app-route-url.constant";
import { HeaderNameKeys } from "@Constants/app.constant";

export const TestDetailsIndex = () => {
    const params = useParams();
    const location = useLocation();
    const testId = params.testId;
    const classId = params.classId;
    const subjectId = params.subjectId;
    const examId = params.examId;
    const [isTestSeries, setIsTestSeries] = useState<boolean>(false);
    const [breadcrumbsHeadings, setBreadcrumbsHeadings] = useState<Array<any>>([]);
    const [similarTests, setSimilarTests] = useState<Array<any>>([]);
    const [testDeatils, setTestDeatils] = useState<Array<any>>([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        if(location.pathname.includes(`${TestSereisRouteURL.TEST_SERIES_DETAIL}`)){
        setIsTestSeries(true);
        getAllTestSeriesDetails();
        let param = {
            'examId': examId,
        }
        getAllSimilarTests(param);
        }else{
        setIsTestSeries(false);
        getAllTestDetails();
        let param = {
            'testId': testId,
        }
        getAllSimilarTests(param);
        }
    }, [testId,classId, examId]);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //     setIsTestSeries(true);
    //     getAllTestSeriesDetails();
    //     getAllSimilarTests();
    // }, [examId]);

    function getAllSimilarTests(param:any) {
        getSimilarTests(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                // show only 4 items
                if (response?.data && response?.data.length >= 4) {
                    data = response?.data.slice(0, 4);
                }
                setSimilarTests(data);
            }
        })
    }

    function getAllTestDetails() {
        let param = {
            "testId": testId,
            "subjectId": subjectId,
            "classId":  classId,        
        }
        getTestDetails(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setTestDeatils(data);
                setBreadcrumbsHeadings([
                    {name:data[0].ClassName, type:HeaderNameKeys.CLASS, id:classId},
                    {name:data[0].SubjectName, type:HeaderNameKeys.SUBJECT, id:subjectId}
                ])
            }
        })
    }

    function getAllTestSeriesDetails() {
        let param = {
            "examId": examId,
        }
        getTestSeriesDetails(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setTestDeatils(data);
                // setBreadcrumbsHeadings([data[0].ClassName,data[0].SubjectName])
            }
        })
    }

    return (
        <>
            <HomeLayout >
                <div className="test-listining test-details-page">
                    <DetailsPageBanner Headings={breadcrumbsHeadings} />
                    <section className="about-benefits-comprise-section">
                        <div className="tw-flex tw-flex-wrap lg:tw-flex-nowrap  tw-mt-5 justify_content" >
                            <TestDescription testDeatils={testDeatils} isTestSeries={isTestSeries} />
                            <TestDetailCard testDeatils={testDeatils} isTestSeries={isTestSeries} />
                        </div>
                        <SimilarTests Heading="Similar Tests For You" list={similarTests} isTestSeries={isTestSeries}  />
                    </section>
                </div>
            </HomeLayout>
        </>
    )
}