
import { useEffect, useState } from "react";
import "./Questionpalette.scss";
import CloseSvg from "@Assets/svg/MockTest-svgs/Close.svg";
import { MathJax } from "better-react-mathjax";
export const QuestionPaletteIndex = ({ questions, jumpToQuestionHandler, onClickBackButtonHandler, testType, fullQuestionPaperMode }: any) => {
    const enum testtype {
        Regular = 1,
        Speed
    }
    const [Questions, setQuestions] = useState<any>(questions);
    useEffect(() => { setQuestions(questions) }, [questions]);
    const onClickBackButton = (value: boolean) => {
        onClickBackButtonHandler(value)
    }
    return (
        <>
            <div className="q-n-ui-1">
                <a href="javascript:void(0)" className="close-pallete-1 tw-mt-2" onClick={() => onClickBackButton(false)}>
                    <img src={CloseSvg} alt="close-svg-img" />
                </a>
                {testType === 2 ?
                    <div className="q-n-header">
                        <h2 className="q-n-h2"> Question Number </h2>
                    </div>
                    : <div className="q-n-header">
                    <h2 className="q-n-h2"> Jump to Question Number </h2>
                </div>
                }
                {fullQuestionPaperMode ?
                    <div className="q-n-contents">
                        <ul className="full-qust-ul">
                            {
                                Questions.map((question: any, index: number) => {
                                    return (
                                        <li key={index}  className={`${testType === 1
                                            ? question.previousQuestionAnswer === false
                                                ? 'active-not'
                                                : question.IsFlag && question.SelectedOption
                                                    ? 'active red-flag'
                                                    : question.SelectedOption
                                                        ? 'active'
                                                        : question.IsVisited && question.IsFlag
                                                            ? 'active-2 red-flag'
                                                            : question.IsVisited
                                                                ? 'active-2'
                                                                : question.IsFlag
                                                                    ? 'red-flag'
                                                                    : ''
                                            : testType === 2
                                                ? question.previousQuestionAnswer === false
                                                    ? 'active-not'
                                                    : question?.SelectedOption
                                                        ? 'active-selected'
                                                        : question?.IsVisited
                                                            ? 'active-2'
                                                            : ''
                                                : ''
                                            }`}
                                            onClick={() => jumpToQuestionHandler(index)} >
                                            <MathJax dangerouslySetInnerHTML={{ __html: `<div class="full-qust-li"><span class="full-qust-palette">${index + 1}</span> <span class="palette-qust">${question.Question}</span></div>` }} dynamic={true} >
                                            </MathJax>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    :
                    <div className="q-n-contents">
                        <ul className="q-n-lists">
                            {
                                Questions.map((question: any, index: number) => {
                                    return (
                                        <li key={index} className={`${testType === 1
                                            ? question.previousQuestionAnswer === false
                                                ? 'active-not'
                                                : question.IsFlag && question.SelectedOption
                                                    ? 'active red-flag'
                                                    : question.SelectedOption
                                                        ? 'active'
                                                        : question.IsVisited && question.IsFlag
                                                            ? 'active-2 red-flag'
                                                            : question.IsVisited
                                                                ? 'active-2'
                                                                : question.IsFlag
                                                                    ? 'red-flag'
                                                                    : ''
                                            : testType === 2
                                                ? question.previousQuestionAnswer === false
                                                    ? 'active-not'
                                                    : question.previousQuestionAnswer === true
                                                    ? 'active'
                                                    : question?.SelectedOption
                                                        ? 'active-selected'
                                                        : question?.IsVisited
                                                            ? 'active-2'
                                                            : ''
                                                : ''
                                            }`}
                                            onClick={() => jumpToQuestionHandler(index)} > {index + 1} </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
        </>
    )
}