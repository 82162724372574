export interface APIResponse {
    ResponseType: string
    StatusMsg: string
    data: any
    success: boolean
}

export const APIStatus  = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    DATA_NOT_FOUND: 'DATA_NOT_FOUND',
    OTP_VERIFIED : 'OTP_VERIFIED',
    EMAIL_NOT_VERIFIED : 'EMAIL_NOT_VERIFIED',
    USER_ALREADY_REGISTERED : 'USER_ALREADY_REGISTERED',
    PHONE_EXIST : 'PHONE_EXIST',
    USER_NOT_REGISTERED:"USER_NOT_FOUND",
    OTP_MISMATCH:"OTP_MISMATCH",
    BOARD_CLASS_NOT_FOUND: 'BOARD_CLASS_NOT_FOUND',
    USER_INACTIVE:'USER_INACTIVE',
    USER_PHONE_NOT_FOUND:'USER_PHONE_NOT_FOUND'
}