import { DashboardSidebarIndex } from "./Sidebar/Index"
import "./Dashboard.scss";
import { Outlet } from "react-router-dom";
import { DashHeaderIndex } from "./Dash-header/Dash-header";


export const DashboardIndex = () => {

    return (
        <>
        <div>
            <DashHeaderIndex />
        <div className="dashboard-container">
            <div className="dashboard-sidebar ">
                <DashboardSidebarIndex />
            </div>
            <div className="dashboard-content-page">
               <Outlet />

            </div>

        </div>
        </div>

        
        </>
    )
}