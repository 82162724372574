import ApiService from "@Src/services/ApiService";
import { USER_PROFILE_API_ROUTES } from "./UserProfile.api.constant";
import { Editprofileintrfc , UpdatePasswordInterface  , UpdateCoursePreferncesIntere} from "../Interfaces/Userprofile.interface";

export async function getUserDetails() { // need to define proper interface
    return await ApiService.fetchData({
        url: USER_PROFILE_API_ROUTES.USER_DETAILS,
        method: "get",
        params:''
    });
}


export async function UpdateuserProfile(Editprofile:Editprofileintrfc) {
    return await ApiService.fetchData({
        url: USER_PROFILE_API_ROUTES.USER_DETAILS,
        method:'post',
        data:Editprofile
    });
}


export async function getCouresePreferences() {
    return await ApiService.fetchData({
        url:USER_PROFILE_API_ROUTES.COURSE_PREFERNCE,
        method:'get',
        params:''
    })
    
}

export async function UpdatePassword(updatepassword:UpdatePasswordInterface) {
    return await ApiService.fetchData({
        url:USER_PROFILE_API_ROUTES.PASSWORD,
        method:'put',
        data:updatepassword
    })
    
}

export async function getBoardlist() {
    return await ApiService.fetchData({
        url:USER_PROFILE_API_ROUTES.BOARD_LIST,
        method:'get',
        params:''
    })
    
}

export async function getClassList(params:any) {
    return await ApiService.fetchData({
        url:USER_PROFILE_API_ROUTES.CLASS_LIST,
        method:'get',
        params:params
    })
    
}
export async function getSubjectList(params:any) {
    return await ApiService.fetchData({
        url:USER_PROFILE_API_ROUTES.SUBJECT_LIST,
        method:'get',
        params:params
    })    
}

export async function getEducationalInterest() {
    return await ApiService.fetchData({
        url:USER_PROFILE_API_ROUTES.EDUCATIONAL_INTEREST,
        method:'get',
        params:''
    })
    
}

export async function UpdateCoursePrefernces(editcourse:UpdateCoursePreferncesIntere) {
    return await ApiService.fetchData({
        url:USER_PROFILE_API_ROUTES.UPDATE_COURSE_PREFER,
        method:'put',
        data:editcourse
    })
    
    
}