import "../TestType/Testtype.scss";
import ActiveProgressbarSvg from "@Assets/svg/Dashboard-svgs/ActiveProgressivebar.svg";
import ProgressbarSvg from "@Assets/svg/Dashboard-svgs/progressiveBar.svg";
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { useState } from "react";

export const TestDurationIndex = ({durationHandler}: any) => {
  const [selectedtimeduration, setselectedtimeduration] = useState(0);


  const citySelectItems = [
    { label: '10 mins', value: 10 },
    { label: '20 mins', value: 20 },
    { label: '30 mins', value: 30 },
    { label: '45 mins', value: 45 },
    { label: '60 mins', value: 60 },
  ];

  const setSelection =(option:number)=>{
    setselectedtimeduration(option);
    durationHandler(option);
  }

  // const continuenxt = (index:any) => {
  //        props.propsselected(index)
  // }

  return (
    <>
      {/* <div className="test-level-indicater">
          <div className="t-l-head">Test Duration Selection</div>
          <div className="t-level-card">1/3</div>
        </div>

        <div className="level-svg-cards">
          <span className="level-svg-span">
            <img src={ActiveProgressbarSvg} alt="progressive-bar" />
          </span>
          <span className="level-svg-span">
            <img src={ProgressbarSvg} alt="progressive-bar" />
          </span>
          <span className="level-svg-span">
            <img src={ProgressbarSvg} alt="progressive-bar" />
          </span>
        </div> */}

      <div className="test-content-card">
        <div className="test-cnt-heading">Select Test Duration</div>
        <div className="input-form-card">
          <Dropdown className="prime-ract-input" value={selectedtimeduration} onChange={(e) => setSelection(e.value)} options={citySelectItems} placeholder="Please Select Test Duration" />
        </div>

      </div>

      {/* <button className="test-cont-btn" onClick={() => continuenxt(1)}>
                Continue
            </button> */}
    </>
  );
};
