import './Testimonials.scss';
import Quotes from "@Assets/svg/Home_svgs/Quotes.svg";
import LeftArrow from "@Assets/svg/Home_svgs/LeftArrow.svg";
import Dots from "@Assets/svg/Home_svgs/Dots.svg";
import FlattedDots from "@Assets/svg/Home_svgs/FlattedDots.svg";
import RightArrow from "@Assets/svg/Home_svgs/RightArrow.svg";

export const Testimonials = () => {
    return (
        <section className="mock-test-testimonial">
            <div className="main-header-card">
                <div className="mock-heading">Testimonials</div>
                <div className="moc-para">Hear what the students have to say </div>
            </div>
            <div className="testimonial-cards-container">
                <div className="testimonial-cards">
                    <div className="tw-flex tw-justify-between">
                        <span className="test-card-img-span">
                            <img src="https://i.postimg.cc/zB5j62F6/Rectangle-25.png" />
                        </span>
                        <div className="tw-mt-2">
                            <div className="w-c-u-card-header">Rohan Bajaj</div>
                            <div className="testimonial-para">10th standard Student, ICSE</div>
                        </div>
                        <span>
                            <img src={Quotes} />
                        </span>
                    </div>
                    <div className="w-c-u-card-para">
                        Ut pharetra ipsum nec leo blandit, sit amet tincidunt eros pharetra. Nam sed imperdiet turpis. In hac habitasse platea dictumst.
                        Praesent nulla massa, hendrerit vestibulum gravida in, feugiat auctor felis.
                    </div>
                </div>
                <div className="testimonial-cards">
                    <div className="tw-flex tw-justify-between">
                        <span className="test-card-img-span">
                            <img src="https://i.postimg.cc/zB5j62F6/Rectangle-25.png" />
                        </span>
                        <div className="tw-mt-2">
                            <div className="w-c-u-card-header">Isha Lamba</div>
                            <div className="testimonial-para">6th standard Student, CBSE</div>
                        </div>
                        <span>
                            <img src={Quotes} />
                        </span>
                    </div>
                    <div className="w-c-u-card-para">
                        Ut pharetra ipsum nec leo blandit, sit amet tincidunt eros pharetra. Nam sed imperdiet turpis. In hac habitasse platea dictumst.
                        Praesent nulla massa, hendrerit vestibulum gravida in, feugiat auctor felis.
                    </div>
                </div>
                <div className="testimonial-cards">
                    <div className="tw-flex tw-justify-between">
                        <span className="test-card-img-span">
                            <img src="https://i.postimg.cc/zB5j62F6/Rectangle-25.png" />
                        </span>
                        <div className="tw-mt-2">
                            <div className="w-c-u-card-header">Rohan Bajaj</div>
                            <div className="testimonial-para">10th standard Student, ICSE</div>
                        </div>
                        <span>
                            <img src={Quotes} />
                        </span>
                    </div>
                    <div className="w-c-u-card-para">
                        Ut pharetra ipsum nec leo blandit, sit amet tincidunt eros pharetra. Nam sed imperdiet turpis. In hac habitasse platea dictumst.
                        Praesent nulla massa, hendrerit vestibulum gravida in, feugiat auctor felis.
                    </div>
                </div>
            </div>
            <div className="testinomal-pagenation">
                <span>
                    <img src={LeftArrow} />
                </span>
                <span>
                    <img src={FlattedDots} />
                </span>
                <span>
                    <img src={Dots} />
                </span>
                <span>
                    <img src={Dots} />
                </span>
                <span>
                    <img src={Dots} />
                </span>
                <span>
                    <img src={RightArrow} />
                </span>
            </div>
        </section>
    )
}