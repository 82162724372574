import { createSlice } from '@reduxjs/toolkit';

/**
 * Need to create interfaces according to /features and create reducers and actions as well.
 */

const initialState = {
    loading: false,
    userAuthenticated: false,
    loginError: "",
    toastDetails: {
        type: '',
        content: '',
        duration: 0
    }
};

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        showSpinner: (state, action) => {
            state.loading = action.payload;
        },
        hideSpinner: (state, action) => {
            state.loading = action.payload;
        },
        setUserAuthenticated: (state, action) => {
            state.userAuthenticated = action.payload;
        },
        setToastDetails: (state, action) => {
            state.toastDetails = action.payload;
        },

    }
});

export const { showSpinner, hideSpinner, setUserAuthenticated, setToastDetails } = globalSlice.actions;
export default globalSlice.reducer;
