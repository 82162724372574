import { useEffect, useState } from 'react';
import './SubjectList.scss'
import UpArrow from "@Assets/svg/Home_svgs/UpArrow.svg";
import UpFilledArrow from "@Assets/svg/Home_svgs/UpFilledArrow.svg";
import { Link } from 'react-router-dom';

export const SubjectList = ({ pageName, subjectList }: any) => {
    const [list, setList] = useState([]);
    const [loadMore, setLoadMore] = useState(false);
    const [isShown, setIsShown] = useState(-1);

    useEffect(() => {
        if (subjectList && subjectList.length >= 12) {
            let data = subjectList.slice(0, 12);
            setList(data);
            setLoadMore(true);
        } else {
            setList(subjectList);
            setLoadMore(false);
        }
    }, [])
    function loadMoreClicked() {
        setList(subjectList);
        setLoadMore(false);
    }
    return (
        <section className="select-your-subject-container">
            <div className="main-header-card">
                <div className="mock-heading">Select your Subject</div>
                <div className="moc-para">Choose a subject to kickstart your exam preparation</div>
            </div>
            <div className="select-your-sbjct-cards-container">
                {
                    list && list.length > 0 && list.map((element: any) => {
                        let name = element.Name;
                        return (
                            <Link to={`${pageName}/${name}/${element.Id}`} className='s-y-link-card'  key={element.Id}>
                                <div onMouseEnter={() => setIsShown(element.Id)} onMouseLeave={() => setIsShown(element.Id)}
                                    className={`s-y-sbjct-card ${(isShown == element.Id) ? "active-subject-selcted" : ""}`} >
                                    <span >
                                        <img src={element.IconImage} onError={(e:any) => {e.target.src = "../../../noimage.png"}} />
                                    </span>
                                    <div className="subj-select-card-header">{element.Name}</div>
                                    {(isShown == element.Id) ? <span className="subject-bg-arrow-svg">
                                        <img src={UpFilledArrow} alt="upfilledarrow-img" />
                                    </span>
                                        : <span className="subject-arrow-svg">
                                            <img src={UpArrow} alt="uparrow-img" />
                                        </span>
                                    }
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
            {
                loadMore ? <div className="lode-more-card">
                    <button className="load-more-btn" onClick={loadMoreClicked} >Load More</button>
                </div> : ''
            }
        </section>
    )
}