export const StopWatchActions = {
    PAUSE: 'pause',
    RESET: 'reset',
    START: 'start'
  }

  export const CountDownActions = {
    PAUSE: 'pause',
    RESUME: 'resume'
  }

  export const enum TestTypes {
    regular = 1,
    speed
  }

  export const TestTypeName = {
    REGULAR: 'regular',
    SPEED: 'speed'
  }

  export const enum TestSteps {
    INSTRUCTION = 1,
    START_TEST
  }

export const GENDER:any = {
  M: 'MALE',
  F: 'FEMALE',
  O: 'OTHERS'
}

export const GENDER_OPTONS = [
  {Name: 'MALE', Id:'M'},
  {Name: 'FEMALE', Id:'F'},
  {Name: 'OTHERS', Id:'O'},
]

export const COUNTRY_CODE = [
  {Name: '+91'},

]

export const ReviewResultType = {
  ALL_ANSWERS:'allAnswers',
  CORRECT_ANSWERS:'correctAnswers',
  INCORRECT_ANSWERS:'incorrectAnswers'
}

export const HeaderNameKeys = {
  CLASS:'class',
  SUBJECT:'subject',
  TEST_SERIES:'testSeries',
  ALL_TEST_SERIES:'allTestSeries'
}
