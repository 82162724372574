import "./LeaderBoard.scss";
import CrowmSvg from "@Assets/svg/Dashboard-svgs/Crown.svg";
import Rank2ndSvg from "@Assets/svg/Dashboard-svgs/rank2nd.svg";
import Rank1stSvg from "@Assets/svg/Dashboard-svgs/ranker1st.svg";
import Rank3rdSvg from "@Assets/svg/Dashboard-svgs/ranker3rd.svg";
import GoldmedalSvg from "@Assets/svg/Dashboard-svgs/Goldmedal.svg";
import SilverMedalSvg from "@Assets/svg/Dashboard-svgs/Silvermedal.svg";
import BronzeMedalSvg from "@Assets/svg/Dashboard-svgs/Bronzemedal.svg";
import BackArrowSvg from "@Assets/svg/Dashboard-svgs/BackArrow.svg";
import rankerbgSvg from "@Assets/svg/Dashboard-svgs/leaderrankerbg.svg";
import CorrectSvg from "@Assets/svg/Dashboard-svgs/correct.svg";
import WroungSvg from "@Assets/svg/Dashboard-svgs/Wroung.svg";
import { useSearchParams, useNavigate } from "react-router-dom";
import { number } from "yup";
import { getLeaderBoard, getLeaderBoardTestSeries } from "../Service/Dashboard.service";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { useEffect, useState } from "react";
import { AppRouteURL } from "@Constants/app-route-url.constant";






export const LeaderBoardIndex = () => {
  const navigation = useNavigate()
  const [Searchparams] = useSearchParams()
  const TestId = Number(Searchparams.get('testId'));
  const examId = Number(Searchparams.get('examId'));
  const TestType = Number(Searchparams.get('testType'))
  const [LeaderDetails, setLeaderDetails] = useState([])
  const [Visible, setVisible] = useState(10)


  useEffect(() => {
    window.scrollTo(0,0)
    if (examId) {
      getLeaderBoardTestSeriesdetails();
    } else {
      getLeaderBoarddetails()
    }
  }, [TestId, examId])

  const getLeaderBoarddetails = () => {
    let params = {
      'testId': TestId,
      'testType': TestType
    }
    getLeaderBoard(params).then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        setLeaderDetails(response.data)

      }
    }).catch((error: any) => {
      console.log(error)
    })

  }

  const getLeaderBoardTestSeriesdetails = () => {
    let params = {
      'examId': examId,
      'testType': TestType
    }
    getLeaderBoardTestSeries(params).then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        setLeaderDetails(response.data)

      }
    }).catch((error: any) => {
      console.log(error)
    })

  }


  const getDuration = (durationdata: any) => {
    let hours: any = Math.floor(durationdata / 3600);
    let minutes: any = Math.floor((durationdata - (hours * 3600)) / 60);
    let seconds: any = durationdata - (hours * 3600) - (minutes * 60);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    return (hours + ":" + minutes + ":" + seconds)
  }

  const LoadMoredata = () => {
    setVisible((previous: any) => previous + 10)
  }



  return (
    <>

      <div className="leader-board-container">
        <div className="leader-header">
          <span className="back-btn-span" onClick={() => navigation(AppRouteURL.DASHBOARD + AppRouteURL.DASHBOARD_HOME)}>
            <img src={BackArrowSvg} alt="back-svg" />
          </span> Leaderboard</div>
        <div className="leader-board-cards-container">
          <div className="l-b-3-rankers-card">
            <div className="bg-svg">
              <img src={rankerbgSvg} alt="rankerbgSvg-svg" />
            </div>
            <div>
              <div className="first-ranker-crown">
                <img src={CrowmSvg} alt="crown-svg" />
              </div>

              <div className="l-b-ranker-content-card">
                {LeaderDetails && LeaderDetails.length > 1 ?
                  <div className="second-ranker-card">
                    <div className="second-ranker-img profile-pic-bg">
                      <img src="https://i.postimg.cc/pVf9y6Hg/user.jpg" />
                    </div>
                    <div className="rank-scored-second">
                      <img src={Rank2ndSvg} alt="rank-svg" />
                    </div>
                    <div className="ranker-name">{LeaderDetails[1]?.['name']}</div>
                    <div className="marks-scored">{LeaderDetails[1]?.['score']}</div>
                  </div>
                  : ""}

                <div className="first-ranker-card">
                  <div className="first-ranker-img profile-pic-bg">
                    <img src="https://i.postimg.cc/pVf9y6Hg/user.jpg" />
                  </div>
                  <div className="rank-scored-first">
                    <img src={Rank1stSvg} alt="rank-svg" />

                  </div>
                  <div className="ranker-name">{LeaderDetails[0]?.['name']}</div>
                  <div className="marks-scored">{LeaderDetails[0]?.['score']}</div>
                </div>

                {LeaderDetails && LeaderDetails.length > 2 ?
                  <div className="third-ranker-card">
                    <div className="third-ranker-img profile-pic-bg">
                      <img src="https://i.postimg.cc/pVf9y6Hg/user.jpg" />
                    </div>
                    <div className="rank-scored-third">
                      <img src={Rank3rdSvg} alt="rank-svg" />
                    </div>
                    <div className="ranker-name">{LeaderDetails[2]?.['name']}</div>
                    <div className="marks-scored">{LeaderDetails[2]?.['score']}</div>
                  </div>

                  : ''}
              </div>
            </div>
          </div>

          <table width="100%" className="rankers-list desktop-list">
            <tr className="rankers-head-list">
              <th></th>
              <th>Rank</th>
              <th>Name</th>
              <th>Score</th>
              <th> Total Attempts</th>
              <th>Time</th>
            </tr>
            {LeaderDetails.slice(0, Visible).map((details: any, index: any) => {
              return (
                <tr className="rankers-list">
                  <td>
                    {index === 0 && <span className="ranker-medal-svg">
                      <img src={GoldmedalSvg} alt="medal-svg" />
                    </span>}
                    {index === 1 && <span className="ranker-medal-svg">
                      <img src={SilverMedalSvg} alt="medal-svg" />
                    </span>}
                    {index == 2 && <span className="ranker-medal-svg">
                      <img src={BronzeMedalSvg} alt="medal-svg" />
                    </span>}
                  </td>
                  <td>{index + 1}</td>
                  <td>{details.name}</td>
                  <td>{details.score}</td>
                  <td>{details.attempt}</td>
                  <td>{LeaderDetails && getDuration(details.time)}</td>
                </tr>
              )
            })}
          </table>

          <table width="100%" className="rankers-list mob-rankers-list">
            <tr className="rankers-head-list">
              <th></th>
              <th>Rank</th>
              <th>Name</th>
              <th>Score</th>
            </tr>
            {LeaderDetails.slice(0, Visible).map((details: any, index: any) => {

              return (
                <tr className="rankers-list">

                  <td>
                    {index === 0 && <span className="ranker-medal-svg">
                      <img src={GoldmedalSvg} alt="medal-svg" />
                    </span>}
                    {index === 1 && <span className="ranker-medal-svg">
                      <img src={SilverMedalSvg} alt="medal-svg" />
                    </span>}
                    {index == 2 && <span className="ranker-medal-svg">
                      <img src={BronzeMedalSvg} alt="medal-svg" />
                    </span>}
                  </td>
                  <td>{index + 1}</td>
                  <td>{details.name}</td>
                  <td className="mob-score-card">
                    <span className="mob-marks-secured">{details.score}</span>
                    <span className="mob-time-rank-card">
                      <span className="mr-2">{details.attempt}</span> |
                      <span className="ml-2">{LeaderDetails && getDuration(details.time)}</span>
                    </span>
                  </td>
                </tr>

              )

            })}

          </table>

          {LeaderDetails.length > 10 && <div className="load-more-card">
            <button className="load-btn" onClick={() => LoadMoredata()}>Load More</button>
          </div>}
        </div>
      </div>





    </>
  );
};
