import { globalRegex } from '@Src/utils/regex/regex.constant';
import { ValidFields } from '@Src/utils/messages/validFields.constant';
import * as Yup from 'yup';

export const ContactUsSchema = Yup.object({
    Name: Yup.string().min(ValidFields.NAME.MIN, ValidFields.NAME.SHORT_MESSAGE).max(ValidFields.NAME.MAX, ValidFields.NAME.LONG_MESSAGE).required(ValidFields.NAME.REQUIRED).matches(globalRegex.ONLY_ALBHABET.REGEX, globalRegex.ONLY_ALBHABET.MESSAGE).matches(globalRegex.BLANK_SPACE.REGEX, ValidFields.NAME.MATCH_MESSAGE),
    EmailId: Yup.string().email(ValidFields.EMAIL.ERROR_MESSAGE).required(ValidFields.EMAIL.REQUIRED),
    ContactNumber: Yup.string().min(ValidFields.MOBILE.MIN, ValidFields.MOBILE.SHORT_MESSAGE).max(ValidFields.MOBILE.MAX, ValidFields.MOBILE.LONG_MESSAGE).required(ValidFields.MOBILE.REQUIRED).matches(globalRegex.MOBILE_NUMBER.REGEX, globalRegex.MOBILE_NUMBER.MESSAGE),
    Subject: Yup.string().min(ValidFields.SUBJECT.MIN, ValidFields.SUBJECT.SHORT_MESSAGE).required(ValidFields.SUBJECT.REQUIRED).matches(globalRegex.BLANK_SPACE.REGEX, ValidFields.SUBJECT.MATCH_MESSAGE),
    Description: Yup.string().min(ValidFields.DESCRIPTION.MIN, ValidFields.DESCRIPTION.SHORT_MESSAGE).required(ValidFields.DESCRIPTION.REQUIRED).matches(globalRegex.BLANK_SPACE.REGEX, ValidFields.DESCRIPTION.MATCH_MESSAGE),
   // Remarks: Yup.string().min(ValidFields.REMARKS.MIN, ValidFields.REMARKS.SHORT_MESSAGE).matches(globalRegex.BLANK_SPACE.REGEX, ValidFields.REMARKS.MATCH_MESSAGE),
})

export const ContactUsInitialValue = {
    Name : "",
    EmailId : "",
    ContactNumber : '',
    Subject : "",
    Description : "",
  //  Remarks : ""
}