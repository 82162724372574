import rightarrowSvg from "@Assets/svg/Dashboard-svgs/right-arrow.svg";
import InstructionSvg from "@Assets/svg/Dashboard-svgs/instruction.svg";
import LeftarrowSvg from "@Assets/svg/Dashboard-svgs/leftarrow.svg";
import PaletteeSvg from "@Assets/svg/MockTest-svgs/Palette.svg";
import { TestTypes } from "@Constants/app.constant";

export const QuestionPaperFooter = ({testType,fullQuestionPaperMode, index, questionsStoreLength, instruction,Qustpalette, onClickPreiousQuestionHandler, setQuestionpaletteHandler,onClickNextQuestionHandler,setFullQuestionPaperModeHandler, setinstructionHadndler,onClickEndTestHandler,submitAnswer,isTestSeries}:any) => {
  const onClickQuestionPalette=()=>{
    if(Qustpalette == true){
      setQuestionpaletteHandler(false);
    }else if(Qustpalette ==false){
      setQuestionpaletteHandler(true);
    }
  }
   
  return (
        <div className="question-footer">
        {
          !fullQuestionPaperMode  && <div className="footer-left-card">
            {testType==TestTypes.regular && index ? <a className="prev-qust" onClick={() => onClickPreiousQuestionHandler()} >
              <img src={LeftarrowSvg} alt="rightarrowSvg" /> Previous
            </a> : ''
            }
            <a className="palette-a-card" onClick={() => onClickQuestionPalette()}>
              <img src={PaletteeSvg} alt="palette-img" />
            </a>
            { testType==TestTypes.regular && index < questionsStoreLength - 1 ? <a className="next-qust" onClick={() => onClickNextQuestionHandler()} >
              Next <img src={rightarrowSvg} alt="rightarrowSvg" /> 
            </a>: ''
}
              { testType==TestTypes.speed && submitAnswer && submitAnswer['SelectedOption'] && submitAnswer['SelectedOption'].length ? <a className="next-qust" onClick={() => onClickNextQuestionHandler()} >
              Next <img src={rightarrowSvg} alt="rightarrowSvg" />
            </a> : ''
              }
          </div>
        }
        {
          fullQuestionPaperMode &&
          <div className="footer-back-card ">
            <a className="back-qust" onClick={() => setFullQuestionPaperModeHandler(false)} >
              <img src={LeftarrowSvg} alt="rightarrowSvg" />  Back
            </a>

          </div>


        }

        {/* <div className="footer-right-card tw-hidden">
          <div className="qust-inst-card">
          {testType==TestTypes.regular ?<a
              href="javascript:void(0)"
              className={`qust-a-btn  ${fullQuestionPaperMode ? 'active-btn' : null}`}
              onClick={() => {
                setFullQuestionPaperModeHandler(true)
              }
              }
            >
              Question Paper
            </a> : ''}  
            <a
              href="javascript:void(0)"
              className={`qust-inst-btn  ${instruction ? 'active-btn' : null}`}
              onClick={() => {
                setinstructionHadndler(true)
              }}
            >
              <img src={InstructionSvg} alt="rightarrowSvg" /> Instructions
            </a>
          </div>

          <div className="tw-w-full" onClick={() => onClickEndTestHandler()} >
            <a href="javascript:void(0)" className="end-test-btn">
              End Test
            </a>
          </div>
        </div> */}
      </div>
)
}
