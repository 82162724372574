export const FIELD_TYPE = {
    DROPDOWN: 'dropdown',
    MULTISELECT: 'multiselect'
}

export const FILTER_KEYS = {
    BOARD: 'boardId',
    CLASS: 'classId',
    SUBJECT: 'subjectId',
    CHAPTER: 'chapterId',
    TOPIC: 'topicId',
    DIFFICULTY: 'difficultyLevelId',
    LANGUAGE: 'languageId',
    TEST: 'testId',
    TEST_SERIES: 'testSeriesId',
    PREVIOUS_YEAR:'yearId',
    SOURCE:'sourceId'
}

export const FILTER_LIST = {
    BOARD: 'boardList',
    CLASS: 'classList',
    SUBJECT: 'subjectList',
    CHAPTER: 'chapterList',
    TOPIC: 'topicList',
    DIFFICULTY: 'difficultyList',
    LANGUAGE: 'languageList',
    TEST_SERIES: 'testSeriesList',
    PREVIOUS_YEAR:'yearList',
    SOURCE:'sourceList'
}
 export const FILTER_KEY_LIST = {
    [FILTER_KEYS.BOARD] : FILTER_LIST.BOARD,
    [FILTER_KEYS.CLASS] : FILTER_LIST.CLASS,
    [FILTER_KEYS.SUBJECT] : FILTER_LIST.SUBJECT,
    [FILTER_KEYS.CHAPTER] : FILTER_LIST.CHAPTER,
    [FILTER_KEYS.TOPIC] : FILTER_LIST.TOPIC,
    [FILTER_KEYS.DIFFICULTY] : FILTER_LIST.DIFFICULTY,
    [FILTER_KEYS.LANGUAGE] : FILTER_LIST.LANGUAGE,
    [FILTER_KEYS.TEST_SERIES] : FILTER_LIST.TEST_SERIES,
    [FILTER_KEYS.PREVIOUS_YEAR] : FILTER_LIST.PREVIOUS_YEAR,
    [FILTER_KEYS.SOURCE] : FILTER_LIST.SOURCE,
 }

 export interface FILTERS_INTERFACE {
    'key':String,
    'name': String,
    'fieldType': String,
    'placeholder': String,
    'list': String,
     'childs':Array<string>,
     'parents': Array<string>
  }

export const BOARD_FILTERS = [
    { [FILTER_KEYS.BOARD]: {
        key: FILTER_KEYS.BOARD,
        name: FILTER_KEYS.BOARD,
        fieldType: FIELD_TYPE.DROPDOWN,
        placeholder: 'Board',
        list: FILTER_LIST.BOARD,
        childs: [FILTER_KEYS.CLASS,
            FILTER_KEYS.SUBJECT,
            FILTER_KEYS.CHAPTER,
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[]
    }},
   {[FILTER_KEYS.CLASS]: {
        key: FILTER_KEYS.CLASS,
        name: FILTER_KEYS.CLASS,
        fieldType: FIELD_TYPE.DROPDOWN,
        placeholder: 'Class',
        list: FILTER_LIST.CLASS,
        childs: [
            FILTER_KEYS.SOURCE,
            FILTER_KEYS.PREVIOUS_YEAR,
            FILTER_KEYS.SUBJECT,
            FILTER_KEYS.CHAPTER,
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[]
    }},
    { [FILTER_KEYS.SOURCE]: {
        key: FILTER_KEYS.SOURCE,
        name: FILTER_KEYS.SOURCE,
        fieldType: FIELD_TYPE.DROPDOWN,
        placeholder: 'Source',
        list: FILTER_LIST.SOURCE,
        childs: [
            FILTER_KEYS.PREVIOUS_YEAR,
            FILTER_KEYS.CHAPTER,
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.CLASS,
        ]
    }},
    { [FILTER_KEYS.PREVIOUS_YEAR]: {
        key: FILTER_KEYS.PREVIOUS_YEAR,
        name: FILTER_KEYS.PREVIOUS_YEAR,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Previous Year',
        list: FILTER_LIST.PREVIOUS_YEAR,
        childs: [
            FILTER_KEYS.CHAPTER,
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.CLASS,
        ]
    }},
    {[FILTER_KEYS.SUBJECT]: {
        key: FILTER_KEYS.SUBJECT,
        name: FILTER_KEYS.SUBJECT,
        fieldType: FIELD_TYPE.DROPDOWN,
        placeholder: 'Subject',
        list: FILTER_LIST.SUBJECT,
        childs: [
          //  FILTER_KEYS.SOURCE,
          //  FILTER_KEYS.PREVIOUS_YEAR,
            FILTER_KEYS.CHAPTER,
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.CLASS
        ]
    }},
   {[FILTER_KEYS.CHAPTER]: {
        key: FILTER_KEYS.CHAPTER,
        name: FILTER_KEYS.CHAPTER,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Chapter',
        list: FILTER_LIST.CHAPTER,
        childs: [
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.SUBJECT
        ]
    }},
    {[FILTER_KEYS.TOPIC]: {
        key: FILTER_KEYS.TOPIC,
        name: FILTER_KEYS.TOPIC,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Topic',
        list: FILTER_LIST.TOPIC,
        childs: [
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.SUBJECT
        ]
    }},
    {[FILTER_KEYS.DIFFICULTY]: {
        key: FILTER_KEYS.DIFFICULTY,
        name: FILTER_KEYS.DIFFICULTY,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Difficulty Level',
        list: FILTER_LIST.DIFFICULTY,
        childs: [
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.SUBJECT
        ]
    }},
    {[FILTER_KEYS.LANGUAGE]:{
        key: FILTER_KEYS.LANGUAGE,
        name: FILTER_KEYS.LANGUAGE,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Language',
        list: FILTER_LIST.LANGUAGE,
        childs: [],
        parents:[
            FILTER_KEYS.SUBJECT
        ]
    }}

];

export const TEST_SERIES_FILTERS =[
    { [FILTER_KEYS.TEST_SERIES]: {
        key: FILTER_KEYS.TEST_SERIES,
        name: FILTER_KEYS.TEST_SERIES,
        fieldType: FIELD_TYPE.DROPDOWN,
        placeholder: 'Test Series',
        list: FILTER_LIST.TEST_SERIES,
        childs: [
            FILTER_KEYS.SOURCE,
            FILTER_KEYS.PREVIOUS_YEAR,
            FILTER_KEYS.SUBJECT,
            FILTER_KEYS.CHAPTER,
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[]
    }},
    { [FILTER_KEYS.SOURCE]: {
        key: FILTER_KEYS.SOURCE,
        name: FILTER_KEYS.SOURCE,
        fieldType: FIELD_TYPE.DROPDOWN,
        placeholder: 'Source',
        list: FILTER_LIST.SOURCE,
        childs: [
            FILTER_KEYS.PREVIOUS_YEAR,
            FILTER_KEYS.CHAPTER,
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.TEST_SERIES,
        ]
    }},
    { [FILTER_KEYS.PREVIOUS_YEAR]: {
        key: FILTER_KEYS.PREVIOUS_YEAR,
        name: FILTER_KEYS.PREVIOUS_YEAR,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Previous Year',
        list: FILTER_LIST.PREVIOUS_YEAR,
        childs: [
            FILTER_KEYS.CHAPTER,
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.TEST_SERIES,
        ]
    }},
    {[FILTER_KEYS.SUBJECT]: {
        key: FILTER_KEYS.SUBJECT,
        name: FILTER_KEYS.SUBJECT,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Subject',
        list: FILTER_LIST.SUBJECT,
        childs: [
          //  FILTER_KEYS.SOURCE,
           // FILTER_KEYS.PREVIOUS_YEAR,
            FILTER_KEYS.CHAPTER,
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.TEST_SERIES,
        ]
    }},
   {[FILTER_KEYS.CHAPTER]: {
        key: FILTER_KEYS.CHAPTER,
        name: FILTER_KEYS.CHAPTER,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Chapter',
        list: FILTER_LIST.CHAPTER,
        childs: [
            FILTER_KEYS.TOPIC,
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.SUBJECT
        ]
    }},
    {[FILTER_KEYS.TOPIC]: {
        key: FILTER_KEYS.TOPIC,
        name: FILTER_KEYS.TOPIC,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Topic',
        list: FILTER_LIST.TOPIC,
        childs: [
            FILTER_KEYS.DIFFICULTY,
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.SUBJECT
        ]
    }},
    {[FILTER_KEYS.DIFFICULTY]: {
        key: FILTER_KEYS.DIFFICULTY,
        name: FILTER_KEYS.DIFFICULTY,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Difficulty Level',
        list: FILTER_LIST.DIFFICULTY,
        childs: [
            FILTER_KEYS.LANGUAGE
        ],
        parents:[
            FILTER_KEYS.SUBJECT
        ]
    }},
    {[FILTER_KEYS.LANGUAGE]:{
        key: FILTER_KEYS.LANGUAGE,
        name: FILTER_KEYS.LANGUAGE,
        fieldType: FIELD_TYPE.MULTISELECT,
        placeholder: 'Language',
        list: FILTER_LIST.LANGUAGE,
        childs: [],
        parents:[
            FILTER_KEYS.SUBJECT
        ]
    }}
]