import { Dialog } from "primereact/dialog"
import "./ExpireTimeEndTestDialog.scss"

export const ExpireTimeEndTestDialog = ({ expireTestDialog, endTestHandler }: any) => {
  return (
    <>      <Dialog position="top" className="custom-expire-dialog" visible={expireTestDialog}  onHide={() => endTestHandler()} closeOnEscape={false} >
      <div className="Expire-time-box">
        <div className="ex-t-head">Your Time is Up!</div>
        <span className="ex-t-para ">You have reached your time limit for your test!</span>

        <div>
          <button className="dialog-end-test-btn"
            onClick={() => endTestHandler()} >View Result</button>
        </div>
      </div>
    </Dialog>
    </>
  )
}