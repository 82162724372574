import {  useEffect, useState } from "react";
import { TestType } from "./Components/TestType/TestType";
import "./MockTest.scss";
import { TestDurationIndex } from "./TestDuration";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppRouteURL, TestRouteURL, TestSereisRouteURL } from "@Constants/app-route-url.constant";
import { toast } from "react-hot-toast";
import { TestTypes} from '@Constants/app.constant';

export const MockTestIndex = () => {
    const params = useParams();
    const navigation = useNavigate();
    const location = useLocation();
    const [selectionOptionScreen, setSelectionOptionScreen] = useState(1);
    const selectionScreenHandler = (option: number) => {
        setSelectionOptionScreen(option)
    }
    const testId = params.testId;
    const examId = params.examId;
    const [testtype, setTesttype] = useState(-1);
    const [testDuration, setTestDuration] = useState(-1);
    const [isTestSeries, setIsTestSeries] = useState<boolean>(false);

    useEffect(() => {
        if(location.pathname.includes(`${AppRouteURL.MOCKTEST_SERIES}`)){
        setIsTestSeries(true);
        }else{
            setIsTestSeries(false);
        }
    },[])

    const onContinueClick = () => {
        if(selectionOptionScreen == 1){
        if (testtype == TestTypes.regular) {
            if(isTestSeries){
                navigation(`${TestRouteURL.TEST_SERIES}/${examId}`)
            }else{
            navigation(`${TestRouteURL.TEST}/${testId}`)
            }
        } else if (testtype == TestTypes.speed) {
            selectionScreenHandler(2)
        } else {
            toast.error("Please select test type")
        }
    }else if(selectionOptionScreen == 2){
        if(testDuration >= 0){
        if(isTestSeries){
            navigation(`${TestRouteURL.SPEED_TEST_SERIES}/${examId}?duration=${testDuration}`) 
        }else{
        navigation(`${TestRouteURL.SPEED_TEST}/${testId}?duration=${testDuration}`) 
        }
    }else{
        toast.error('Please select test duration')
    }
    }
    }

    const testTypeHandler = (option: number) => {
        setTesttype(option);
    }

    const durationHandler = (option:number)=>{
        setTestDuration(option);
    }
   
    const onBackClick = ()=>{
        setSelectionOptionScreen(1);
    }

    return (
        <>
            <div className="test-type-selection-container ">
                <div className="test-typ-header">Mock Tests</div>
                {/* <div className="test-level-indicater">
          <div className="t-l-head">Test Type Selection</div>
          <div className="t-leuuivel-card">2/3</div>
        </div> */}
                {selectionOptionScreen == 1 ? <TestType testTypeHandler={testTypeHandler} /> :
                    selectionOptionScreen == 2 ? <TestDurationIndex durationHandler={durationHandler} /> : ''}
                {
                    selectionOptionScreen != 1 ? <button className="test-back-btn" onClick={() => { onBackClick() }} >
                        Back
                    </button> : ''
                }
                <button className="test-cont-btn lg:tw-ml-3" onClick={() => { onContinueClick() }} >
                    Continue
                </button>
            </div>
        </>
    );
};
