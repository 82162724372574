

import { globalRegex } from '@Src/utils/regex/regex.constant';
import { ValidFields } from '@Src/utils/messages/validFields.constant';
import * as Yup from 'yup';

export const UpdatePasswordSchema = Yup.object({
    oldPassword: Yup.string().min(ValidFields.PASSWORD.MIN, ValidFields.PASSWORD.SHORT_MESSAGE).required(ValidFields.PASSWORD.REQUIRED).matches(globalRegex.PASSWORD.REGEX, globalRegex.PASSWORD.MESSAGE),
    newPassword:Yup.string().min(ValidFields.PASSWORD.MIN, ValidFields.PASSWORD.SHORT_MESSAGE).required(ValidFields.NEW_PASSWORD.REQUIRED).matches(globalRegex.PASSWORD.REGEX, globalRegex.PASSWORD.MESSAGE),
    re_newPassword:Yup.string().oneOf([Yup.ref('newPassword')] , "Password Doesn't match " ).min(ValidFields.PASSWORD.MIN, ValidFields.PASSWORD.SHORT_MESSAGE).required(ValidFields.ConForm_PASSWORD.REQUIRED).matches(globalRegex.PASSWORD.REGEX, globalRegex.PASSWORD.MESSAGE),
})

export const perosnalProfileSchema = Yup.object({
    PhoneNo: Yup.string().min(ValidFields.MOBILE.MIN, ValidFields.MOBILE.SHORT_MESSAGE).max(ValidFields.MOBILE.MAX, ValidFields.MOBILE.LONG_MESSAGE).required(ValidFields.MOBILE.REQUIRED).matches(globalRegex.MOBILE_NUMBER.REGEX, globalRegex.MOBILE_NUMBER.MESSAGE),
     DOB: Yup.date().nullable().required(ValidFields.DATE_OF_BIRTH.REQUIRED),
    Gender: Yup.string().required(ValidFields.GENDER.REQUIRED),
    city: Yup.string().required(ValidFields.CITY.REQUIRED),
    stateId: Yup.string().required(ValidFields.STATE.REQUIRED),
    school: Yup.string().required(ValidFields.SCHOOL.REQUIRED),
    countryCode: Yup.string()
})

export const EditProfileInitialValue = {
    PhoneNo: '',
    DOB: null,
    Gender: '',
    city: '',
    stateId: '',
    school: '',
    countryCode: ''
}

export const UpdatePasswordInitialvalue = {
    oldPassword:'',
    newPassword:'',
    re_newPassword:''
}

export const EditCoursePrefernces = {
    classlistId:null,
    boardListId: '',
    subjectListId: [],
    educationInterestListId:''
}
