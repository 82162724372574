import { AppRouteURL } from "@Constants/app-route-url.constant"
import { useAppDispatch } from "@Src/app/hooks";
import { setPreviousUrl } from "@Src/app/reducers/auth/loginSlice";
import { isLoggedIn } from "@Src/utils/service/app.utility.service";
import { useNavigate } from "react-router-dom"
import { Tooltip } from 'primereact/tooltip';
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { getPreviousOpenStateTest, putEndPreviousOpenStateTests } from "../../services/TestDetails.service";
import { OpenStateTestDialog } from "@Components/openStateTestDialog/openStateTestDialog";
import { useState } from "react";
import { showSpinner } from "@Src/app/reducers/globalSlice";

export const TestDetailCard = ({ testDeatils, isTestSeries }: any) => {
    const isLogin = isLoggedIn()
    const navigation = useNavigate();
    const dispatch = useAppDispatch()
   const [openStateDialog, setOpenStateDialog] = useState<boolean>(false);
   const [previousOpenTestDetails, setPreviousOpenTestDetails] = useState<any>();

    const clickTakeTestHandler = (item: any, isTestSeries: boolean) => {
        let takeTestUrl = '';
        if (isTestSeries) {
            takeTestUrl = AppRouteURL.DASHBOARD + AppRouteURL.MOCKTEST_SERIES + '/' + item.Id;
        } else {
            takeTestUrl = AppRouteURL.DASHBOARD + AppRouteURL.MOCKTEST + '/' + item.Id;
        }
        if (isLogin) {
            checkPreviousOngoingTest(takeTestUrl);
           // navigation(`${takeTestUrl}`)
        } else {
            dispatch(setPreviousUrl(takeTestUrl));
            navigation(`${AppRouteURL.AUTH}`)
        }
    }
  
    const checkPreviousOngoingTest = (url:string) => {
        dispatch(showSpinner(true));
        getPreviousOpenStateTest().then((response: APIResponse) => {
            dispatch(showSpinner(false));
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                if(data && data.TestName){
                setPreviousOpenTestDetails(data);
                setOpenStateDialog(true);
                }else{
                    navigation(url);    
                }
            }else{
                navigation(url);
            }
              })
    }


    const endPreviousOngoingTest = () => {
        dispatch(showSpinner(true));
        putEndPreviousOpenStateTests().then((response: APIResponse) => {
            dispatch(showSpinner(false));
            if (response?.ResponseType == APIStatus.SUCCESS) {
                setOpenStateDialog(false);
                let takeTestUrl = '';
                if (isTestSeries) {
                    takeTestUrl = AppRouteURL.DASHBOARD + AppRouteURL.MOCKTEST_SERIES + '/' + testDeatils.Id;
                } else {
                    takeTestUrl = AppRouteURL.DASHBOARD + AppRouteURL.MOCKTEST + '/' + testDeatils[0].Id;
                } 
                navigation(takeTestUrl);       
              //  clickTakeTestHandler(isTestSeries ? testDeatils : testDeatils[0],isTestSeries);
            }
        })
    }

    const endTestHandler = () =>{
        endPreviousOngoingTest();
    }
    return (
        <>
            {
                isTestSeries ?
                    testDeatils && testDeatils.Id ? <div className="eng-litera-card">
                        <div className="eng-ltr-bg-img">
                            <img src={testDeatils.Image} alt={testDeatils.Name} />

                            <div className="subject-test-levl-card">Level {testDeatils.DifficultyLevel}</div>
                        </div>

                        <div className="test-details-card">
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Test Series</span>
                                <span className="test-detail-info"><span className="test-span-info custom-tooltip-text-info-Series">{testDeatils.Name} </span></span>
                                <Tooltip target=".custom-tooltip-text-info-Series" position='bottom' >
                                    {testDeatils.Name}
                                </Tooltip>
                            </div>
                            {
                                testDeatils.SubjectList && testDeatils.SubjectList.length ? <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                    <span className="test-detail-heading">Subject</span>
                                    <span className="test-detail-info" ><span className="test-span-info custom-tooltip-text-info-Subject">{testDeatils.SubjectList[0]}</span>
                                        {testDeatils.SubjectList.length > 1 ?
                                            <span className="custom-tooltip-btn-subject"  >+{testDeatils.SubjectList.length - 1} </span>
                                            : ''}
                                    </span>
                                    <Tooltip target=".custom-tooltip-text-info-Subject" position='bottom' >
                                        {testDeatils.SubjectList[0]}
                                    </Tooltip>
                                    <Tooltip target=".custom-tooltip-btn-subject" position='bottom' >
                                        {
                                            testDeatils.SubjectList.map((subject: string, index: number) => {
                                                if (index > 0) {
                                                    return <span>{subject} </span>
                                                }
                                            })
                                        }
                                    </Tooltip>
                                </div> : ''
                            }
                            {
                                testDeatils.ChapterList && testDeatils.ChapterList.length ? <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                    <span className="test-detail-heading">Chapter</span>
                                    <span className="test-detail-info"><span className="test-span-info custom-tooltip-text-info-Chapter">{testDeatils.ChapterList[0]}</span>
                                        {testDeatils.ChapterList.length > 1 ?
                                            <span className="custom-tooltip-btn-chapter"> +{testDeatils.ChapterList.length - 1}</span>
                                            : ''}
                                    </span>
                                    <Tooltip target=".custom-tooltip-text-info-Chapter" position='bottom' >

                                        {testDeatils.ChapterList[0]}
                                    </Tooltip>
                                    <Tooltip target=".custom-tooltip-btn-chapter" position='bottom' >
                                        {
                                            testDeatils.ChapterList.map((chapter: string, index: number) => {
                                                if (index > 0) {
                                                    return <span>{chapter} </span>
                                                }
                                            })
                                        }
                                    </Tooltip>
                                </div> : ''
                            }
                            {
                                testDeatils.TopicList && testDeatils.TopicList.length ? <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                    <span className="test-detail-heading">Topic</span>
                                    <span className="test-detail-info"><span className="test-span-info custom-tooltip-text-info-Topic">{testDeatils.TopicList[0]}</span>
                                        {testDeatils.TopicList.length > 1 ?
                                            <span className="custom-tooltip-btn-topic"> +{testDeatils.TopicList.length - 1}</span>
                                            : ''}
                                    </span>
                                    <Tooltip target=".custom-tooltip-text-info-Topic" position='bottom' >
                                        {testDeatils.TopicList[0]}
                                    </Tooltip>
                                    <Tooltip target=".custom-tooltip-btn-topic" position='bottom' >
                                        {
                                            testDeatils.TopicList.map((topic: string, index: number) => {
                                                if (index > 0) {
                                                    return <span>{topic} </span>
                                                }
                                            })
                                        }
                                    </Tooltip>
                                </div> : ''
                            }
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Difficulty Level</span>
                                <span className="test-detail-info"> {testDeatils.DifficultyLevel} </span>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Questions</span>
                                <span className="test-detail-info"> {testDeatils.NoOfQues} </span>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Time</span>
                                <span className="test-detail-info"> {testDeatils.Duration / 60} mins </span>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Language</span>
                                <span className="test-detail-info">{testDeatils.Language}</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Access</span>
                                <span className="test-detail-info">Lifetime</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Students who took the test</span>
                                <span className="test-detail-info">{testDeatils.NoOfStudent}</span>
                            </div>
                        </div>

                        <div className="take-test-card " onClick={() => clickTakeTestHandler(testDeatils, isTestSeries)} >
                            <button className="take-test-btn tw-mt-3">Take Test</button>
                        </div>
                    </div> : ''

                    :
                    testDeatils && testDeatils.length && testDeatils[0].Id ? <div className="eng-litera-card">
                        <div className="eng-ltr-bg-img">
                            <img src={testDeatils[0].Image} className="eng-subject-img" alt={testDeatils[0].Name} />
                            <div className="subject-test-levl-card">Level {testDeatils[0].DifficultyLevel}</div>
                        </div>

                        <div className="test-details-card">
                            <div className="tw-flex tw-items-center tw-justify-between  tw-my-4">
                                <span className="test-detail-heading">Class</span>
                                <span className="test-detail-info">{testDeatils[0].ClassName}</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Subject</span>
                                <span className="test-detail-info"><span className="test-span-info custom-tooltip-text-info-Subject2">{testDeatils[0].SubjectName}</span>  </span>
                                <Tooltip target=".custom-tooltip-text-info-Subject2" position='bottom' >
                                    {testDeatils[0].SubjectName}
                                </Tooltip>
                            </div>
                            {
                                testDeatils[0].ChapterList && testDeatils[0].ChapterList.length ? <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                    <span className="test-detail-heading">Chapter</span>
                                    <span className="test-detail-info"><span className="test-span-info custom-tooltip-text-info-Chapter2">{testDeatils[0].ChapterList[0]}</span>
                                        {testDeatils[0].ChapterList.length > 1 ?
                                            <span className="custom-tooltip-btn-chapter"> +{testDeatils[0].ChapterList.length - 1}</span>
                                            : ''}
                                    </span>
                                    <Tooltip target=".custom-tooltip-text-info-Chapter2" position='bottom' >
                                        {testDeatils[0].ChapterList[0]}
                                    </Tooltip>
                                    <Tooltip target=".custom-tooltip-btn-chapter" position='bottom' >
                                        {
                                            testDeatils[0].ChapterList.map((chapter: string, index: number) => {
                                                if (index > 0) {
                                                    return <span>{chapter} </span>
                                                }
                                            })
                                        }
                                    </Tooltip>
                                </div> : ''
                            }
                            {
                                testDeatils[0].TopicList && testDeatils[0].TopicList.length ? <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                    <span className="test-detail-heading">Topic</span>
                                    <span className="test-detail-info"><span className="test-span-info custom-tooltip-text-info-Topic2">{testDeatils[0].TopicList[0]}</span>
                                        {testDeatils[0].TopicList.length > 1 ?
                                            <span className="custom-tooltip-btn-topic"> +{testDeatils[0].TopicList.length - 1}</span>
                                            : ''}
                                    </span>
                                    <Tooltip target=".custom-tooltip-text-info-Topic2" position='bottom' >
                                        {testDeatils[0].TopicList[0]}
                                    </Tooltip>
                                    <Tooltip target=".custom-tooltip-btn-topic" position='bottom' >
                                        {
                                            testDeatils[0].TopicList.map((topic: string, index: number) => {
                                                if (index > 0) {
                                                    return <span>{topic} </span>
                                                }
                                            })
                                        }
                                    </Tooltip>
                                </div> : ''
                            }
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Difficulty Level</span>
                                <span className="test-detail-info"> {testDeatils[0].DifficultyLevel} </span>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Questions</span>
                                <span className="test-detail-info"> {testDeatils[0].NoOfQues} </span>
                            </div>
                            {/* <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Marks</span>
                                <span className="test-detail-info"> {testDeatils[0].Marks} </span>
                            </div> */}
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Time</span>
                                <span className="test-detail-info"> {testDeatils[0].Duration / 60} mins </span>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Language</span>
                                <span className="test-detail-info">{testDeatils[0].Language}</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Access</span>
                                <span className="test-detail-info">Lifetime</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                                <span className="test-detail-heading">Students who took the test</span>
                                <span className="test-detail-info">{testDeatils[0].NoOfStudent}</span>
                            </div>
                        </div>


                        {/* <div className="choose-test-lang-card">
                        <span>Choose Test  Language</span>


                        <Dropdown  optionLabel="name" 
                placeholder="Select a City" className="w-full md:w-14rem" />

                    </div> */}

                        <div className="take-test-card " onClick={() => clickTakeTestHandler(testDeatils[0], isTestSeries)} >
                            <button className="take-test-btn tw-mt-3">Take Test</button>
                        </div>
                    </div> : ''
            }
                        <OpenStateTestDialog openStateDialog={openStateDialog} previousOpenTestDetails={previousOpenTestDetails} endTestHandler={endTestHandler} />
        </>
    )
}
