import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from '@App/store';
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "@App/store";
import { MathJaxContext } from 'better-react-mathjax';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const config = {
	loader: {
		load: ["input/asciimath", 'output/chtml', 'ui/menu',
			'[tex]/require', '[tex]/tagformat', '[tex]/ams', '[tex]/enclose',
			'[tex]/action',
			'[tex]/mathtools',
			'[tex]/physics',
			'[tex]/cancel',
			'[tex]/color',
			'[tex]/extpfeil',
			'[tex]/mhchem',
			'[tex]/extpfeil',
			// commenting as not processing all mathjax formulas on page
			//'ui/lazy'
		]
	},
	asciimath: {
		delimiters: [['`', '`']]
	},
	CommonHTML: {
		mtextFontInherit: true
	  },  
	tex: {
		inlineMath: [              // start/end delimiter pairs for in-line math
			['\\(', '\\)'], ["$", "$"],
		],
		displayMath: [             // start/end delimiter pairs for display math
			['$$', '$$'],
			['\\[', '\\]']
		],
		processEscapes: true,      // use \$ to produce a literal dollar sign
		processEnvironments: true, // process \begin{xxx}...\end{xxx} outside math mode
		processRefs: true,         // process \ref{...} outside of math mode
		digits: /^(?:[0-9]+(?:\{,\}[0-9]{3})*(?:\.[0-9]*)?|\.[0-9]+)/,
		// pattern for recognizing numbers
		tags: 'none',              // or 'ams' or 'all'
		tagSide: 'right',          // side for \tag macros
		tagIndent: '0.8em',        // amount to indent tags
		useLabelIds: true,         // use label name rather than tag for ids
		maxMacros: 1000,           // maximum number of macro substitutions per expression
		maxBuffer: 5 * 1024,       // maximum size for the internal TeX string (5K)
		baseURL:                   // URL for use with links to tags (when there is a <base> tag in effect)
			(document.getElementsByTagName('base').length === 0) ?
				'' : String(document.location).replace(/#.*$/, ''),
		equationNumbers: { autoNumber: "all" },
		extensions: ["AMSmath.js", "AMSsymbols.js", "AMScd.js", "autobold.js", "action.js", "begingroup.js", 'mathtools.js', "require.js", "tagformat.js", "physics.js",
			"cancel.js", "color.js", "enclose.js", "extpfeil.js", "mhchem.js", "noErrors.js", "noUndefined.js", "autoload-all.js", "mediawiki-texvc.js"],
		packages: {
			'+': ["AMSmath", "AMSsymbols", "AMScd", "autobold", "action", "begingroup", 'mathtools', "require", "tagformat", "physics",
				"cancel", "color", "enclose", "extpfeil", "mhchem", "noErrors", "noUndefined", "autoload-all", "mediawiki-texvc"]
		},
		TagSide: "left",
		mhchem: { legacy: true },
		noErrors: {
			inlineDelimiters: [["", ""], ["$", "$"], ["\\(", "\\)"]],   // or ["$","$"] or ["\\(","\\)"]
			multiLine: true,             // false for TeX on all one line
			style: {
				"font-size": "90%",
				"text-align": "left",
				"color": "black",
				"padding": "1px 3px",
				"border": "1px solid"
			}
		},
		noUndefined: {
			attributes: {
				mathcolor: "red",
				mathbackground: "#FFEEEE",
				mathsize: "90%"
			}
		},
		unicode: {
			fonts: "STIXGeneral, 'Arial Unicode MS'"
		}
	}
};

root.render(
	// <React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<MathJaxContext config={config}>
					<BrowserRouter basename="/">
						<App />
					</BrowserRouter>
				</MathJaxContext>
			</PersistGate>
		</Provider>
	// </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
