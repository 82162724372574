import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AppRouteURL, AppRouteURLwithChilds, DetailsRouteURL, TestRouteURL, TestSereisRouteURL } from "@Constants/app-route-url.constant";
import Loader from "@Components/loader/Loader";
import { Home } from "@Pages/Home/Home";
import { UserProfile } from "@Pages/UserProfile/UserProfile";
import { ChangePasswordIndex } from "@Src/features/UserProfile/ChangePassword";
import { CoursePreferenceIndex } from "@Src/features/UserProfile/CoursesPreferences";
import { MyProfileIndex } from "@Src/features/UserProfile/Myprofile/Index";
import { Dashboard } from "@Pages/Dashboard";
import { DashboardpageIndex } from "@Src/features/Dashboard/Dashboardpage";
import { LeaderBoardIndex } from "@Src/features/Dashboard/LeaderBoard";
import { MyBadgesIndex } from "@Src/features/Dashboard/MyBadges";
import ClassesDetails from "@Src/features/ClassesDetails";
import { TestDetails } from "@Pages/TestDetails";
import SubjectDetails from "@Src/features/SubjectDetails";
import TestSeriesDetails from "@Src/features/TestSeriesDetails";
import { Test } from "@Pages/MockTest/MockTest";
import { MockTestIndex } from "@Src/features/Dashboard/MockTest";
import { SpeedTest } from "@Pages/SpeedTest/SpeedTest";
import { PrivateRoute, UnAuthorisedRoute } from "@Src/utils/service/Authenticated-Routes";
import { TestResultIndex } from "@Src/features/Dashboard/TestResult/Index";
import { Contactus } from "@Pages/Contactus/Contactus";
import { SearchList } from "@Pages/SearchList";
import { TestListing } from "@Pages/TestListing";

export const AppRoutes = () => {
  return (

    <Routes>
      {/* add all routes */}

      <Route path="/" element={<Navigate to={AppRouteURL.HOME} />} />
      <Route
        path={AppRouteURL.HOME}
        element={
          <React.Suspense fallback={<Loader />}>
            <Home />
          </React.Suspense>
        }
      />
      <Route
        path={AppRouteURL.AUTH}
        element={
          <React.Suspense fallback={<Loader />}>
            <AppRouteURLwithChilds.AUTH.component />
            {/* <UnAuthorisedRoute component={AppRouteURLwithChilds.AUTH.component} /> */}
          </React.Suspense>
        }
      >
        <Route path={AppRouteURL.AUTH} element={<Navigate to={AppRouteURL.AUTH + AppRouteURLwithChilds.AUTH.childrens.LOGIN.path} />} />
        <Route index
          path={AppRouteURLwithChilds.AUTH.childrens.LOGIN.path}
          element={
            <React.Suspense fallback={<Loader />}>
              {/* <AppRouteURLwithChilds.AUTH.childrens.LOGIN.component /> */}
              <UnAuthorisedRoute component={AppRouteURLwithChilds.AUTH.childrens.LOGIN.component} />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURLwithChilds.AUTH.childrens.LOGOUT.path}
          element={
            <React.Suspense fallback={<Loader />}>
              {/* <AppRouteURLwithChilds.AUTH.childrens.LOGOUT.component /> */}
              <UnAuthorisedRoute component={AppRouteURLwithChilds.AUTH.childrens.LOGOUT.component} />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURLwithChilds.AUTH.childrens.REGISTER.path}
          element={
            <React.Suspense fallback={<Loader />}>
              <AppRouteURLwithChilds.AUTH.childrens.REGISTER.component />
              <UnAuthorisedRoute component={AppRouteURLwithChilds.AUTH.childrens.LOGOUT.component} />
            </React.Suspense>
          }
        />
         <Route
          path={AppRouteURLwithChilds.AUTH.childrens.UPDATE_MOBILE.path}
          element={
            <React.Suspense fallback={<Loader />}>
              <AppRouteURLwithChilds.AUTH.childrens.UPDATE_MOBILE.component />
              <UnAuthorisedRoute component={AppRouteURLwithChilds.AUTH.childrens.LOGOUT.component} />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURLwithChilds.AUTH.childrens.VERIFY_OTP.path}
          element={
            <React.Suspense fallback={<Loader />}>
              {/* <AppRouteURLwithChilds.AUTH.childrens.VERIFY_OTP.component /> */}
              <UnAuthorisedRoute component={AppRouteURLwithChilds.AUTH.childrens.VERIFY_OTP.component} />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURLwithChilds.AUTH.childrens.EXAM_PREFRENCES.path}
          element={
            <React.Suspense fallback={<Loader />}>
              {/* <AppRouteURLwithChilds.AUTH.childrens.EXAM_PREFRENCES.component /> */}
              <UnAuthorisedRoute component={AppRouteURLwithChilds.AUTH.childrens.EXAM_PREFRENCES.component} />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURLwithChilds.AUTH.childrens.FORGOT_PASSWORD.path}
          element={
            <React.Suspense fallback={<Loader />}>
              <AppRouteURLwithChilds.AUTH.childrens.FORGOT_PASSWORD.component />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURLwithChilds.AUTH.childrens.RESET_PASSWORD.path}
          element={
            <React.Suspense fallback={<Loader />}>
              <AppRouteURLwithChilds.AUTH.childrens.RESET_PASSWORD.component />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURLwithChilds.AUTH.childrens.VERIFY_EMAIL.path}
          element={
            <React.Suspense fallback={<Loader />}>
              <AppRouteURLwithChilds.AUTH.childrens.VERIFY_EMAIL.component />
            </React.Suspense>
          }
        />
      </Route>
      <Route
        path={AppRouteURL.USERPROFILE}
        element={
          <React.Suspense fallback={<Loader />}>
            <PrivateRoute component={UserProfile} />
          </React.Suspense>
        }
      >
        <Route
          path={AppRouteURL.MYPROFILE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivateRoute component={MyProfileIndex} />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURL.CHANGEPASS}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivateRoute component={ChangePasswordIndex} />
            </React.Suspense>
          }
        />

        <Route
          path={AppRouteURL.COURSEPREFERD}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivateRoute component={CoursePreferenceIndex} />
            </React.Suspense>
          }
        />
      </Route>
      <Route
        path={AppRouteURL.DASHBOARD}
        element={
          <React.Suspense fallback={<Loader />}>
            <PrivateRoute component={Dashboard} />
          </React.Suspense>
        }
      >
        <Route
          path={AppRouteURL.DASHBOARD_HOME}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivateRoute component={DashboardpageIndex} />
            </React.Suspense>
          }
        />
        <Route
          path={`${AppRouteURL.MOCKTEST}/:testId`}
          element={
            <React.Suspense fallback={<Loader />}>
                <PrivateRoute component={MockTestIndex} />
            </React.Suspense>
          }
        >
        </Route>
        <Route
          path={`${AppRouteURL.MOCKTEST_SERIES}/:examId`}
          element={
            <React.Suspense fallback={<Loader />}>
                <PrivateRoute component={MockTestIndex} />
            </React.Suspense>
          }
        >
        </Route>
        <Route
          path={`${AppRouteURL.LEADEBOARD}/:LeaderBoardId`}
          element={
            <React.Suspense fallback={<Loader />}>
                <PrivateRoute component={LeaderBoardIndex} />
            </React.Suspense>
          }
        />

        <Route
          path={`${AppRouteURL.TESTRESULT}/:TestResultsId`}
          element={
            <React.Suspense fallback={<Loader />}>
            <TestResultIndex />
            </React.Suspense>
          }
        />

        <Route
          path={AppRouteURL.MYBADGES}
          element={
            <React.Suspense fallback={<Loader />}>
            <PrivateRoute component={MyBadgesIndex} />
            </React.Suspense>
          }
        />
      </Route>
        <Route path={AppRouteURL.CONTACT} element={<React.Suspense fallback={<Loader />} >
       <Contactus/>
      </React.Suspense>}  />
      <Route path={`${DetailsRouteURL.CLASSES_DETAILS}/:className/:classId`} element={<React.Suspense fallback={<Loader />} >
      <ClassesDetails />
      </React.Suspense>}>
      </Route>
      <Route path={`${DetailsRouteURL.TEST_DETAILS}/:testId`} element={<React.Suspense fallback={<Loader />} >
      <TestDetails />
      </React.Suspense>}>
      </Route>
      {/* <Route path={`${DetailsRouteURL.CLASSES_DETAILS}/:classId${DetailsRouteURL.SUBJECT_DETAILS}/:subjectId`} element={<React.Suspense fallback={<Loader />} >
      <TestDetails />
      </React.Suspense>}>
      </Route> */}
      <Route path={`${DetailsRouteURL.SUBJECT_DETAILS}/:subjectName/:subjectId`} element={<React.Suspense fallback={<Loader />} >
      <SubjectDetails />
      </React.Suspense>}>
      </Route>
      <Route path={`${TestSereisRouteURL.TEST_SERIES}`} element={<React.Suspense fallback={<Loader />} >
      <TestListing />
      </React.Suspense>}>
      </Route>
      <Route path={`${TestSereisRouteURL.TEST_SERIES}/all`} element={<React.Suspense fallback={<Loader />} >
      <TestSeriesDetails />
      </React.Suspense>}>
      </Route>
      <Route path={`${TestSereisRouteURL.TEST_SERIES}/:testSeriesName/:testSeriesId`} element={<React.Suspense fallback={<Loader />} >
      <TestListing />
      </React.Suspense>}>
      </Route>
      <Route path={`${DetailsRouteURL.CLASSES_DETAILS}`} element={<React.Suspense fallback={<Loader />} >
      <TestListing />
      </React.Suspense>}>
      </Route>
      <Route path={`${TestRouteURL.TEST}/:testId`} element={<React.Suspense fallback={<Loader />} >
      <PrivateRoute component={Test} />
      </React.Suspense>}>
      </Route>
      <Route path={`${TestRouteURL.TEST_SERIES}/:examId`} element={<React.Suspense fallback={<Loader />} >
      <PrivateRoute component={Test} />
      </React.Suspense>}>
      </Route>
      <Route path={`${TestSereisRouteURL.TEST_SERIES_DETAIL}/:examId`} element={<React.Suspense fallback={<Loader />} >
      <TestDetails />
      </React.Suspense>}>
      </Route>
      <Route
        path={`${TestRouteURL.SPEED_TEST}/:testId`}
        element={
          <React.Suspense fallback={<Loader />}>
             <PrivateRoute component={SpeedTest} />
          </React.Suspense>
        }
      >
      </Route>
      <Route
        path={`${TestRouteURL.SPEED_TEST_SERIES}/:examId`}
        element={
          <React.Suspense fallback={<Loader />}>
             <PrivateRoute component={SpeedTest} />
          </React.Suspense>
        }
      >
      </Route>
      <Route path={`${DetailsRouteURL.SEARCH}`} element={<React.Suspense fallback={<Loader />} >
      <TestListing />
      </React.Suspense>}>
      </Route>
      <Route path={`/:className/:classId/:subjectName/:subjectId`} element={<React.Suspense fallback={<Loader />} >
      <TestListing />
      </React.Suspense>}>
      </Route>
    </Routes>

  );
};
