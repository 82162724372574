import * as CryptoJSLib from "crypto-js";
class CryptoJs {
    secretKey = (process.env.REACT_APP_HASHCODE as string);

    encrypt(data: string) {
        var ciphertext = CryptoJSLib.AES.encrypt(data, this.secretKey).toString();
        return ciphertext;
    }

    decrypt(ciphertext: string) {
        var bytes  = CryptoJSLib.AES.decrypt(ciphertext, this.secretKey);
        var originalText = bytes.toString(CryptoJSLib.enc.Utf8);
        return originalText;
    }
}

export default CryptoJs;