import ApiService from "@Src/services/ApiService";
import { MOCKTEST_API_ROUTES } from "./MockTest.api.constant";

export async function getQuestion(params:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.QUESTION,
        method: 'get',
        params: params
    })
}

export async function postQuestion(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.QUESTION,
        method: 'put',
        data
    })
}

export async function postSubmitQuestion(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.SUBMIT,
        method: 'post',
        data
    })
}
export async function postResumeTest(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.RESUME,
        method: 'post',
        data
    })
}

export async function postPauseTest(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.PAUSE,
        method: 'post',
        data
    })
}

export async function postFinalSubmitTest(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.FINAL_SUBMIT,
        method: 'post',
        data
    })
}

export async function postTimerTest(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.TIMER,
        method: 'post',
        data
    })
}

export async function postSpeedTestQuestion(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.SPEED_TEST_QUESTION,
        method: 'put',
        data
    })
}

export async function postSpeedTestSubmitQuestion(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.SPEED_TEST_SUBMIT_QUESTION,
        method: 'post',
        data
    })
}

export async function getSpeedTestQuestions(params:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.SPEED_TEST_QUESTION,
        method: 'get',
        params: params
    })
}

export async function postTestSeriesQuestion(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.EXAM_QUESTION,
        method: 'put',
        data
    })
}

export async function getTestSeriesQuestion(params:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.EXAM_QUESTION,
        method: 'get',
        params: params
    })
}

export async function postSubmitTestSeriesQuestion(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.SUBMIT_TEST_SERIES_QUESTION,
        method: 'post',
        data
    })
}

export async function postSpeedTestSeriesQuestion(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.EXAM_SPEED_TEST_SERIES_QUESTION,
        method: 'put',
        data
    })
}

export async function postSpeedTestSeriesSubmitQuestion(data:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.EXAM_SPEED_TEST_SERIES_SUBMIT_QUESTION,
        method: 'post',
        data
    })
}

export async function getSpeedTestSeriesQuestions(params:any){
    return await ApiService.fetchData({
        url: MOCKTEST_API_ROUTES.TEST + '/' + MOCKTEST_API_ROUTES.EXAM_SPEED_TEST_SERIES_QUESTION,
        method: 'get',
        params: params
    })
}
