import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { StopWatchActions } from '@Constants/app.constant';

export const StopWatch=forwardRef(({ action, onActionChange, actionStopWatch, askNextSpeedTestQuestionHandler,indexInNeed}: any, ref:any)=> {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: true });
    useEffect(() => {
        if (action == StopWatchActions.RESET) {
            onActionChange(seconds + (minutes * 60) + (hours * 3600));
            askNextSpeedTestQuestionHandler(indexInNeed+1)
            reset();
        }
    }, [action])

    useEffect(() => {
        if (actionStopWatch == StopWatchActions.START) {
            start();
        }
        if(actionStopWatch == StopWatchActions.PAUSE){
            pause();
        }
    }, [actionStopWatch])

useImperativeHandle(ref, () => ({
     getStopWatchTimeHandler(){    
        let time  = seconds + (minutes * 60) + (hours * 3600);
        onActionChange(time);
        askNextSpeedTestQuestionHandler(indexInNeed+1)
        reset();
        return time;
  }
}));

    return (
        <>
        </>
    );
})
