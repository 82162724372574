
import "./TestListing.scss";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import HomeLayout from "../layout/components/HomeLayout/HomeLayout";
import DetailsPageBanner from "@Components/DetailsPageBanner/DetailsPageBanner";
import { SubscriptionUpdates } from "@Components/SubscriptionUpdates/SubscriptionUpdates";
import { getSearchFilters, getTestList, getTestSeriesList, getTestSeriesSearchFilters } from "../TestDetails/services/TestDetails.service";
import MockTests from "./Components/MockTests/MockTests";
import { FilterIndex } from "../Filter/Filter";
import { FILTER_KEYS } from "../Filter/Constants/filters.constants";
import { TestSereisRouteURL } from "@Constants/app-route-url.constant";
import { HeaderNameKeys } from "@Constants/app.constant";

export const TestListingIndex = () => {
    const params = useParams();
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();
    const [isTestSeries, setIsTestSeries] = useState<boolean>(false);
    let classId = params.classId || searchParams.get("classId"); 
    let className = params.className! || searchParams.get("className")!;
    let subjectId = params.subjectId || searchParams.get("subjectId");
    let subjectName = params.subjectName! || searchParams.get("subjectName")!; 
    let testSeriesId = params.testSeriesId || searchParams.get("testseriesId"); 
    let testSeriesName = params.testSeriesName! || searchParams.get("testseriesName")!;
    const testId = searchParams.get("testId");
    const chapterId = searchParams.get("chapterId");
    const topicId = searchParams.get("topicId");
    const testName = searchParams.get("testName");
    const chapterName = searchParams.get("chapterName");
    const topicName = searchParams.get("topicName");
    const boardId = searchParams.get("boardId");
    const boardName = searchParams.get("boardName");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [testDeatils, setTestDeatils] = useState<Array<any>>([]);
    const [filters, setFilters] = useState<Array<any>>([]);
    const [initialFilters, setInitialFilters] = useState<Array<any>>([]);
    const [headingsBreadcrumb, setHeadingsBreadcrumb] = useState<Array<any>>([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        let param:any = {
        }
        let initialFilters:any = [
        ]
        if(classId || subjectId || testId || chapterId || testSeriesId){}else{
        if(location.pathname == TestSereisRouteURL.TEST_SERIES){
            setIsTestSeries(true);
        setInitialFilters(initialFilters);
        getAllTestSeriesSearchFilters(param)
        getAllTestSeriesList(param );
    }else{
        setIsTestSeries(false);
        setInitialFilters(initialFilters);
        getAllSearchFilters(param)
        getAllTestList(param );  
    }
}
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        let Headings = []; 
        if(classId || subjectId || testId){
            setIsTestSeries(false);
        let param:any = {
            [FILTER_KEYS.SUBJECT] : subjectId,
            [FILTER_KEYS.CLASS]:  classId,
            [FILTER_KEYS.TEST]:testId,
           // "chapterId":[Number(chapterId)],
           // "topicId": [Number(topicId)]      
        }
        let initialFilters:any = [
            { [FILTER_KEYS.SUBJECT]:{
                 Id: Number(subjectId),
                 Name: subjectName
             }},
            { [FILTER_KEYS.CLASS]: {
                 Id: Number(classId),
                 Name: className
             }},
                     ] 
        if(chapterId){
            param[FILTER_KEYS.CHAPTER] = [Number(chapterId)];
            let chapterData =  { [FILTER_KEYS.CHAPTER]:[{
                Id: Number(chapterId),
                Name: chapterName
            }]}
            initialFilters.push(chapterData)
        }
        if(topicId){
            param[FILTER_KEYS.TOPIC] = [Number(topicId)];
            let topicData =  { [FILTER_KEYS.TOPIC]: [{
                Id: Number(topicId),
                Name: topicName
            }]}
            initialFilters.push(topicData)
        }
        if(boardId){
            param[FILTER_KEYS.BOARD] = boardId;
            let boardData =  { [FILTER_KEYS.BOARD]: {
                Id: Number(boardId),
                Name: boardName
            }}
            initialFilters.push(boardData)
        }
        setInitialFilters(initialFilters);
        getAllSearchFilters(param)
        getAllTestList(param );
        Headings =[
            {name:className, type:HeaderNameKeys.CLASS, id:classId},
            {name:subjectName, type:HeaderNameKeys.SUBJECT, id:subjectId},
            {name:testSeriesName, type:HeaderNameKeys.TEST_SERIES, id:testSeriesId}
        ];    
        setHeadingsBreadcrumb(Headings)
    }
    }, [classId,subjectId,testId,chapterId]);

    useEffect(() => {
        window.scrollTo(0, 0);
        let Headings = [];
        if(testSeriesId){
        setIsTestSeries(true);
        let param:any = {
            [FILTER_KEYS.TEST_SERIES] : testSeriesId,
        }
        let initialFilters:any = [
            { [FILTER_KEYS.TEST_SERIES]:{
                 Id: Number(testSeriesId),
                 Name: testSeriesName
             }},
        ] 
        setInitialFilters(initialFilters);
        getAllTestSeriesSearchFilters(param)
        getAllTestSeriesList(param );
        Headings =  [
            {name:'Test Series', type:HeaderNameKeys.ALL_TEST_SERIES},
            {name:testSeriesName, type:HeaderNameKeys.TEST_SERIES, id:testSeriesId}
        ]    
        setHeadingsBreadcrumb(Headings)
    }
    }, [testSeriesId]);

    function getAllTestList( param: any) {
        setIsLoading(true)
        getTestList(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setTestDeatils(data.result);
                setIsLoading(false);
            }
        })
    }

    function getAllSearchFilters(param:any) {
        setIsLoading(true)
        getSearchFilters(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setFilters(data);
                setIsLoading(false);
            }
        })
    }

    function getAllTestSeriesList(param: any ) {
        setIsLoading(true)
        getTestSeriesList(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setTestDeatils(data.result);
                setIsLoading(false);
            }
        })
    }

    function getAllTestSeriesSearchFilters(param:any) {
        setIsLoading(true)
        getTestSeriesSearchFilters(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setFilters(data);
                setIsLoading(false);
            }
        })
    }
    const submitFilters = (filters:any) =>{
        let newFilters:any = {...filters};
                // if(isTestSeries){
                   // pass  source as array intead of string irrespective of the dropdown 
            if(newFilters[FILTER_KEYS.SOURCE] && newFilters[FILTER_KEYS.SOURCE].Id  ){
                newFilters[FILTER_KEYS.SOURCE] = [newFilters[FILTER_KEYS.SOURCE]];
            }
        // }
        Object.keys(newFilters).map((filter:any) => {
            newFilters[filter] = newFilters[filter] != undefined || newFilters[filter] != null ? newFilters[filter] : delete newFilters[filter];
        });
        getHeadingFromSubmitFilters(filters);
     let params = modifiedFilters(newFilters);
     if(isTestSeries){
        getAllTestSeriesSearchFilters(params)
        getAllTestSeriesList(params );
     }else{
     getAllSearchFilters(params);
     getAllTestList(params )
     }
    }

   const getHeadingFromSubmitFilters = (filters:any)=>{
    let updatedBreadrumb: any = [];
         if(filters[FILTER_KEYS.CLASS] && filters[FILTER_KEYS.CLASS].Id ){
            let dataToPush = {
                type: HeaderNameKeys.CLASS, 
                name:filters[FILTER_KEYS.CLASS].Name
            }
            updatedBreadrumb.push(dataToPush);
         }
         if(filters[FILTER_KEYS.SUBJECT] && filters[FILTER_KEYS.SUBJECT].Id ){
            let dataToPush = {
                type: HeaderNameKeys.SUBJECT, 
                name:filters[FILTER_KEYS.SUBJECT].Name
            }
            updatedBreadrumb.push(dataToPush);
         }
         if(filters[FILTER_KEYS.TEST_SERIES] && filters[FILTER_KEYS.TEST_SERIES].Id ){
            let dataToPush = {
                type: HeaderNameKeys.ALL_TEST_SERIES, 
                name:'Test Series'
            }
            updatedBreadrumb.push(dataToPush);
             dataToPush = {
                type: HeaderNameKeys.TEST_SERIES, 
                name:filters[FILTER_KEYS.TEST_SERIES].Name
            }
            updatedBreadrumb.push(dataToPush);
         }
         setHeadingsBreadcrumb(updatedBreadrumb);
   }

    const modifiedFilters = (filters:any) =>{
          let payload:any={};
          let keys = Object.keys(filters);
          keys.forEach((key)=>{
            if(typeof(filters[key]) == 'object'){
            if (filters[key].length) {
                payload[key] = filters[key].map((filter:any)=>{
                   return filter.Id
                })
            }else{
                payload[key] = filters[key].Id && filters[key].Id.toString()
            }
            }
          });
          return payload
    }
    return (
        <>
            <HomeLayout >
                {/* <!-----------------------------------test-listining-section-------------------------------> */}
                <div className="test-listining">
                    <DetailsPageBanner Headings={headingsBreadcrumb} />
                {filters ?   <FilterIndex filters={filters} submitFilters={submitFilters}  initialFilters={initialFilters} isTestSeries={isTestSeries} /> : ""}   
                    <MockTests testList={testDeatils} classId={classId} subject={subjectName} isTestSeries={isTestSeries} />
                    <SubscriptionUpdates />
                </div>

                {/* <!----------------------------------end-test-listining-section------------------------------->  */}
            </HomeLayout>

        </>
    );
}