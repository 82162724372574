import "../UserProfile.scss";
import { getBoardlist, getClassList, getSubjectList, getEducationalInterest, getCouresePreferences, UpdateCoursePrefernces } from "../Services/UserProfile.sevice";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { useEffect, useState } from "react";
import { SelectButton } from 'primereact/selectbutton';
import { useFormik } from "formik";
import { EditCoursePrefernces } from "../Schema/UserProfile.schema";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from 'primereact/multiselect';
import { UpdateCoursePreferncesIntere } from "../Interfaces/Userprofile.interface";
import { toast } from "react-hot-toast";
import * as Yup from 'yup';


export const EditCoursesIndex = ({ setIsEdit, courseprefernces }: any) => {
  const [boardlist, setBoardlist] = useState<Array<any>>([])
  const [classList, setclassList] = useState<Array<any>>([])
  const [subjectList, setsubjectList] = useState<Array<any>>([])
  const [eductinterest, seteductinterest] = useState<Array<any>>([])
  const [selectedClassName, setSelectedClassName] = useState<Array<any>>([])

  const CoursePreference = Yup.object({
    classlistId: Yup.string().required("Please select class"),
    boardListId: Yup.string().required("Please select board"),
    subjectListId: Yup.array().min(1, 'Please select atleast one subject').required('Please select subjects'),
  })


  useEffect(() => {
    //   getcoursedata();
    setcoursepreferncedata(courseprefernces);
    Boardlistdata()
    ClassListdata()
    EducationalInterest()
  }, [])




  const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setValues } = useFormik({
    initialValues: EditCoursePrefernces,
    validationSchema: CoursePreference,

    onSubmit: (e) => {

      updatecourseprefer(e as any)


    }
  })
  useEffect(() => {
    if (values.classlistId != '' && values.boardListId != '') {
      subjectlistdata();
    }
  }, [values.classlistId, values.boardListId])

  useEffect(() => {
    if (values.boardListId != '') {
      ClassListdata();
    }
  }, [values.boardListId])

 
  // -------------------getting-existing-course-details-api-call-------------------  
  const getcoursedata = () => {
    getCouresePreferences().then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        setcoursepreferncedata(response?.data)
      }
    })
  }

  const setcoursepreferncedata = (data: any) => {
    let subjectIds:any;
    if(data?.['subjectDetails'] && data?.['subjectDetails'].length){
     subjectIds = data?.['subjectDetails'].map((index:any)=> {return index.Id});
    }
    setValues({
      classlistId: data?.['classDetails'] && data?.['classDetails'].length && data?.['classDetails'][0]['Id'],
      boardListId: data?.['boardDetails'] && data?.['boardDetails'].length && data?.['boardDetails'][0]['Id'],
      subjectListId:  subjectIds,
      educationInterestListId:  data?.['educationInterestDetails'] &&  data?.['educationInterestDetails'][0] && data?.['educationInterestDetails'][0]['Id'] 
    })
  }

  // -------------------classlist-api-call-------------------
  const ClassListdata = () => {
    let params = {
      boardId: values.boardListId
    }
    getClassList(params).then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        setclassList(response?.data)
        let classId = Number(courseprefernces?.['classDetails'][0]['Id'],)
        onChangeClass(classId, response?.data)
      }
    }).catch((error: any) => {
      console.log(error)
    })

  }

  // -------------------Boardlist-api-call-------------------
  const Boardlistdata = () => {
    
     
     getBoardlist().then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        setBoardlist(response?.data)

      }
    }).catch((error: any) => {
      console.log(error)
    })

  }

  // -------------------subjectlist-api-call-------------------

  const subjectlistdata = () => {

    // values.subjectListId = []
    let params = {
      classId: values.classlistId,
      boardId: values.boardListId
    }
    getSubjectList(params).then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        setsubjectList(response?.data)
      }
    }).catch((error: any) => {
      console.log(error)
    })
  }

  // -------------------EducationalInterest-api-call-------------------
  const EducationalInterest = () => {
    getEducationalInterest().then((response: APIResponse) => {
      if (response.ResponseType === APIStatus.SUCCESS) {
        seteductinterest(response?.data)
      }
    }).catch((error: any) => {
      console.log(error)
    })
  }

  // -------------------sending-updated-course-prefernces-to-api-------------------
  const updatecourseprefer = (updatedata: UpdateCoursePreferncesIntere) => {
    const courseupdate: UpdateCoursePreferncesIntere = {
      "classlistId": [updatedata.classlistId],
      "boardListId": [updatedata.boardListId],
      "subjectListId": updatedata.subjectListId,
      "educationInterestListId": updatedata.educationInterestListId > 0 ? [updatedata.educationInterestListId] : []
    }
    if (touched && isValid) {
      toast.promise(UpdateCoursePrefernces(courseupdate), {
        loading: "Updating user details...",
        success: <p>user details updated successfully!</p>,
        error: <p>user details Could not update. Please try again.</p>,
      }).then((response: APIResponse) => {
        setIsEdit(false)
      })
    }


  }

  const onChangeClass = (value: number, data?: any) => {
    let dataSelection: any;
    if (data && data.length) {
      dataSelection = data.find((element: any) => element.Id == value);

    } else {
      dataSelection = classList.find((element: any) => element.Id == value);
    }
    if (values.classlistId) {
      if (value == values.classlistId) {

      } else {
        setFieldValue('educationInterestListId', 0);
        setFieldValue('subjectListId', [])
      }
    }
    setSelectedClassName(dataSelection.Name);
    //subjectlistdata()
  }


  const onChangeBoard = (e: any) => {
    if (values.boardListId) {
      if (e.value == values.boardListId) {

      } else {
        setFieldValue('educationInterestListId', 0);
        setFieldValue('subjectListId', [])
        setFieldValue('classlistId', '');
      }
    }
    //subjectlistdata()
  }


  return (
    <>
      <div className="profile-input-card">
        <form onSubmit={handleSubmit}>
          <div>
            <div className="input-form-card">
              <div className="form-label-log">Select Board</div>
              <Dropdown
                className="prime-deopdown"
                placeholder="Please select your Board"
                value={values.boardListId}
                name="boardListId"
                onChange={(e) => { handleChange(e), onChangeBoard(e) }}
                options={boardlist}
                optionLabel="Name" optionValue="Id"
              />
              {errors.boardListId && touched.boardListId ?
                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.boardListId}</p> : null}
            </div>
            <div className="input-form-card">
              <div className="form-label-log">Select Class</div>

              <Dropdown value={values.classlistId}
                className="prime-deopdown"
                name="classlistId"
                onChange={(e) => { handleChange(e), onChangeClass(e.value) }}
                onBlur={handleBlur} options={classList} optionLabel="Name" optionValue="Id"
                placeholder="Please select your class"
                disabled={values.boardListId? false : true} />

              {errors.classlistId && touched.classlistId ?
                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.classlistId}</p> : null}
            </div>

            {(selectedClassName.includes('11') || selectedClassName.includes('12')) ?
              <div className="input-form-card">
                <div className="form-label-log">
                  Select your Education Interest (For students of 11th and 12th)
                </div>

                <div className="course-prefernce-selectbutton">
                  <SelectButton
                    value={values.educationInterestListId}
                    name="educationInterestListId"
                    onChange={(e) => { handleChange(e) }}
                    options={eductinterest}
                    optionLabel="Name"
                    optionValue="Id"
                  />
                </div>
              </div>
              : ''}

            <div className="input-form-card">
              <div className="form-label-log">Select Subject</div>


              <MultiSelect
                value={values.subjectListId}
                className="prime-deopdown"
                name="subjectListId"
                onChange={handleChange}
                options={subjectList}
                optionLabel="Name"
                optionValue="Id"
                placeholder="Please select your Subject"
                showSelectAll={true} 
                disabled={values.classlistId? false : true} />

              {errors.subjectListId && touched.subjectListId ?
                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.subjectListId}</p> : null}
            </div>


          </div>
          <div className="tw-text-center tw-mt-9 ">
            <button type="submit" className="user-profile-btn ">Save</button>

          </div>

        </form>


      </div>
    </>
  );
};