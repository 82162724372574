import { Dialog } from "primereact/dialog"
import colourPauseSvg from "@Assets/svg/Dashboard-svgs/colourPause.svg";

export const PauseTestDialog = ({Pauesedialog,setPauesedialogHandler, pauseTestHandler, resumeTestHandler}:any) => {
    return (
        <>
              <Dialog  className="mock-test-dialog-box" position="top" visible={Pauesedialog} onHide={() => setPauesedialogHandler(false)} closeOnEscape={false} >
        <div className="pause-dialog-box">
          <div className="p-dlg-heading">Pause Test?</div>
          <span className="p-dilg-para">Do you really want to end the test?</span>

          <div className="tw-mt-7 tw-flex">
            <button className="dialog-end-test-btn" onClick={() => pauseTestHandler()} ><img src={colourPauseSvg} alt="colourPauseSvg" /> Pause Test</button>
            <button className="resume-test-btn" onClick={() => resumeTestHandler ()}>Resume Test</button>
          </div>
        </div>
      </Dialog></>
    )
}