export const MOCKTEST_API_ROUTES = {
   TEST: 'test',
   QUESTION :'question',
   SUBMIT: 'submitQuestion',
   PAUSE: 'testPause',
   RESUME: 'testResume',
   FINAL_SUBMIT: 'finalSubmitQuestion',
   TIMER: 'timeEvent',
   SPEED_TEST_QUESTION : 'speedTestQuestion',
   SPEED_TEST_SUBMIT_QUESTION : 'speedTestSubmitQuestion',
   EXAM_QUESTION:"examQuestion",
   SUBMIT_TEST_SERIES_QUESTION: "examSubmitQuestion",
   EXAM_SPEED_TEST_SERIES_QUESTION: "examSpeedTestQuestion",
   EXAM_SPEED_TEST_SERIES_SUBMIT_QUESTION :"examSpeedTestSubmitQuestion"
}