import { Dialog } from "primereact/dialog"
import "./QuestionsExhaustDialog.scss"

export const QuestionsExhaustDialog = ({ questionsExhaustDialog, endTestHandler }: any) => {
  return (
    <>      <Dialog position="top" className="custom-expire-dialog" visible={questionsExhaustDialog}  onHide={() => endTestHandler()} closeOnEscape={false} >
      <div className="Expire-time-box">
        <div className="ex-t-head">No More Questions Are Available. Please try later.</div>
        <div>
          <button className="dialog-end-test-btn"
            onClick={() => endTestHandler()} >End Test</button>
        </div>
      </div>
    </Dialog>
    </>
  )
}