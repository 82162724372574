import { TestSereisRouteURL } from '@Constants/app-route-url.constant';
import { Link } from 'react-router-dom';
import './MockTestSeries.scss';
export const MockTestSeries = ({ mockTestSeriesList }: any) => {
    return (
        <section className="most-popular-series-container ">
        <div className="mock-test-listing-header">
        Mock Test Series
        </div>
        <div  className="most-popular-cards-container">
            {/* { mockTestSeriesList.map((element:any)=>{
            return (<div key={element.Id} className="othr-test-card">
                <div className="o-t-c-header">
                    <img src="https://i.postimg.cc/L5BX8kMX/image-1.png" alt="ssc-cgl" />
                    <div className="o-t-c-heading">{element.Name}</div>
                </div>
                <div className="question-paper">
                    <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                        <span className="qust-papr-head">Questions</span>
                        <span className="duration-marks-card">{element.NoOfQuestion}</span>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <span className="qust-papr-head">Max Marks</span>
                        <span className="duration-marks-card">{element.mask}</span>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                        <span className="qust-papr-head">Time</span>
                        <span className="duration-marks-card">{element.Duration} mins</span>
                    </div>
                </div>
                <div className="stnd-cls-card-btn tw-my-4">
                <Link to={`${DetailsRouteURL.TEST_SERIES_DETAILS}/${element.Name}/${element.Id}`} >
                    <button className="take-test-btn">Take Test</button>
                    </Link>
                </div>
            </div>)
            })
} */}
                    {
                        mockTestSeriesList.map((testSeries: any) => {
                            return (
                                <div key={testSeries.Id || testSeries.TestMasterId} className='subject-card'>
                                    <img src={testSeries.Image}  className='subjct-img' onError={(e:any) => {e.target.src = "../../../noimage.png"}} />
                                    <div className="s-c-right">
                                        <div className="s-c-r-head">{testSeries.Name || testSeries.TestMasterName}</div>
                                        <div className="s-c-r-para">{testSeries.Description}</div>
                                        <div className="stnd-cls-card-btn">
                                        <Link to={`${TestSereisRouteURL.TEST_SERIES}/${testSeries.Name || testSeries.TestMasterName}/${testSeries.Id || testSeries.TestMasterId}`} >
                                                <button className="take-test-btn">View Test</button>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }

            {/* <div className="othr-test-card">
                <div className="o-t-c-header">
                    <img src="https://i.postimg.cc/L5BX8kMX/image-1.png" alt="ssc-cgl" />
                    <div className="o-t-c-heading">SSC MTS Tier 1 Mock 1</div>
                    <div className="o-t-c-expires-date">Expires on 29 Sep 2023</div>
                </div>
                <div className="question-paper">
                    <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                        <span className="qust-papr-head">Questions</span>
                        <span className="duration-marks-card">90</span>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <span className="qust-papr-head">Max Marks</span>
                        <span className="duration-marks-card">270</span>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                        <span className="qust-papr-head">Time</span>
                        <span className="duration-marks-card">90 mins</span>
                    </div>
                </div>
                <div className="stnd-cls-card-btn tw-my-4">
                    <button className="take-test-btn">Take Test</button>
                </div>
            </div>
            <div className="othr-test-card">
                <div className="o-t-c-header">
                    <img src="https://i.postimg.cc/L5BX8kMX/image-1.png" alt="ssc-cgl" />
                    <div className="o-t-c-heading">SSC MTS Tier 1 Mock 1</div>
                    <div className="o-t-c-expires-date">Expires on 29 Sep 2023</div>
                </div>
                <div className="question-paper">
                    <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                        <span className="qust-papr-head">Questions</span>
                        <span className="duration-marks-card">90</span>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <span className="qust-papr-head">Max Marks</span>
                        <span className="duration-marks-card">270</span>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                        <span className="qust-papr-head">Time</span>
                        <span className="duration-marks-card">90 mins</span>
                    </div>
                </div>
                <div className="stnd-cls-card-btn tw-my-4">
                    <button className="take-test-btn">Take Test</button>
                </div>
            </div>
            <div className="othr-test-card">
                <div className="o-t-c-header">
                    <img src="https://i.postimg.cc/L5BX8kMX/image-1.png" alt="ssc-cgl" />
                    <div className="o-t-c-heading">SSC MTS Tier 1 Mock 1</div>
                    <div className="o-t-c-expires-date">Expires on 29 Sep 2023</div>
                </div>
                <div className="question-paper">
                    <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                        <span className="qust-papr-head">Questions</span>
                        <span className="duration-marks-card">90</span>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <span className="qust-papr-head">Max Marks</span>
                        <span className="duration-marks-card">270</span>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-between tw-my-3">
                        <span className="qust-papr-head">Time</span>
                        <span className="duration-marks-card">90 mins</span>
                    </div>
                </div>
                <div className="stnd-cls-card-btn tw-my-4">
                    <button className="take-test-btn">Take Test</button>
                </div>
            </div> */}
        </div>
    </section>
    )
}