import "../UserProfile.scss";
import { UpdatePassword } from "../Services/UserProfile.sevice";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { FormikState, useFormik } from "formik";
import { UpdatePasswordInitialvalue, UpdatePasswordSchema } from "../Schema/UserProfile.schema";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { UpdatePasswordInterface } from "../Interfaces/Userprofile.interface";
import { useNavigate } from "react-router-dom";
import { AppRouteURL, AppRouteURLwithChilds } from "@Constants/app-route-url.constant";


export const ChangePasswordIndex = () => {
 const Navigation = useNavigate();

  // useEffect(() => {
  //   updatepass(e)
  // }, [])

  const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setValues,resetForm  } = useFormik({
    initialValues: UpdatePasswordInitialvalue,
    validationSchema: UpdatePasswordSchema,

    onSubmit: (e, {resetForm} ) => {

      updatepass(e, resetForm)


    }
  })

  let content = ""
  const updatepass = (e: UpdatePasswordInterface,resetForm: (nextState?: Partial<FormikState<{ oldPassword: string; newPassword: string; re_newPassword: string; }>> | undefined) => void) => {
    const passwordobj = {
      'oldPassword':e.oldPassword,
      'newPassword':e.newPassword
    }
    UpdatePassword(passwordobj).then((response: APIResponse) => {
    //  if(response.ResponseType === "PASSWORD_NOT_MATCHED"){
        if(response.ResponseType === APIStatus.SUCCESS){
        resetForm()
        toast.success(response.StatusMsg)
      }
      else {
        toast.error(response.StatusMsg)
      }

    })
 
  }


  return (
    <>
      <div className="user-profile-right-container ">
        <div className="user-act-header">
          <div className="usr-prf-heading">Change Password</div>
          <div className="user-prf-para tw-mt-2">
            We recommend that you set a strong password that is at least 8{" "}
            <br /> characters long and includes a mix of letter, number and
            special character
          </div>
        </div>
        <p>{content}</p>

        <form onSubmit={handleSubmit}>
          <div className="profile-input-card">
            <div className="input-form-card">
              <div className="form-label-log">Current Password</div>
              <input
                type="password"
                className="input-form-control"
                placeholder="Enter Current password"
                name="oldPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.oldPassword}
              />
              {errors.oldPassword && touched.oldPassword ? (
                <p className="text-red-500">{errors.oldPassword}</p>
              ) : null}
            </div>
            <a className="forget-pass-span">
              <span
                onClick={() =>
                  Navigation(
                    AppRouteURL.AUTH +
                      AppRouteURLwithChilds.AUTH.childrens.FORGOT_PASSWORD.path
                  )
                }
              >
                Forgot password?
              </span>
            </a>

            <div className="input-form-card tw-mt-3">
              <div className="form-label-log">New Password</div>
              <input
                type="password"
                className="input-form-control"
                placeholder="Enter New password"
                name="newPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
              />
              {errors.newPassword && touched.newPassword ? (
                <p className="text-red-500">{errors.newPassword}</p>
              ) : null}
            </div>

            <div className="input-form-card">
              <div className="form-label-log">Confirm Password</div>
              <input
                type="password"
                className="input-form-control"
                placeholder="Re-enter new password"
                name="re_newPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.re_newPassword}
              />
              {errors.re_newPassword && touched.re_newPassword ? (
                <p className="text-red-500">{errors.re_newPassword}</p>
              ) : null}
            </div>

            <div className="tw-text-center tw-mt-9 ">
              <button className="user-profile-btn " type="submit">
                Update Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};