import { useFormik } from "formik"
import { FiltersInitialvalue, FiltersSchema } from "../../Schema/Flters.schema";
import { Dropdown } from "primereact/dropdown";
import { BOARD_FILTERS, FIELD_TYPE, FILTER_KEYS } from "../../Constants/filters.constants";
import { useEffect, useState } from "react";

export const Filters  = ({ filters,submitFilters,classId }: any) => {
    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setValues, resetForm } = useFormik({
        initialValues:  FiltersInitialvalue,
        enableReinitialize: true,
        validationSchema: FiltersSchema,
        onSubmit: (e) => {
            setValues(e);
        },
    })
    const [Filters, setFilters] = useState<any>(BOARD_FILTERS);

    useEffect(()=>{
        setValues({});
        resetForm({values:{}})
        setFilters(BOARD_FILTERS);
    },[classId]);

    const handleOnChange = (field: string, event: any) => {
        let data: any = values;
        data[field] = event.target.value;
        submitFilters(data);
    }

    const onClickClearFilter = () => {
        let values: any = {};
        setValues(values);
        submitFilters(values);
        resetForm({values:{}})
    }
   
    return (
        <section className="filter-section">
        <div className="filter-head">
            <div className="f-h-heading">Select your filter to refine your test search</div>
            <a className="f-h-clear" onClick={onClickClearFilter} > <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3413_3245)">
<path d="M3.64216 3.45501C4.85187 2.40679 6.39949 1.83078 8.00016 1.83301C11.6822 1.83301 14.6668 4.81768 14.6668 8.49968C14.6668 9.92368 14.2202 11.2437 13.4602 12.3263L11.3335 8.49968H13.3335C13.3336 7.4541 13.0263 6.43155 12.4499 5.55919C11.8735 4.68682 11.0535 4.00312 10.0916 3.5931C9.12978 3.18307 8.06864 3.06482 7.04013 3.25303C6.01163 3.44124 5.06113 3.92761 4.30683 4.65168L3.64216 3.45501ZM12.3582 13.5443C11.1485 14.5926 9.60084 15.1686 8.00016 15.1663C4.31816 15.1663 1.3335 12.1817 1.3335 8.49968C1.3335 7.07568 1.78016 5.75568 2.54016 4.67301L4.66683 8.49968H2.66683C2.66674 9.54527 2.974 10.5678 3.55039 11.4402C4.12678 12.3125 4.94687 12.9962 5.90871 13.4063C6.87054 13.8163 7.93169 13.9345 8.96019 13.7463C9.9887 13.5581 10.9392 13.0717 11.6935 12.3477L12.3582 13.5443Z" fill="#7F56D9"/>
</g>
<defs>
<clipPath id="clip0_3413_3245">
<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg> Clear Filter</a>
        </div>
        <form >

            <div className="filter-dropdown-container">
                {
                    Filters && Filters.length && Filters.map((filter: any, index: number) => {
                        return (
                            Object.keys(filter).map((fil: any) => {
                                return (
                                    filter[fil].fieldType == FIELD_TYPE.DROPDOWN  ?
                                        filters[filter[fil]['list']] && filters[filter[fil]['list']].length ?
                                            <Dropdown key={index} value={values[filter[fil]['key']]} name={filter[fil].name} onChange={(event) => { handleChange(event),handleOnChange(filter[fil].key, event) }}
                                                onBlur={handleBlur} options={filters[filter[fil]['list']]} optionLabel="Name"
                                                placeholder={filter[fil].placeholder} className="filter-dropdown" filter={true} /> : ''
                                           : ''
                                )
                            })
                        )
                    })
                }
            </div>
        </form>
    </section>        )
}