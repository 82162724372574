import {isMobile, browserName, browserVersion, osName, osVersion } from 'react-device-detect';

export const getUserDeviceDetails = () => {
    return {
        ipAddress: '',
        userAgent: '',
        device: isMobile ? 'MOBILE' : 'DESKTOP',
        os: osName ,
        osVersion: osVersion,
        browser: browserName,
        browserVersion: browserVersion,
        isMobileDevice: isMobile,
    };
}