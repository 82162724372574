import { Link, NavLink, useLocation, useMatch, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import { AppRouteURL } from "@Constants/app-route-url.constant";
import DashBoardSvg from "@Assets/svg/Dashboard-svgs/Dahboard.svg";
import MocktestSvg from "@Assets/svg/Dashboard-svgs/MockTest.svg";
import LeaderboardSvg from "@Assets/svg/Dashboard-svgs/LeaderBoar.svg";
import ActiveDashboardSvg from "@Assets/svg/Dashboard-svgs/ActiveDashboard.svg";
import ActiveMocktestSvg from "@Assets/svg/Dashboard-svgs/ActiveMocktest.svg";
import ActiveLeaderboardSvg from "@Assets/svg/Dashboard-svgs/ActiveLeaderboard.svg";
import Logoutsvg from "@Assets/svg/Dashboard-svgs/Logout.svg";
import { setUserID } from "@Src/app/reducers/auth/registerSlice";
import { setIsloggedIn, setLoginToken, setUserFirtsName, setUserLastName, setloginEmailID } from "@Src/app/reducers/auth/loginSlice";
import { useAppDispatch } from "@Src/app/hooks";
import toast from "react-hot-toast";



export const DashboardSidebarIndex = () => {
  const dispatch = useAppDispatch();
  //  let match = useMatch({ path: AppRouteURL.DASHBOARD + AppRouteURL.MOCKTEST + '/20' , end: true });
  const matchMockTestURL = useLocation().pathname.includes(`${AppRouteURL.MOCKTEST}`)
  const logout = () => {
    dispatch(setUserID(null));
    dispatch(setloginEmailID(''));
    dispatch(setLoginToken(''));
    dispatch(setIsloggedIn(false));
    dispatch(setUserFirtsName(''));
    dispatch(setUserLastName(''));
    toast.success('Student loggged out successfully');


  }

  return (
    <>
      <div className="dashboard-sidebar-container">
        <ul className="dashboard-ul-card">

          <li>
            <NavLink to={AppRouteURL.DASHBOARD + AppRouteURL.DASHBOARD_HOME} className={({ isActive }) => isActive ? 'active-navlink-li' : 'navlink-li'}>
              <span className="dashboard-svg" >
                <img className="dash-img-svg" src={DashBoardSvg} alt="dashboard-img" />
                <img className="active-dash-img-svg" src={ActiveDashboardSvg} alt="dashboard-img" />
              </span>
              Dashboard
            </NavLink>
          </li>

          {matchMockTestURL ? <li>
            <NavLink to={AppRouteURL.MOCKTEST} onClick={(e) => e.preventDefault()} className={({ isActive }) => isActive ? 'active-navlink-li' : 'navlink-li'}>
              <span className="dashboard-svg">
                <img className="dash-img-svg" src={MocktestSvg} alt="dashboard-img" />
                <img className="active-dash-img-svg" src={ActiveMocktestSvg} alt="dashboard-img" />
              </span>
              Mock Test</NavLink>
          </li> : ''}

          {/* <li>
          <NavLink to={AppRouteURL.DASHBOARD + AppRouteURL.LEADEBOARD} className={({isActive}) => isActive? 'active-navlink-li' : 'navlink-li' }>
            <span className="dashboard-svg">
            <img className="dash-img-svg" src={LeaderboardSvg} alt="dashboard-img" />
            <img className="active-dash-img-svg" src={ActiveLeaderboardSvg} alt="dashboard-img" />
            </span>
            Leaderboard</NavLink>
          </li> */}

          {/* <li>
          <NavLink to={AppRouteURL.DASHBOARD + AppRouteURL.MYBADGES} className={({isActive}) => isActive? 'active-navlink-li' : 'navlink-li' }>
            <span className="dashboard-svg">
            <img className="dash-img-svg" src={BadgeSvg} alt="dashboard-img" />
            <img className="active-dash-img-svg" src={ActiveBadgeSvg} alt="dashboard-img" />
            </span>
            My Badges</NavLink>
          </li> */}
        </ul>


        <ul className="logout-ul-card">
          <li>
            <Link to="/" >
              <a className="logout-li-tag" onClick={() => logout()}>
                <span className="logout-svg">
                  <img src={Logoutsvg} alt="logout-svg" />

                </span>
                Logout</a>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};
