import { APIResponse, APIStatus } from '@Src/services/baseInterfaces';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getStandardClassesList } from '../../services/SubjectDetails.service';
import './StandardClasses.scss'
export const StandardClassesList = ({ standardClassesList, subjectId,name,subjectName }: any) => {
    return (
        <>
            <section className="standard-classes-container">
                <div className="mock-test-listing-header">
                  {subjectName} Test For
                </div>
                <div className="stnd-cls-cards-container">
                    {
                        standardClassesList && standardClassesList.length > 0 && standardClassesList.map((element: any) => {
                            let className = element.Name; 
                            return (
                                <div key={element.Id} className="stnd-cls-card">
                                    <img className='stnd-cls-card-img' src={element.IconImage} alt="no-6" />

                                    <div className="stnd-cls-cards-header">
                                        {element.Name}
                                    </div>
                                    <div className="stnd-cls-cards-para">
                                        Standard 6 requires an organisation to have a system to resolve complaints...
                                    </div>
                                    <div className="stnd-cls-card-btn">
                                    <Link to={`/${className}/${element.Id}/${name}/${subjectId}`} >
                                        <button className="take-test-btn">View Test</button>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </>
    )
}