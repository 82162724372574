import Header from "@Components/Header/Header"
import { MobHeader } from "@Components/Mob-header/Mob-header"
import { ContactusIndex } from "@Src/features/contactus/contact"


export const Contactus =() => {

    return (
        <>
        <Header/>
        <MobHeader/>
        <ContactusIndex/>
        
        </>
    )
}