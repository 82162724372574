import { NavLink } from "react-router-dom";
import "./Sidebar.scss";
import { AppRouteURL } from "@Constants/app-route-url.constant";
import ProfileSvg from "@Assets/svg/Myprofile/profile.svg";
import ActiveProfile from "@Assets/svg/Myprofile/Activeprofile.svg";
import PasswordSvg from "@Assets/svg/Myprofile/Password.svg";
import ActivePasswordSvg from "@Assets/svg/Myprofile/ActivePassword.svg";
import CoursepreferSvg from "@Assets/svg/Myprofile/CoursePrefer.svg";
import ActiveCoursepreferSvg from "@Assets/svg/Myprofile/ActiveCourseprefer.svg";

export const SidebarIndex = () => {


  return (
    <>
      <div className="user-profile-sidebar-conatiner">
        <div className="user-prof-header">My Profile</div>

        <ul className="u-prof-side-ul">
          <li>
            <NavLink to={AppRouteURL.USERPROFILE + AppRouteURL.MYPROFILE} className={({ isActive }) => isActive ? 'active-li' : 'side-li'}>
              <span >
                <img src={ProfileSvg} className="side-li-svg" alt="my-profile-svg" />
                <img src={ActiveProfile} className="actve-li-svg" alt="my-profile-svg" />
              </span>
              My Profile
            </NavLink>

          </li>

          <li>
            <NavLink to={AppRouteURL.USERPROFILE + AppRouteURL.CHANGEPASS} className={({ isActive }) => isActive ? 'active-li' : 'side-li'}>
              <span >
                <img src={PasswordSvg} alt="my-profile-svg" className="side-li-svg " />

                <img src={ActivePasswordSvg} alt="my-profile-svg" className="actve-li-svg" />
              </span>
              Change Password
            </NavLink>
          </li>
          <li >
            <NavLink to={AppRouteURL.USERPROFILE + AppRouteURL.COURSEPREFERD} className={({ isActive }) => isActive ? 'active-li' : 'side-li'}>

              <span >
                <img src={CoursepreferSvg} alt="my-profile-svg" className="side-li-svg " />

                <img src={ActiveCoursepreferSvg} alt="my-profile-svg" className="actve-li-svg" />
              </span>
              Course Preferences
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};
