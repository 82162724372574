import { UserProfileIndex } from "@Src/features/UserProfile";
import { SidebarIndex } from "@Src/features/UserProfile/Sidebar";
import "./UserProfile.scss";
import Header from "@Components/Header/Header";
import { MobHeader } from "@Components/Mob-header/Mob-header";

export const UserProfile = () => {
  return (
    <>
    <Header />
    <MobHeader/>
      <div className="user-profile-page">
        <div className="user-profile-sidebar">
          <SidebarIndex />
        </div>

        <div className="user-profile-main-contant">
          <UserProfileIndex />
        </div>
      </div>
    </>
  );
};
