import ApiService from "@Src/services/ApiService";
import { CLASSES_API_ROUTES } from "./ClassesDetails.api.constant";

export async function getSubjectTestList(params:any){
    return await ApiService.fetchData({
        url: CLASSES_API_ROUTES.DETAILS + '/' + CLASSES_API_ROUTES.SUBJECT_TEST_LIST,
        method: 'get',
        params: params
    })
}

export async function getBoardsFilterList(params:any){
    return await ApiService.fetchData({
        url: CLASSES_API_ROUTES.DETAILS + '/' + CLASSES_API_ROUTES.BOARD_FILTER_LIST,
        method: 'get',
        params:params
    })
}
