import ApiService from "@Src/services/ApiService";
import { TEST_DETAILS_API_ROUTES } from "./TestDetails.api.constant";

export async function getSimilarTests(params:any){
    return await ApiService.fetchData({
        url: TEST_DETAILS_API_ROUTES.DETAILS + '/' + TEST_DETAILS_API_ROUTES.SIMILAR_TEST,
        method: 'get',
        params:params
    })
}

export async function getTestDetails(data:any){
    return await ApiService.fetchData({
        url: TEST_DETAILS_API_ROUTES.DETAILS + '/' + TEST_DETAILS_API_ROUTES.TEST_DETAILS,
        method: 'post',
        data
    })
}

export async function getTestList(data:any){
    return await ApiService.fetchData({
        url: TEST_DETAILS_API_ROUTES.DETAILS + '/' + TEST_DETAILS_API_ROUTES.TEST_LIST,
        method: 'post',
        data
    })
}

export async function getSearchFilters(data:any){
    return await ApiService.fetchData({
        url: TEST_DETAILS_API_ROUTES.SEARCH_FILTERS,
        method: 'post',
        data
    })
}

export async function getTestSeriesList(data:any){
    return await ApiService.fetchData({
        url: TEST_DETAILS_API_ROUTES.TEST_SERIES_LIST,
        method: 'post',
        data
    })
}

export async function getTestSeriesSearchFilters(data:any){
    return await ApiService.fetchData({
        url: TEST_DETAILS_API_ROUTES.TEST_SERIES_FILTERS,
        method: 'post',
        data
    })
}

export async function getTestSeriesDetails(data:any){
    return await ApiService.fetchData({
        url: TEST_DETAILS_API_ROUTES.DETAILS + '/' + TEST_DETAILS_API_ROUTES.EXAM_DETAILS,
        method: 'post',
        data
    })
}

export async function getPreviousOpenStateTest(){
    return await ApiService.fetchData({
        url: TEST_DETAILS_API_ROUTES.TEST + '/' + TEST_DETAILS_API_ROUTES.PREVIOUS_OPEN_TEST,
        method: 'get',
        data:{}
    })
}

export async function putEndPreviousOpenStateTests(){
    return await ApiService.fetchData({
        url: TEST_DETAILS_API_ROUTES.TEST + '/' + TEST_DETAILS_API_ROUTES.END_PREVIOUS_OPEN_TEST,
        method: 'put',
        data:{}
    })
}
