import { DashboardIndex } from "@Src/features/Dashboard"


export const Dashboard = () => {

    return (
        <>
         <DashboardIndex />
        </>
    )
}