
import { FILTER_KEYS } from '@Src/features/Filter/Constants/filters.constants';
import * as Yup from 'yup';

export const FiltersSchema = Yup.object({
    [FILTER_KEYS.BOARD] :Yup.object(),
})


export const FiltersInitialvalue = {
    [FILTER_KEYS.BOARD]:{},
}  