import { MathJax } from "better-react-mathjax";
import CorrectSvg from "@Assets/svg/Dashboard-svgs/correct.svg";
import WroungSvg from "@Assets/svg/Dashboard-svgs/Wroung.svg";

export const ResultCard = ({ review, rightOptions, opt, options, missedOption }: any) => {
    return (
                                          
                                            review && review.IsMultiple ? 
                                            <div className={`review-options  ${ (missedOption && missedOption.length && missedOption.includes(opt[0].toLowerCase()) )  ? 'missed-answer' : review.Ans && (review.Ans.toLowerCase().includes(opt[0].toLowerCase()))  ? 'correct-question' : (review.SelectedOption && (review.SelectedOption.toLowerCase().includes(opt[0].toLowerCase())) && review.AnsType === 0) ? 'incorrect-qust' : 
                                             ''}
                                            `}>
                                           <span className="opt-span">
                                               <span className="tw-flex"> {opt[0]} <span className="option-value"  >
                                                   {/* <MathJax dangerouslySetInnerHTML={{ __html: opt[1] }} dynamic={true} >
                                                   </MathJax> */}
                                                   <div dangerouslySetInnerHTML={{ __html: opt[1] }} >
                                                   </div>
                                               </span></span>
                                               { rightOptions.includes(opt[0].toLowerCase()) && review.AnsType != 1 ?
                                               missedOption && missedOption.length && missedOption.includes(opt[0].toLowerCase()) ?
                                               <span className="missed-opt-span">Missed out Option {opt[0].toUpperCase()} </span>
                                               :
                                                   <span className="correct-opt-span">Correct asnwer is Option {opt[0].toUpperCase()} </span>
                                                   : ''}
                                               {rightOptions.includes(opt[0].toLowerCase()) && review.AnsType == 1 ?
                                                   <img className="Correct-svg" src={CorrectSvg} alt="back-svg" />
                                                   : 
                                                   <img src={WroungSvg} className="incorrect-svg" alt="back-svg" />
                                               }
                                               {/* <img src={WroungSvg} className="incorrect-svg" alt="back-svg" /> */}
                                           </span>
                                       </div> 
                                            :
                                            <div className={`review-options  ${(review.Ans && (review.Ans.toLowerCase().includes(opt[0].toLowerCase())) ) ? 'correct-question' : (review.SelectedOption && (review.SelectedOption.toLowerCase().includes(opt[0].toLowerCase())) && review.AnsType === 0) ? 'incorrect-qust' : ''}
                                            `}>
                                           <span className="opt-span">
                                               <span className="tw-flex"> {opt[0]} <span className="option-value"  >
                                                   {/* <MathJax dangerouslySetInnerHTML={{ __html: opt[1] }} dynamic={true} >
                                                   </MathJax> */}
                                                                                                      <div dangerouslySetInnerHTML={{ __html: opt[1] }}  >
                                                   </div>
                                               </span></span>
                                               {opt[0].toLowerCase() == rightOptions[0] && review.AnsType != 1 ?
                                                   <span className="correct-opt-span">Correct asnwer is Option {review.Ans.toUpperCase()} </span>
                                                   : ''}
                                               { opt[0].toLowerCase() == rightOptions[0] && review.AnsType == 1 ?
                                                   <img className="Correct-svg" src={CorrectSvg} alt="back-svg" />
                                                   : 
                                                   <img src={WroungSvg} className="incorrect-svg" alt="back-svg" />
                                               }
                                               {/* <img src={WroungSvg} className="incorrect-svg" alt="back-svg" /> */}
                                           </span>
                                       </div> 
                                )
}