import ApiService from "@Src/services/ApiService";
import { CONTACT_US_API_ROUTES } from "./contactus.api.constant";


export async function contactUs(data: any) {
    return ApiService.fetchData({
        url: CONTACT_US_API_ROUTES.CONTACT_US,
        method: "post",
        data,
    });
}