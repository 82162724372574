import './ChooseOurPlatform.scss'
export const ChooseOurPlatform = () => {
    return (
        <section className="why-choose-us-container">
            <div className="main-header-card">
                <div className="mock-heading">Why Choose Our Platform?</div>
                <div className="moc-para">Join our hands to make your journey to success easier</div>
            </div>
            <div className="why-choose-us-cards-container">
                <div className="why-choose-us-cards">
                    <span className="w-c-u-card-svg">
                        <img src="https://i.postimg.cc/cHZVv5xB/key-alt.png" />
                    </span>
                    <div className="w-c-u-card-header">Simple to Learn</div>
                    <div className="w-c-u-card-para">
                        One stop solution for online examination and assessment. It would solve all your problems in preparation for the exam. The platform is effortless to use and transmit information.
                    </div>
                </div>
                <div className="why-choose-us-cards">
                    <span className="w-c-u-card-svg">
                        <img src="https://i.postimg.cc/hGVkPqBG/Laptop-with-cursor.png" />
                    </span>
                    <div className="w-c-u-card-header">Interactive Interface</div>
                    <div className="w-c-u-card-para">
                        Easy registration, click away tricks, and simple test creation. Signing in and concurring. Safe data encrypted information.            </div>
                </div>
                <div className="why-choose-us-cards">
                    <span className="w-c-u-card-svg">
                        <img src="https://i.postimg.cc/XvMMbYth/Report-File.png" />
                    </span>
                    <div className="w-c-u-card-header">Advanced Reporting System</div>
                    <div className="w-c-u-card-para">
                        It offers fast result generation, digital evaluation, sharing feedback for enhancing performance and perfection. An utmost combination of detailed combinations, according to skills and results.            </div>
                </div>
                <div className="why-choose-us-cards">
                    <span className="w-c-u-card-svg">
                        <img src="https://i.postimg.cc/FzH8ZdJf/Online-Support.png" />
                    </span>
                    <div className="w-c-u-card-header">Exception Support</div>
                    <div className="w-c-u-card-para">
                        We offer exceptional support to your request. Our dedicated professionals work round the clock to work on your queries.            </div>
                </div>
                <div className="why-choose-us-cards">
                    <span className="w-c-u-card-svg">
                        <img src="https://i.postimg.cc/SsrHC7jF/Membership-Card.png" />
                    </span>
                    <div className="w-c-u-card-header">Smart Subscription</div>
                    <div className="w-c-u-card-para">
                        Subscription according to your reference for the use of an online examination platform.            </div>
                </div>
                <div className="why-choose-us-cards">
                    <span className="w-c-u-card-svg">
                        <img src="https://i.postimg.cc/y83qtZpc/System-Information.png" />
                    </span>
                    <div className="w-c-u-card-header">Dynamic Accessibility</div>
                    <div className="w-c-u-card-para">
                        Anywhere and anytime practice of online examination system. Design and assign tests from anywhere and anytime. Error-free synchronized performance.            </div>
                </div>
            </div>
        </section>
    )
}