

import "./Contactus.scss";
import MaileSvg from "@Assets/svg/Contactus/Maile.svg";
import SupportSvg from "@Assets/svg/Contactus/support.svg";
import LocationSvg from "@Assets/svg/Contactus/Location.svg";
import DeliverySvg from "@Assets/svg/Contactus/Delivery.svg";
import GetinTouchSvg from "@Assets/svg/Contactus/getintouch.svg";
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from "formik";
import { ContactUsInitialValue, ContactUsSchema } from "./Schema/contactus.schema";
import { contactUs } from "./service/contactus.service";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { useAppDispatch } from "@Src/app/hooks";
import { toast } from "react-hot-toast";
import { showSpinner } from "@Src/app/reducers/globalSlice";

export const ContactusIndex = () => {
    const dispatch = useAppDispatch();

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setValues,resetForm } = useFormik({
        initialValues: ContactUsInitialValue,
        validationSchema: ContactUsSchema,
        onSubmit: (e) => {
          contactUsForm(e)
    
        }
      })
    
      const contactUsForm = (payload: any) => {
        if (isValid && touched) {
            dispatch(showSpinner(true));
            contactUs(payload).then((response: APIResponse) => {
                dispatch(showSpinner(false));
                if (response?.ResponseType === APIStatus.SUCCESS) {
                    resetForm()
                   toast.success('Your Query has been received. We will get back to you shotly');
                } else{
                    toast.error(response.StatusMsg);
                }
            }).catch((error) => {
                dispatch(showSpinner(false));

            })
        }
    }

    return (
        <>
            <section className="contact-us-section ">
                <div className="c-u-s-head">
                    <span>Home </span> | <span className="active-span"> Contact Us</span>
                </div>
                <div className="c-u-heading">
                    Contact Us
                </div>
                <div className="c-u-card">
                    <div className="c-u-card-left">
                        Lorem ipsum dolor sit amet consectetur. Tristique proin pulvinar
                        elementum bibendum viverra risus at convallis. Pharetra vel maecenas
                        scelerisque imperdiet. Sed odio vitae nunc neque ut metus id venenatis pharetra.
                        Sollicitudin arcu sociis enim vel. Urna egestas sit sagittis quam donec eget sit.
                        Commodo semper ullamcorper praesent vulputate ornare eget est. Egestas fermentum lectus justo et erat luctus vel.
                    </div>

                    <div className="c-u-card-right">
                        <div className="c-u-details">
                            <div className="c-u-d-sub-card">
                                <span className="blue-color-span">
                                    <img src={MaileSvg} alt="mailer-svg" />
                                </span>
                                <div className="tw-flex tw-flex-col">
                                    <span className="c-u-tel-email">Tel: 877-67-88-99</span>
                                    <span className="c-u-tel-email">E-Mail: shop@store.com</span>
                                </div>
                            </div>
                            <div className="c-u-d-sub-card">
                                <span className="blue-color-span">
                                    <img src={SupportSvg} alt="mailer-svg" />
                                </span>
                                <div className="tw-flex tw-flex-col">
                                    <span className="c-u-tel-email">Tel: 877-67-88-99</span>
                                    <span className="c-u-tel-email">E-Mail: shop@store.com</span>
                                </div>
                            </div>
                        </div>

                        <div className="c-u-details marg-div">
                            <div className="c-u-d-sub-card">
                                <span className="blue-color-span">
                                    <img src={LocationSvg} alt="mailer-svg" />
                                </span>
                                <div className="tw-flex tw-flex-col">
                                    <span className="c-u-tel-email">Tel: 877-67-88-99</span>
                                    <span className="c-u-tel-email">E-Mail: shop@store.com</span>
                                </div>
                            </div>

                            <div className="c-u-d-sub-card">
                                <span className="blue-color-span">
                                    <img src={DeliverySvg} alt="mailer-svg" />
                                </span>
                                <div className="tw-flex tw-flex-col">
                                    <span className="c-u-tel-email">Tel: 877-67-88-99</span>
                                    <span className="c-u-tel-email">E-Mail: shop@store.com</span>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </section>
            <div className="g-i-t-head-mob">Get In Touch</div>
            <section className="get-in-touch-sec">
                <div className="g-i-t-left-card">
                    <div className="g-i-t-head">Get In Touch</div>
                    <div className="g-i-t-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis neque ultrices  tristique amet erat vitae eget dolor los vitae lobortis quis bibendum quam.</div>
                    <form onSubmit={handleSubmit}>
                        <div className="n-e-card">
                            <InputText value={values.Name} id="Name" className="contact-input" type="text" placeholder="Name*" onChange={handleChange} onBlur={handleBlur} />
                            <br/>
                            {touched.Name && errors.Name ? (
                        <p className="tw-text-red-500 tw-text-sm ">{errors.Name}</p>
                    ) : null}
                            <InputText value={values.EmailId} id="EmailId" className="contact-input" placeholder="Email id*" onChange={handleChange} onBlur={handleBlur} />
                            <br/>
                            {touched.EmailId && errors.EmailId ? (
                        <p className="tw-text-red-500 tw-text-sm">{errors.EmailId}</p>
                    ) : null}
                        </div>
                        <InputText value={values.ContactNumber} id="ContactNumber" className="contact-input" placeholder="Contact Number*" onChange={handleChange} onBlur={handleBlur} />
                        {/* <InputNumber value={values.ContactNumber} id="ContactNumber" className="contact-input" useGrouping={false} maxLength={10} placeholder="Contact Number*" onValueChange={handleChange}  /> */}
                        {touched.ContactNumber && errors.ContactNumber ? (
                        <p className="tw-text-red-500 tw-text-sm ">{errors.ContactNumber}</p>
                    ) : null}
                        <InputText value={values.Subject}  id="Subject" className="contact-input" placeholder="Subject*" onChange={handleChange} onBlur={handleBlur} />
                        {touched.Subject && errors.Subject ? (
                        <p className="tw-text-red-500 tw-text-sm ">{errors.Subject}</p>
                    ) : null}
                        <InputTextarea value={values.Description} id="Description" className="contact-input" rows={5} cols={30} placeholder="Type Your Message*" onChange={handleChange} onBlur={handleBlur} />
                        {touched.Description && errors.Description ? (
                        <p className="tw-text-red-500 tw-text-sm ">{errors.Description}</p>
                    ) : null}
                        <button  type="submit" className="send-mail-btn" >Send Mail</button>

                    </form>
                </div>
                <div className="g-i-t-right-card">
                    <img src={GetinTouchSvg} alt="getintouch-svg" />
                </div>

            </section>

        </>
    )

}