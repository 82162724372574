import axios from 'axios'
import CryptoJs from '@Src/utils/service/crypto.service';
import { useAppSelector } from '@Src/app/hooks';
import { store } from '@Src/app/store';
const unauthorizedCode = [401]

const cryptoJs = new CryptoJs();

const BaseService = axios.create({
    timeout: 60000,
    baseURL: process.env.REACT_APP_BASE_API_URL,
})

BaseService.interceptors.request.use((config: any) => {
    if (config.encrypt) {
        config.headers['encrypt'] = true;
        config.data = {
            'encodedPayload': cryptoJs.encrypt(JSON.stringify(config.data))
        }
    }
    config.headers['authorization'] = store.getState().login.loginToken;

    return config;
}, error => {
    return Promise.reject(error)
})

BaseService.interceptors.response.use(
    response => {
        if (response.config['headers']?.encrypt) {
            if (response.data.data)
                response.data.data = JSON.parse(cryptoJs.decrypt(response.data.data))
        }
        return response
    },
    error => {
        const { response } = error
        if (response && unauthorizedCode.includes(response.status)) {
            // store.dispatch(onSignOutSuccess())
        }
        return Promise.reject(error)
    }
)


export default BaseService