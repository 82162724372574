import { Dialog } from "primereact/dialog"

export const OpenStateTestDialog = ({ openStateDialog, previousOpenTestDetails, endTestHandler, }: any) => {
  return (
    <>
      <Dialog className="mock-test-dialog-box moc-test-unfinish-popup"   position="top" visible={openStateDialog} onHide={() => endTestHandler()} closeOnEscape={true} >
        <div className="pause-dialog-box">
          <span className="tw-flex tw-justify-end tw-cursor-pointer tw-mb-2">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.936 2.63288L8.8359 7.73297L13.936 12.8331C14.5783 13.4752 13.4825 14.5852 12.8331 13.936L7.73297 8.8359L2.63288 13.936C1.99074 14.5781 0.880697 13.4825 1.52995 12.8331L6.63005 7.73297L1.52995 2.63288C0.887817 1.99074 1.98362 0.880697 2.63288 1.52995L7.73297 6.63005L12.8331 1.52995C13.4825 0.8807 14.5783 1.99074 13.936 2.63288Z" fill="#101828" fill-opacity="0.45" />
            </svg>

          </span>
          <span className="para-head">You have an unfinished test,<span className="para-strong"> {previousOpenTestDetails?.TestName}</span>.
            The ongoing test would end  in order to start a new test.</span>
          <div className="tw-mt-7 tw-flex">
            <button className="dialog-ok-btn"
              onClick={() => endTestHandler()} > OK</button>
          </div>
        </div>
      </Dialog>
    </>
  )
}