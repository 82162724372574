import { APIResponse, APIStatus } from '@Src/services/baseInterfaces';
import { useEffect, useState } from 'react';
import HomeLayout from '../layout/components/HomeLayout/HomeLayout';
import DetailsPageBanner from '@Components/DetailsPageBanner/DetailsPageBanner';
import { SubscriptionUpdates } from '@Components/SubscriptionUpdates/SubscriptionUpdates';
import { MockTestSeries } from './Components/MockTestSeries/MockTestSeries';
import { useParams } from 'react-router-dom';
import { getMockTestSeries, getStandardClasses, getStandardClassesList } from './services/TestSeriesDetails.service';
import { StandardClasses } from './Components/StandardClasses/StandardClasses';
import { HeaderNameKeys } from '@Constants/app.constant';
const TestSeriesDetailsIndex = () => {
    // const params = useParams();
    // const testId = params.testId;
    // let testName = params.testName; 
     let Headings = [
        { name:'Test Series', type:HeaderNameKeys.ALL_TEST_SERIES}]
    // const [standardClasses, setStandardClasses] = useState<Array<any>>([]);
    const [mockTestSeriesList, setMockTestSeriesList] = useState<Array<any>>([]);
    const [headingsBreadcrumb, setHeadingsBreadcrumb] = useState<Array<any>>(Headings);
        useEffect(() => {
            getALLMockTestSeries();
    }, []);


    // useEffect(() => {
    //     getAllStandardClasses();
    // }, [testId]);

    // function getAllStandardClasses() {
    //     let param = {
    //         examId: testId
    //     }
    //     getStandardClasses(param).then((response: APIResponse) => {
    //         if (response?.ResponseType == APIStatus.SUCCESS) {
    //             let data = response?.data;
    //             setStandardClasses(data);
    //         }
    //     })
    // }

    // function getMockTestSeries(standerClassId:number){
    //     let param = {
    //         standerClassId: standerClassId > 0 ? standerClassId : '',
    //         examId: testId
    //     }
    //     getStandardClassesList(param).then((response: APIResponse) => {
    //         if (response?.ResponseType == APIStatus.SUCCESS) {
    //             let data = response?.data;
    //             setMockTestSeriesList(data);
    //         }
    //     })
    // }

    function getALLMockTestSeries(){
        getMockTestSeries().then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setMockTestSeriesList(data);
            }
        })
    }

    return (
        <>
            <HomeLayout >
                {/* <!-----------------------------------test-listining-section-------------------------------> */}
                <div className="test-listining">
                    <DetailsPageBanner Headings={Headings}  />
                    {/* <StandardClasses standardClasses={standardClasses} getMockTestSeries={getMockTestSeries}  /> */}
                    <MockTestSeries mockTestSeriesList={mockTestSeriesList} />
                    <SubscriptionUpdates />
                </div>
                {/* <!----------------------------------end-test-listining-section------------------------------->  */}
            </HomeLayout>

        </>
    );
}

export default TestSeriesDetailsIndex;
