import "./Instruction.scss";
import TickcicrcleSvg from "@Assets/svg/Dashboard-svgs/tickCircle.svg";
import FilledflagSvg from "@Assets/svg/Dashboard-svgs/Flagfilled.svg";
import QuestionSvg from "@Assets/svg/Dashboard-svgs/Question.svg";
import InstrucClockSvg from "@Assets/svg/Dashboard-svgs/InstuctClock.svg";
import InstructionStarSvg from "@Assets/svg/Dashboard-svgs/Instructionstar.svg";
import InstructionFlagSvg from "@Assets/svg/Dashboard-svgs/InstructionFlag.svg";
import { TestTypes } from "@Constants/app.constant";
import FlagSvg from "@Assets/svg/Dashboard-svgs/Flag.svg";

export const InstructionIndex = ({maxMarks,noOfQuestion,timeDuration, onContinueClickHandler, testType, isTestSeries, testName}:any) => {
    return (
        <>
            <div className="ui-module-1" >
                <div className="ui-module-info-1">
                    <div className="ui-m-i-header">
                        <h2 className="ui-module-info-h1">Necessary Registration of an Entity - {testName}</h2>
                        <ul className="ui-i-detals">
                            {noOfQuestion && noOfQuestion >0 ? <li className="m-questions-1">
                                <span className="ui-i-icons">
                                  <img src={QuestionSvg} alt="qust-svg" />
                                </span>
                                <span className="ui-m-i-details">
                                    <span className="ui-m-i-count"> {noOfQuestion} </span>
                                    <span className="ui-m-i-names"> Questions </span>
                                </span>
                            </li>
                                : ''}
                            <li className="m-times-1 colors-2">
                                <span className="ui-i-icons">
                                    <img src={InstrucClockSvg} alt="clock-svg" />
                                </span>
                                <span className="ui-m-i-details">
                                    <span className="ui-m-i-count"> {testType == TestTypes.regular   ? timeDuration/60 : timeDuration} </span>
                                    <span className="ui-m-i-names"> Minutes </span>
                                </span>
                            </li>
                            {
                                maxMarks && maxMarks > 0  ? <li className="m-mark-1 colors-3">
                                    <span className="ui-i-icons">
                                       <img src={InstructionStarSvg} alt="inst-star" />
                                    </span>
                                    <span className="ui-m-i-details">
                                        <span className="ui-m-i-count"> {maxMarks} </span>
                                        {/* <span className="ui-m-i-names"> Cut off mark </span> */}
                                        <span className="ui-m-i-names"> Maximum Mark </span>
                                    </span>
                                </li>
                                    : ''
                            }

                        </ul>
                    </div>
                    <div className="ui-module-info-contents">
                        <div className="ui-general-ins-1">
                            <div className="ui-g-ins-row">
                                <h2 className="ui-general-ins-h1"> General Instructions: </h2>
                                <ul className="ui-general-ins-list">
                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        This is a practice test.
                                    </li>


                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        Each Question will have 4 answer options, out of which only one option will be correct.
                                    </li>

                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        There are no negative marks for incorrect answers.
                                    </li>
                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        No marks will be awarded or deducted for the unattempted question.
                                    </li>

                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        Do not use your keyboard during the test.

                                    </li>
                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        Do not use the browser back, forward, or refresh button during the test.

                                    </li>
                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        The clock will be set at the server. The countdown timer present on the screen will display
                                        the remaining time available for you to complete the examination. When the timer reaches
                                        zero, the examination will end by itself. You will not be required to end or submit your
                                        examination.

                                    </li>
                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        You can end the test anytime by clicking the “End Test” button.

                                    </li>
                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        You can even pause the test in case of any emergency and can also resume the same later
                                        during the same day.

                                    </li>
                                    <li>
                                        <span className="li-row">
                                            <img src={TickcicrcleSvg} alt="tick-svg" />
                                            The Question Palette displayed on the right side of the screen will show the status of each question using one of the following symbols:
                                        </span>
                                        <ul className="q-n-lists">


                                            <li className="li-row">
                                                <span className="q-palettes active"> 1 </span> : You have answered the question.


                                            </li>

                                            <li className="li-row">
                                                <span className="q-palettes active-2"> 1 </span> : You have not answered the question.
                                            </li>
                                            <li className="li-row">
                                                <span className="q-palettes"> 1 </span> : You have not visited the question yet.
                                            </li>
                                            <li className="li-row ">
                                                <span>
                                                    <img src={FilledflagSvg} alt="filled-svg" />
                                                </span>: You have answered the question and marked it for review.
                                            </li>


                                            <li className="li-row">
                                                <span> 
                                                   <img src={InstructionFlagSvg} alt="flag-svg" />
                                                </span>: You have not answered the question and marked it for review.
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        If an answer is selected for a question that is Marked for Review, the answer will be
                                        considered in the final evaluation.
                                    </li>
                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        <span> You can mark a question for review by simply clicking on the flag icon ( <span className="flag-span">🏳️</span> )
                                        present on the
                                        screen.
                                         
                                        </span>
                                        
                                        
                                    </li>

                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        You may have to scroll down to see the answer options for some questions. You can also make
                                        use of the buttons labeled Q and A to jump between the two divisions namely the question
                                        division and the answer options division.
                                    </li>

                                </ul>
                            </div>
                            <div className="ui-g-ins-row">
                                <h2 className="ui-general-ins-h1"> Navigating to a question
                                </h2>
                                <p> To select a question to answer, you can do one of the following:
                                </p>
                                <ul className="ui-general-ins-list">
                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        Click on the question number on the question palette at the right of your screen to go to
                                        that numbered question directly.
                                    </li>

                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        Click on the Next button to go to the next question in sequence.
                                    </li>
                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        Click on the Previous button to go to the previous question in sequence.
                                    </li>


                                </ul>
                            </div>
                            <div className="ui-g-ins-row">
                                <h2 className="ui-general-ins-h1"> Answering questions
                                </h2>


                                <ul className="ui-general-ins-list">
                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        To select & save your answer, click on one of the four answer options present on the screen.
                                    </li>

                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        To change your answer, click on any one of the remaining answer options. Your response will be saved as soon as you will click the new answer option of your choice.
                                    </li>



                                    <li className="li-row">
                                        <img src={TickcicrcleSvg} alt="tick-svg" />
                                        You can use the clear response button to deselect an already selected answer option.
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="ui-module-footer tw-mt-5">
                        <div onClick={() => { onContinueClickHandler() }} >

                            <a href="javascript:void(0)" className="ui-button-default active btn-lg-m-1" >
                                Get Started ! </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}