import ApiService from "@Src/services/ApiService";
import { TEST_SERIES_DETAILS_API_ROUTES } from "./TestSeriesDetails.api.constant";

export async function getStandardClasses(params:any){
    return await ApiService.fetchData({
        url: TEST_SERIES_DETAILS_API_ROUTES.DETAILS + '/' + TEST_SERIES_DETAILS_API_ROUTES.STANDARD_CLASSES,
        method: 'get',
        params: params
    })
}

export async function getStandardClassesList(params:any) {
      return await ApiService.fetchData({
        url: TEST_SERIES_DETAILS_API_ROUTES.DETAILS + '/' +TEST_SERIES_DETAILS_API_ROUTES.STANDARD_CLASSES_TEST_LIST,
        method: 'get',
        params: params
      })
}

export async function getMockTestSeries(){
  return await ApiService.fetchData({
      url: TEST_SERIES_DETAILS_API_ROUTES.ALL_TEST_SERIES,
      method: 'get',
  })
}
