import { DASHBOARD_API_ROUTES } from "./Dashboard.api.constant";
import ApiService from "@Src/services/ApiService";



export const getDashboarddetails = async () => {
    return await ApiService.fetchData({
        url:DASHBOARD_API_ROUTES.DASHBOARD,
        method:'get',
        params:''
    })

}

export const getDashboardTestSeriesDetails = async () => {
    return await ApiService.fetchData({
        url:DASHBOARD_API_ROUTES.DASHBOARD_TEST_SERIES,
        method:'get',
        params:''

    })

}


export const getTestResult = async (params:any) => {
    return await ApiService.fetchData({
        url:DASHBOARD_API_ROUTES.TESTRESULT,
        method:'get',
        params: params
    })
}


export const getUserTestReview = async (params:any) => {
   return await ApiService.fetchData({
    url:DASHBOARD_API_ROUTES.TESTREVIEW,
    method:'get',
    params:params
   })
}

export const getLeaderBoard = async (params:any) => {
   return await ApiService.fetchData({
    url:DASHBOARD_API_ROUTES.LEADERBOARD ,
    method:'get',
    params:params
   })
}

export const getTestSeriesResult = async (params:any) => {
    return await ApiService.fetchData({
        url:DASHBOARD_API_ROUTES.TEST_SERIES_RESULT,
        method:'get',
        params: params
    })
}


export const getUserTestSeriesReview = async (params:any) => {
   return await ApiService.fetchData({
    url:DASHBOARD_API_ROUTES.TEST_SERIES_REVIEW,
    method:'get',
    params:params
   })
}

export const getLeaderBoardTestSeries = async (params:any) => {
   return await ApiService.fetchData({
    url:DASHBOARD_API_ROUTES.LEADERBOARD_TEST_SERIES ,
    method:'get',
    params:params
   })
}