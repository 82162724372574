import { AppRoutes } from "@Src/routes/Routes";
import './layout.scss'

const Layout = () => {
    return (
        <>
              <AppRoutes/>
              </>
    );
};

export default Layout;
