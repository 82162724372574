import DetailsPageBanner from '@Components/DetailsPageBanner/DetailsPageBanner';
import { SubscriptionUpdates } from '@Components/SubscriptionUpdates/SubscriptionUpdates';
import { APIResponse, APIStatus } from '@Src/services/baseInterfaces';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HomeLayout from '../layout/components/HomeLayout/HomeLayout';
import { getBoardsFilterList, getSubjectTestList } from './services/ClassesDetails.service';
import { FilterIndex } from '../Filter/Filter';
import SubjectTests from './Components/SubjectTests/SubjectTests';
import { Filters } from './Components/Filters/Filters';
import { FILTER_KEYS, FILTER_LIST } from './Constants/filters.constants';
import { HeaderNameKeys } from '@Constants/app.constant';
const ClassesDetailsIndex = () => {
    const params = useParams();
    const classId = params.classId;
    let className = params.className;
    let Headings = [
        {name:className, type:HeaderNameKeys.CLASS, id: classId}
    ]
    const [subjectList, setSubjectList] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<any>([]);
    const [board, setBoard] = useState<any>({});
    const [headingsBreadcrumb, setHeadingsBreadcrumb] = useState<Array<any>>(Headings);

    useEffect(() => {
        window.scrollTo(0, 0);
        getAllBoardsFilterList()
        getAllSubjectList();
    }, [classId]);

    function getAllSubjectList(filterParam?:any) {
        setIsLoading(true)
        let param = {
            classId: classId
        }
        if(filterParam){
            Object.assign(param, filterParam);
        }
        getSubjectTestList(param).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                let data = response?.data;
                setSubjectList(data);
                setIsLoading(false)
            }
        })
    }

    function getAllBoardsFilterList() {
        setFilters([]);
        let params = {
            classId:classId
        }
        getBoardsFilterList(params).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
               // let data = response?.data;
                let filtersData = {
                    [FILTER_LIST.BOARD]:response?.data
                }
                setFilters(filtersData);
            }
        })
    }

    const submitFilters = (filters:any) =>{
        let newFilters:any = filters;
        setBoard(newFilters[FILTER_KEYS.BOARD]);
     let params = modifiedFilters(newFilters);
     getAllSubjectList(params)

    }

    const modifiedFilters = (filters:any) =>{
        let payload:any={};
        let keys = Object.keys(filters);
        keys.forEach((key)=>{
              payload[key] = filters[key].Id 
        });
        return payload
  }

    return (
        <>
            <HomeLayout >
                {/* <!-----------------------------------test-listining-section-------------------------------> */}
                <div className="test-listining">
                    <DetailsPageBanner Headings={headingsBreadcrumb} />
                    <Filters filters={filters} submitFilters={submitFilters} classId={classId}  />
                    <SubjectTests subjectList={subjectList} classId={classId} name={params.className} board={board} />
                    <SubscriptionUpdates />
                </div>

                {/* <!----------------------------------end-test-listining-section------------------------------->  */}
            </HomeLayout>

        </>
    );
}

export default ClassesDetailsIndex;
