import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSpeedTestQuestions, getSpeedTestSeriesQuestions, postFinalSubmitTest, postResumeTest, postSpeedTestQuestion, postSpeedTestSeriesQuestion, postSpeedTestSeriesSubmitQuestion, postSpeedTestSubmitQuestion, postTimerTest } from "../../MockTest/services/MockTest.service";
import { APIResponse, APIStatus } from "@Src/services/baseInterfaces";
import { AppRouteURL } from "@Constants/app-route-url.constant";
import { MyTimer } from "@Components/CountDown/CountDown";
import { getUserDeviceDetails } from "@Src/utils/service/device-details.service";
import { CountDownActions, StopWatchActions } from '@Constants/app.constant';
import { TestTypes } from '@Constants/app.constant';
import { StopWatch } from "@Components/StopWatch/StopWatch";
import { QuestionCardIndex } from "@Src/features/MockTest/Components/Test-question-card/Question-card";
import { InstructionSetIndex } from "@Src/features/MockTest/Components/InstructionSet/InstructionSet";
import { QuestionPaletteIndex } from "@Src/features/MockTest/Components/Test-question-card/Question-palette";
import { QuestionPaperFooter } from "@Src/features/MockTest/Components/QuestionPaperFooter/QuestionPaperFooter";
import { EndTestDialog } from "@Components/EndTestDialog/EndTestDialog";
import { ExpireTimeEndTestDialog } from "@Components/ExpireTimeEndTestDialog/ExpireTimeEndTestDialog";
import { useAppDispatch, useAppSelector } from "@Src/app/hooks";
import { QuestionpaperrightFooter } from "@Src/features/MockTest/Components/QuestionPaperFooter/Questionpaperrightfooter";
import TimerSvg from "@Assets/svg/MockTest-svgs/Timer.svg"
import { showSpinner } from "@Src/app/reducers/globalSlice";
import { QuestionsExhaustDialog } from "@Components/QuestionsExhaustDialog/QuestionsExhaustDialog";
import { QuestionsNotAvailableDialog } from "@Components/QuestionsNotAvailableDialog/QuestionsNotAvailableDialog";
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';


export const SpeedTestCard = ({ testId, noOfQuestion, timeDuration,isTestSeries, testIds,testName }: any) => {
    const navigation = useNavigate();
    const testType = TestTypes.speed;
    const [instruction, setinstruction] = useState(false);
    const [Pauesedialog, setPauesedialog] = useState(false);
    const [questionsStore, setAllQuestions] = useState<any[]>([]);
    const [index, setIndex] = useState(0);
    const [endTestDialog, setEndTestDialog] = useState(false);
    const [action, setAction] = useState('');
    const [expireTestDialog, setExpireTestDialog] = useState(false);
    const [Qustpalette, setQustpalette] = useState(false);
    const [indexInNeed, setIndexInNeed] = useState(0);
    const userID = useAppSelector(state => state.login.userID)
    const fullQuestionPaperMode: boolean = false;
    const time = new Date();
    time.setSeconds(time.getSeconds() + (timeDuration * 60));
    const [submitAnswer, setSubmitAnswer] = useState<any>({});
    const params = useParams()
   const timer: any = useRef();
    const dispatch = useAppDispatch();
    const [questionsExhaustDialog, setQuestionsExhaustDialog] = useState<boolean>(false);
    const [questionsNotAvailbleDialog, setQuestionsNotAvailbleDialog] = useState<boolean>(false);
    const ref:any = useRef();

    useEffect(() => {
        if(isTestSeries){
            insertSpeedTestSeriesQuestions()
        }else{
        insertSpeedTestQuestions()
        }
    }, []);

    useEffect(() => {
        if (questionsStore && questionsStore.length && questionsStore[0] && questionsStore[0]['UserTestId']) {
            let param = {
                "userTestId": questionsStore[0]['UserTestId'],
                "timeTake": 0
            }
            postTimerTest(param);
            timer.current = setInterval(() => {
                let params = {
                    "userTestId": questionsStore[0]['UserTestId'],
                    "timeTake": 15
                }
                postTimerTest(params);
            }, 15000);
        }
        return () => clearInterval(timer.current);
    }, [questionsStore[0]]);
        
    useEffect(() => {
        if (action == CountDownActions.PAUSE) {
            clearInterval(timer.current);
        } else if (action == CountDownActions.RESUME) {
            timer.current = setInterval(() => {
                let params = {
                    "userTestId": questionsStore[0]['UserTestId'],
                    "timeTake": 15
                }
                postTimerTest(params);
            }, 15000);
            // timer.current =  setTimeout(function go() {
            //         let params = {
            //         "userTestId": questionsStore[0]['UserTestId'],
            //         "timeTake": 15
            //     }
            //     postTimerTest(params);
            //       setTimeout(go, 15000);
            //   }, 15000);
        //     timer.current = useTimer({ delay: 15000 }, useCallback(() => {
        //             let params = {
        //             "userTestId": questionsStore[0]['UserTestId'],
        //             "timeTake": 15
        //         }
        //         postTimerTest(params);
        // }, []));
                     }
    }, [action]);
    
    const speedTestQuestionSubmit = (params:any)=>{
    dispatch(showSpinner(true));
    postSpeedTestSubmitQuestion(params).then((response: APIResponse) => {
        dispatch(showSpinner(false));
        if (response?.ResponseType == APIStatus.SUCCESS) {
            // clear pevious answer
            setSubmitAnswer({})
            let data = response.data[0];
            let previousQuestionAnswer = data.previousQueFlag;
            let store = questionsStore;
            questionsStore[index]['IsVisited'] = true;
            questionsStore[index]['SelectedOption'] = submitAnswer['SelectedOption'];
            store[index ]['previousQuestionAnswer'] = previousQuestionAnswer;
            delete data.previousQueFlag;
            let appendedQuestions = [...store, data];
            setAllQuestions(appendedQuestions);
            setIndex(index + 1);
            setAction('')
        }else if(response?.StatusMsg == "Question not found in speed test.") {
            questionsExhaustEventHandler();
        }
    }).catch((error) => {
        dispatch(showSpinner(false));
        console.log(error);

    })
  }

  const speedTestSeriesQuestionSubmit = (params:any)=>{
    dispatch(showSpinner(true));
    postSpeedTestSeriesSubmitQuestion(params).then((response: APIResponse) => {
        dispatch(showSpinner(false));
        if (response?.ResponseType == APIStatus.SUCCESS) {
            // clear pevious answer
            setSubmitAnswer({})
            let data = response.data[0];
            let previousQuestionAnswer = data.previousQueFlag;
            let store = questionsStore;
            questionsStore[index]['IsVisited'] = true;
            questionsStore[index]['SelectedOption'] = submitAnswer['SelectedOption'];
            store[index ]['previousQuestionAnswer'] = previousQuestionAnswer;
            delete data.previousQueFlag;
            let appendedQuestions = [...store, data];
            setAllQuestions(appendedQuestions);
            setIndex(index + 1);
            setAction('')
        }else if(response?.StatusMsg == "Question not found in speed test."){
            questionsExhaustEventHandler();
        }
    }).catch((error) => {
        dispatch(showSpinner(false));
        console.log(error);

    })

  }

    const insertSpeedTestQuestions = () => {
        let params = {
            "testId": testId,
            "duration": timeDuration * 60,
            "noOfQuestion": 1
        }
        postSpeedTestQuestion(params).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                getSpeedTestQuestion();
            }
        })
    }


    const getSpeedTestQuestion = () => {
        let params = {
            "testId": testId,
            "difficultyLevel": 1
        }
        getSpeedTestQuestions(params).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                setAllQuestions(response.data);
            }else{
                questionsNotAvailableHandler();
            }
        })
    }

    const insertSpeedTestSeriesQuestions = () => {
        let params = {
            "examId": testId,
            "duration": timeDuration * 60,
            "noOfQuestion": 1
        }
        postSpeedTestSeriesQuestion(params).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                getSpeedTestSeriesQuestion();
            }
        })
    }


    const getSpeedTestSeriesQuestion = () => {
        let params = {
            "examId": testId,
            "difficultyLevel": 1
        }
        getSpeedTestSeriesQuestions(params).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                setAllQuestions(response.data);
            }else{
                questionsNotAvailableHandler();
            }
        })
    }


    const onClickSubmitionHandler = (params: any) => {
        setSubmitAnswer(params);
    }

    const onClickNextQuestion = () => {
        setIndexInNeed(index);
        postSpeedTestSubmitAnswer(submitAnswer)
    }

    const askNextSpeedTestQuestionHandler = (index: number) => {
    }

    const postSpeedTestSubmitAnswer = (params: any) => {
        params['timeSpend'] = ref.current.getStopWatchTimeHandler();
         if(isTestSeries){
             speedTestSeriesQuestionSubmit(params)
         }else{
             speedTestQuestionSubmit(params)       
             }
    }
    const onClickEndTest = () => {
        setEndTestDialog(true);
    }

    const endTest = () => {
        setEndTestDialog(false);
        setExpireTestDialog(false);
        setQuestionsExhaustDialog(false);
        setQuestionsNotAvailbleDialog(false);
        let params = {
            "userTestId": questionsStore[0]['UserTestId'],
        }
        postFinalSubmitTest(params).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
                if(isTestSeries){
                    navigation(`${AppRouteURL.DASHBOARD}${AppRouteURL.TESTRESULT}/${userID}?examId=${testId}&testType=2&userTestId=${questionsStore[0]['UserTestId']}`)
                }else{
                navigation(`${AppRouteURL.DASHBOARD}${AppRouteURL.TESTRESULT}/${userID}?testId=${testId}&testType=2&userTestId=${questionsStore[0]['UserTestId']}`)
                }
            }
        })
    }

    const resumeTest = () => {
        setPauesedialog(false);
        setEndTestDialog(false);
        setQuestionsExhaustDialog(false);
        setQuestionsNotAvailbleDialog(false);
        setAction(CountDownActions.RESUME);
        let params = {
            "userTestId": questionsStore[0]['UserTestId'],
        }
        postResumeTest(params).then((response: APIResponse) => {
            if (response?.ResponseType == APIStatus.SUCCESS) {
            }
        })
    }

    const runningEventHandler = (isRunning: boolean) => {
        //  no action here to be taken
    }

    const expireEventHandler = () => {
        // as using worrker timeres, so mention window for default set time out
        window.setTimeout(()=>{
            setExpireTestDialog(true);
            setAction(CountDownActions.PAUSE);    
        }
        ,1000)
    }

    const jumpToQuestionHandler = (index: number) => {
        //  no action here to be taken
    }


    const updateTestQuestionHandler = (params: any) => {
        questionsStore[index]['SelectedOption'] = params['SelectedOption'];    
    }

    const getStopWatchSpendTime = () => {
      //  setAction(StopWatchActions.RESET)
    }

    const resumeTestHandler = () => {
        resumeTest()
    }

    const setEndTestDialogHandler = (value: boolean) => {
        setEndTestDialog(value)
    }

    const endTestHandler = () => {
        endTest()
    }



    const onClickPreiousQuestionHandler = () => {
        // no need to write code here
    }

    const setQuestionpaletteHandler = () => {
        setQustpalette(!Qustpalette)
    }

    const onClickNextQuestionHandler = () => {
        onClickNextQuestion();
    }

    const setFullQuestionPaperModeHandler = (value: boolean) => {
        // no need to write code here
    }

    const setinstructionHadndler = (value: boolean) => {
        setinstruction(value)
    }

    const onClickEndTestHandler = () => {
        let params = submitAnswer;
        params['IsFinalSubmit'] = true;
        postSpeedTestSubmitAnswer(params)
        onClickEndTest()
    }

    const onActionChange = (time: number) => {
       // setSpendTime(time)
    }

    const questionsExhaustEventHandler = () => {
        setQuestionsExhaustDialog(true);
        setAction(CountDownActions.PAUSE);
    }

    const questionsNotAvailableHandler=()=>{
        setQuestionsNotAvailbleDialog(true);
        setAction(CountDownActions.PAUSE);
    }

    const endTestHandlerWithoutResult = ()=>{
            setEndTestDialog(false);
            setExpireTestDialog(false);
            setQuestionsExhaustDialog(false);
            setQuestionsNotAvailbleDialog(false);
            navigation(AppRouteURL.HOME)
    }

    const onClickBackButtonHandler = (value:boolean)=>{
        setQustpalette(value);
        // setFullQuestionPaperMode(false);
        setinstruction(false);
      }
    
    return (
        <>
            <div className="test-question-container">
                <div className="test-qust-cards-container">
                    <div className="test-qust-header">
                        <div className="left-qust-header">
                            <div className="t-qust-heading">{testName}</div>
                            <div className="t-qust-timer">
                                <span>
                                <img src={TimerSvg} className="timer-img"  alt="timer-svg"  />

                                </span>
                                <span className="timer-span"><span className="my-timer-section">Time Left:</span> 
                                    <MyTimer  expiryTimestamp={time} action={action} runningEventHandler={runningEventHandler} expireEventHandler={expireEventHandler} />
                                </span>
                                <StopWatch action={action} onActionChange={onActionChange} askNextSpeedTestQuestionHandler={askNextSpeedTestQuestionHandler} indexInNeed={indexInNeed} ref={ref} />
                            </div>
                        </div>

                        <div className="quest-palette-header">Question Palette</div>
                    </div>

                    <div className="qust-palette-container">
                        <div className="test-question-card">
                            {questionsStore.length && <div className="qust-container">
                                <QuestionCardIndex question={questionsStore[index]} index={index} total={questionsStore.length} testType={testType} testId={testId} onClickSubmitionHandler={onClickSubmitionHandler} updateTestQuestionHandler={updateTestQuestionHandler} isTestSeries={isTestSeries} />
                            </div>
                            }

                            <QuestionPaperFooter testType={testType} fullQuestionPaperMode={fullQuestionPaperMode} index={index} questionsStoreLength={questionsStore.length} instruction={instruction} Qustpalette={Qustpalette} onClickPreiousQuestionHandler={onClickPreiousQuestionHandler} setQuestionpaletteHandler={setQuestionpaletteHandler} onClickNextQuestionHandler={onClickNextQuestionHandler} setFullQuestionPaperModeHandler={setFullQuestionPaperModeHandler} setinstructionHadndler={setinstructionHadndler} onClickEndTestHandler={onClickEndTestHandler} submitAnswer={submitAnswer}  isTestSeries={isTestSeries} />


                        </div>

                        <div>
                            <div className={`qust-palette-card  ${(instruction || Qustpalette) ? 'qust-mob-card isactive-card' : ''}`}>
                                {instruction && (
                                    <InstructionSetIndex setinstructionValue={setinstruction} />
                                )}

                                {!getUserDeviceDetails().isMobileDevice && !instruction && <QuestionPaletteIndex questions={questionsStore} jumpToQuestionHandler={jumpToQuestionHandler} testType={testType}  />}
                                {getUserDeviceDetails().isMobileDevice && Qustpalette && <QuestionPaletteIndex questions={questionsStore} jumpToQuestionHandler={jumpToQuestionHandler} testType={testType} setQustpalette={setQustpalette} onClickBackButtonHandler={onClickBackButtonHandler} fullQuestionPaperMode={fullQuestionPaperMode} />}

                            </div>
                            <div className="speed-test-right-footer">
                            <QuestionpaperrightFooter testType={testType} fullQuestionPaperMode={fullQuestionPaperMode} instruction={instruction} setFullQuestionPaperModeHandler={setFullQuestionPaperModeHandler} setinstructionHadndler={setinstructionHadndler} onClickEndTestHandler={onClickEndTestHandler} />

                            </div>
                        </div>
                    </div>




                </div>
            </div>

            <EndTestDialog endTestDialog={endTestDialog} setEndTestDialogHandler={setEndTestDialogHandler} endTestHandler={endTestHandler} resumeTestHandler={resumeTestHandler} />

            <ExpireTimeEndTestDialog expireTestDialog={expireTestDialog} endTestHandler={endTestHandler} />
            <QuestionsExhaustDialog questionsExhaustDialog={questionsExhaustDialog} endTestHandler={endTestHandler} />
            <QuestionsNotAvailableDialog questionsNotAvailbleDialog={questionsNotAvailbleDialog} endTestHandlerWithoutResult={endTestHandlerWithoutResult} />
        </>
    );
};
