
import { FormEvent, useEffect, useState } from "react";
import "./Filter.scss";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from "primereact/multiselect";
import { Form, useFormik } from "formik";
import { FiltersInitialvalue, FiltersSchema, TestSeriesFiltersInitialvalue } from "../TestListing/Schema/Flters.schema";
import { BOARD_FILTERS, FIELD_TYPE, TEST_SERIES_FILTERS,FILTER_KEYS } from "./Constants/filters.constants";
import { useNavigate } from "react-router-dom";
import { DetailsRouteURL, TestSereisRouteURL } from "@Constants/app-route-url.constant";

export const FilterIndex = ({ filters, submitFilters, initialFilters, isTestSeries }: any) => {
    const navigation = useNavigate();
    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setValues } = useFormik({
        initialValues: !isTestSeries ? TestSeriesFiltersInitialvalue : FiltersInitialvalue,
        enableReinitialize: true,
        validationSchema: FiltersSchema,
        onSubmit: (e) => {
            setValues(e);
        },

    })

    const [Filters, setFilters] = useState<any>([]);

    useEffect(() => {
        if (isTestSeries) {
            setFilters(TEST_SERIES_FILTERS);
        } else {
            setFilters(BOARD_FILTERS);
        }
    }, [isTestSeries])

    useEffect(() => {
        setInitialFilter();
    }, [initialFilters])

    const setInitialFilter = () => {
        let values: any = {};
        initialFilters.forEach((element: any) => {
            let keys = Object.keys(element);
            keys.forEach((key: any) => {
                values[key] = element[key]
            })

        });
        setValues(values)
    }

    const handleOnChange = (field: string, event: any, childs: any) => {
        let data: any = values;
        if(field ==  FILTER_KEYS.SOURCE){
       // pass  source as array intead of string irrespective of the dropdown 
        data[field] = [event.target.value];
        }else{
        data[field] = event.target.value;
        }
        if (childs && childs.length) {
            childs.forEach((child: string) => {
                delete data[child]
            });
        }
        submitFilters(data)
    }

    const onClickClearFilter = () => {
        let values: any = {};
            if(isTestSeries){
                navigation(TestSereisRouteURL.TEST_SERIES)
                setValues(values);
                submitFilters(values)    
            }else{
            navigation(DetailsRouteURL.CLASSES_DETAILS)
            setValues(values);
            submitFilters(values);
        }
    }

    return (
        <>
            <section className="filter-section">
                <div className="filter-head">
                    <div className="f-h-heading">Select your filter to refine your test search</div>
                    <a className="f-h-clear" onClick={onClickClearFilter} > 
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3413_3245)">
<path d="M3.64216 3.45501C4.85187 2.40679 6.39949 1.83078 8.00016 1.83301C11.6822 1.83301 14.6668 4.81768 14.6668 8.49968C14.6668 9.92368 14.2202 11.2437 13.4602 12.3263L11.3335 8.49968H13.3335C13.3336 7.4541 13.0263 6.43155 12.4499 5.55919C11.8735 4.68682 11.0535 4.00312 10.0916 3.5931C9.12978 3.18307 8.06864 3.06482 7.04013 3.25303C6.01163 3.44124 5.06113 3.92761 4.30683 4.65168L3.64216 3.45501ZM12.3582 13.5443C11.1485 14.5926 9.60084 15.1686 8.00016 15.1663C4.31816 15.1663 1.3335 12.1817 1.3335 8.49968C1.3335 7.07568 1.78016 5.75568 2.54016 4.67301L4.66683 8.49968H2.66683C2.66674 9.54527 2.974 10.5678 3.55039 11.4402C4.12678 12.3125 4.94687 12.9962 5.90871 13.4063C6.87054 13.8163 7.93169 13.9345 8.96019 13.7463C9.9887 13.5581 10.9392 13.0717 11.6935 12.3477L12.3582 13.5443Z" fill="#7F56D9"/>
</g>
<defs>
<clipPath id="clip0_3413_3245">
<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
                    Clear Filter</a>
                </div>
                <form >

                    <div className="filter-dropdown-container">
                        {/* {filters.boardList && filters.boardList.length ? <Dropdown value={values.boardId} name="boardId" onChange={(event) => { handleChange(event), handleOnChange("boardId", event) }}
                            onBlur={handleBlur} options={filters.boardList} optionLabel="Name"
                            placeholder="Board" className="filter-dropdown" />
                            : ''}
                        {filters.classList && filters.classList.length ? <Dropdown value={values.classId} name="classId" onChange={(event) => { handleChange(event); handleOnChange("classId", event) }}
                            onBlur={handleBlur} options={filters.classList} optionLabel="Name"
                            placeholder="Class" className="filter-dropdown" /> : ''}
                        {filters.subjectList && filters.subjectList.length ? <Dropdown value={values.subjectId} name="subjectId" onChange={(event) => { handleChange(event); handleOnChange("subjectId", event) }}
                            onBlur={handleBlur} options={filters.subjectList} optionLabel="Name"
                            placeholder="Subject" className="filter-dropdown" /> : ''}
                        {filters.chapterList && filters.chapterList.length ? <MultiSelect value={values.chapterId} name="chapterId" onChange={(event) => { handleChange(event); handleOnChange("chapterId", event) }}
                            onBlur={handleBlur} options={filters.chapterList} optionLabel="Name"
                            placeholder="Chapter" className="filter-dropdown" filter={true} filterPlaceholder={'Search'}
                            maxSelectedLabels={1} selectedItemsLabel={`Chapter ${values.chapterId && values.chapterId.length ? values.chapterId[0]['Name'] : ''} {0}+`} /> : ''}
                        {filters.topicList && filters.topicList.length ? <MultiSelect value={values.topicId} name="topicId" onChange={(event) => { handleChange(event); handleOnChange("topicId", event) }}
                            onBlur={handleBlur} options={filters.topicList} optionLabel="Name"
                            placeholder="Topic" className="filter-dropdown" filter={true} filterPlaceholder={'Search'}
                            maxSelectedLabels={1} selectedItemsLabel={`Topic ${values.topicId && values.topicId.length ? values.topicId[0]['Name'] : ''}{0}+`} />
                            : ''}
                        {filters.difficultyList && filters.difficultyList.length ? <MultiSelect value={values.difficultyLevelId} name="difficultyLevelId" onChange={(event) => { handleChange(event); handleOnChange("difficultyLevelId", event) }}
                            onBlur={handleBlur} options={filters.difficultyList} optionLabel="Name"
                            placeholder="Difficulty Level" className="filter-dropdown" filter={true} filterPlaceholder={'Search'}
                            maxSelectedLabels={1} selectedItemsLabel={`Difficulty Level ${values.difficultyLevelId && values.difficultyLevelId.length ? values.difficultyLevelId[0]['Name'] : ''}{0}+`} />
                            : ''}
                        {filters.languageList && filters.languageList.length ? <Dropdown value={values.languageId} name="languageId" onChange={(event) => { handleChange(event); handleOnChange("languageId", event) }}
                            onBlur={handleBlur} options={filters.languageList} optionLabel="Name"
                            placeholder="Language" className="filter-dropdown" />
                            : ''} */}
                        {
                            Filters && Filters.length && Filters.map((filter: any, index: number) => {
                                return (
                                    Object.keys(filter).map((fil: any) => {
                                        let isParentHasValue: boolean = false;
                                        if (filter[fil]['parents'] && filter[fil]['parents'].length) {
                                            filter[fil]['parents'].forEach((pt: string) => {
                                                if (values[pt] && (values[pt]['Name' as keyof Object] || ( (Object.values(values[pt])).length ) && ( Object.values(values[pt])[0] != 0 ) )) {
                                                    isParentHasValue = true;
                                                }
                                            });
                                        } else {
                                            isParentHasValue = true;
                                        }
                                        return (
                                            filter[fil].fieldType == FIELD_TYPE.DROPDOWN && isParentHasValue ?
                                                filters[filter[fil]['list']] && filters[filter[fil]['list']].length ?
                                                    <Dropdown key={index} value={ filter[fil]['key'] == FILTER_KEYS.SOURCE  ? values[filter[fil]['key']]: values[filter[fil]['key']] } name={filter[fil].name} onChange={(event) => { handleChange(event), handleOnChange(filter[fil].key, event, filter[fil]['childs']) }}
                                                        onBlur={handleBlur} options={filters[filter[fil]['list']]} optionLabel="Name"
                                                        placeholder={filter[fil].placeholder} className="filter-dropdown" filter={true} /> : ''
                                                : filter[fil].fieldType == FIELD_TYPE.MULTISELECT && filter[fil]['key'] == FILTER_KEYS.LANGUAGE && isParentHasValue ?
                                                   ( filters[filter[fil]['list']] && filters[filter[fil]['list']].length ) ?
                                                        <MultiSelect key={index} value={values[filter[fil]['key']]} name={filter[fil].name} onChange={(event) => { handleChange(event); handleOnChange(filter[fil].key, event, filter[fil]['childs']) }}
                                                            onBlur={handleBlur} options={filters[filter[fil]['list']]} optionLabel="Name"
                                                            placeholder={filter[fil].placeholder} className="filter-dropdown" filter={true} filterPlaceholder={'Search'}
                                                            maxSelectedLabels={1} selectedItemsLabel={`${filter[fil].placeholder} ${values[filter['key']] && Object.values(values[filter['key']]).length ? values[filter['key']]['Name' as keyof Object]  : ''}+{0}`} /> : ''
                                                    : filter[fil].fieldType == FIELD_TYPE.MULTISELECT &&  filter[fil]['key'] != FILTER_KEYS.LANGUAGE && isParentHasValue ?
                                                    ( filters[filter[fil]['list']] && filters[filter[fil]['list']].length) ?
                                                         <MultiSelect key={index} value={values[filter[fil]['key']]} name={filter[fil].name} onChange={(event) => { handleChange(event); handleOnChange(filter[fil].key, event, filter[fil]['childs']) }}
                                                             onBlur={handleBlur} options={filters[filter[fil]['list']]} optionLabel="Name"
                                                             placeholder={filter[fil].placeholder} className="filter-dropdown" filter={true} filterPlaceholder={'Search'}
                                                             maxSelectedLabels={1} selectedItemsLabel={`${filter[fil].placeholder} ${values[filter['key']] && Object.values(values[filter['key']]).length ? values[filter['key']]['Name' as keyof Object]  : ''}+{0}`} /> : ''
                                                     : ''
                                        )
                                    })
                                )
                            })
                        }
                    </div>
                </form>
            </section>

        </>
    )
}